<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M91.678 32.6797L63.786 52.7895C62.851 53.4656 62.29 54.5588 62.29 55.7096V133.171C62.29 135.171 63.9011 136.782 65.9006 136.782H121.67C123.67 136.782 125.281 135.171 125.281 133.171V55.7096C125.281 54.5444 124.72 53.4656 123.785 52.7895L95.9071 32.6797C94.6413 31.7734 92.9439 31.7734 91.6924 32.6797H91.678ZM93.7926 64.8151C89.8655 64.8151 86.6865 61.6361 86.6865 57.7235C86.6865 53.8108 89.8655 50.6174 93.7926 50.6174C97.7196 50.6174 100.884 53.7964 100.884 57.7235C100.884 61.6505 97.7052 64.8151 93.7926 64.8151Z"
      :fill="background"
      :stroke="fill"
      stroke-width="2.87694"
      stroke-miterlimit="10"
    />
    <path
      d="M94.8717 83.8749H99.2159V86.047H97.0438V88.2191H92.6997V94.7497H88.3555V92.5776H90.5276V86.0614H94.8717V83.8893V83.8749Z"
      :fill="fill"
    />
    <path
      d="M70.9497 83.8749V99.0939H86.1687V83.8749H70.9497ZM83.9966 96.9218H73.1218V86.047H83.9966V96.9218Z"
      :fill="fill"
    />
    <path
      d="M101.402 83.8749V99.0939H116.621V83.8749H101.402ZM114.449 96.9218H103.574V86.047H114.449V96.9218Z"
      :fill="fill"
    />
    <path d="M75.3081 88.2334H81.8244V94.7497H75.3081V88.2334Z" :fill="fill" />
    <path d="M105.746 88.2334H112.262V94.7497H105.746V88.2334Z" :fill="fill" />
    <path d="M94.8711 90.4055H97.0432V92.5776H94.8711V90.4055Z" :fill="fill" />
    <path
      d="M97.0434 92.5775H99.2155V101.28H97.0434V96.9361H94.8713V99.1082H92.6992V94.764H97.0434V92.5919V92.5775Z"
      :fill="fill"
    />
    <path
      d="M88.3554 96.9218H90.5275V99.0938H92.6996V101.266H90.5275V105.61H92.6996V103.438H94.8717V101.266H97.0438V107.797H92.6996V109.969H90.5275V107.797H88.3554V103.452H84.0112V101.28H88.3554V96.9361V96.9218Z"
      :fill="fill"
    />
    <path d="M75.3081 101.28H79.6523V103.452H75.3081V101.28Z" :fill="fill" />
    <path
      d="M99.2153 101.28H103.56V103.452H101.387V105.625H99.2153V101.28Z"
      :fill="fill"
    />
    <path d="M105.746 101.28H107.918V103.452H105.746V101.28Z" :fill="fill" />
    <path
      d="M70.9497 103.453H75.2939V105.625H77.466V107.797H75.2939V109.969H73.1218V105.625H70.9497V103.453Z"
      :fill="fill"
    />
    <path
      d="M79.6523 103.452H83.9965V105.625H81.8244V107.797H83.9965V109.969H79.6523V103.438V103.452Z"
      :fill="fill"
    />
    <path
      d="M107.918 103.453H110.09V105.625H112.263V112.155H110.09V109.983H105.746V112.155H103.574V107.811H107.918V103.467V103.453Z"
      :fill="fill"
    />
    <path d="M83.9966 105.625H86.1687V107.797H83.9966V105.625Z" :fill="fill" />
    <path d="M114.449 105.625H116.621V107.797H114.449V105.625Z" :fill="fill" />
    <path
      d="M86.1687 107.797H88.3408V112.141H83.9966V109.969H86.1687V107.797Z"
      :fill="fill"
    />
    <path d="M97.0435 107.797H99.2155V109.969H97.0435V107.797Z" :fill="fill" />
    <path d="M70.9497 109.983H73.1218V112.155H70.9497V109.983Z" :fill="fill" />
    <path d="M75.3081 109.983H77.4802V112.155H75.3081V109.983Z" :fill="fill" />
    <path
      d="M92.6997 109.983H97.0438V112.155H94.8717V116.499H92.6997V120.843H90.5276V116.499H88.3555V112.155H90.5276V114.327H92.6997V109.983Z"
      :fill="fill"
    />
    <path d="M99.2153 109.983H101.387V112.155H99.2153V109.983Z" :fill="fill" />
    <path d="M114.449 109.983H116.621V112.155H114.449V109.983Z" :fill="fill" />
    <path d="M97.0435 112.155H99.2155V116.499H97.0435V112.155Z" :fill="fill" />
    <path d="M107.918 112.155H110.091V116.499H107.918V112.155Z" :fill="fill" />
    <path d="M112.263 112.155H114.435V114.327H112.263V112.155Z" :fill="fill" />
    <path
      d="M103.575 114.327H105.747V116.499H107.919V120.844H114.435V123.016H105.732V127.36H103.56V123.016H101.388V125.188H99.2159V127.36H101.388V129.532H90.5132V127.36H97.0438V123.016H99.2159V116.499H103.56V114.327H103.575Z"
      :fill="fill"
    />
    <path
      d="M70.9497 114.327V129.546H86.1687V114.327H70.9497ZM83.9966 127.374H73.1218V116.499H83.9966V127.374Z"
      :fill="fill"
    />
    <path d="M94.8711 116.499H97.0432V118.671H94.8711V116.499Z" :fill="fill" />
    <path d="M110.09 116.499H114.435V118.671H110.09V116.499Z" :fill="fill" />
    <path d="M75.3081 118.672H81.8244V125.188H75.3081V118.672Z" :fill="fill" />
    <path d="M114.449 118.672H116.621V120.844H114.449V118.672Z" :fill="fill" />
    <path
      d="M88.3555 120.844H90.5276V123.016H92.6997V125.188H88.3555V120.844Z"
      :fill="fill"
    />
    <path d="M92.6992 120.844H97.0434V123.016H92.6992V120.844Z" :fill="fill" />
    <path d="M110.09 125.202H112.262V127.374H110.09V125.202Z" :fill="fill" />
    <path d="M114.449 125.202H116.621V127.374H114.449V125.202Z" :fill="fill" />
    <path d="M105.746 127.374H107.918V129.546H105.746V127.374Z" :fill="fill" />
  </svg>
</template>

<script>
export default {
  name: "HangTag",
  props: {
    width: {
      type: String,
      default: "188",
    },
    height: {
      type: String,
      default: "168",
    },
    fill: {
      type: String,
      default: "#442D64",
    },
    background: {
      type: String,
      default: "#E9E7ED",
    },
    viewBox: {
      type: String,
      default: "0 0 188 168",
    },
  },
};
</script>
