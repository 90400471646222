var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.password)?_c('v-row',[_c('v-col',{staticClass:"password-policy-icon",attrs:{"cols":"3"}},[_c('password-lock')],1),_c('v-col',{attrs:{"cols":"9"}},[_c('p',{staticClass:"password-policy-title"},[_c('strong',[_vm._v("Your password needs to:")])]),_c('div',{staticClass:"password-policy__items"},[_c('div',{staticClass:"policy-item"},[_c('span',{class:{ 'green-tick': _vm.havingNumber, 'red-cross': !_vm.havingNumber }},[(_vm.havingNumber)?[_vm._v("✔")]:[_vm._v("✘")]],2),_c('span',{class:{ 'green-text': _vm.havingNumber, 'red-text': !_vm.havingNumber }},[_vm._v(" Include at least one number. ")])]),_c('div',{staticClass:"policy-item"},[_c('span',{class:{
            'green-tick': _vm.havingLowerCase,
            'red-cross': !_vm.havingLowerCase,
          }},[(_vm.havingLowerCase)?[_vm._v("✔")]:[_vm._v("✘")]],2),_c('span',{class:{
            'green-text': _vm.havingLowerCase,
            'red-text': !_vm.havingLowerCase,
          }},[_vm._v(" Include at least one lowercase character. ")])]),_c('div',{staticClass:"policy-item"},[_c('span',{class:{
            'green-tick': _vm.havingUpperCase,
            'red-cross': !_vm.havingUpperCase,
          }},[(_vm.havingUpperCase)?[_vm._v("✔")]:[_vm._v("✘")]],2),_c('span',{class:{
            'green-text': _vm.havingUpperCase,
            'red-text': !_vm.havingUpperCase,
          }},[_vm._v(" Include at least one uppercase character. ")])]),_c('div',{staticClass:"policy-item"},[_c('span',{class:{ 'green-tick': _vm.havingLength, 'red-cross': !_vm.havingLength }},[(_vm.havingLength)?[_vm._v("✔")]:[_vm._v("✘")]],2),_c('span',{class:{ 'green-text': _vm.havingLength, 'red-text': !_vm.havingLength }},[_vm._v(" Be at least 6 characters long. ")])])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }