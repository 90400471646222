<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_257_822)">
      <path
        d="M133.41 70.3699L128.95 79.8999V116.11C128.95 116.78 128.41 117.32 127.74 117.32H59.7798C59.1098 117.32 58.5798 116.78 58.5798 116.11V79.8899L54.1198 70.3799C53.9398 69.9999 53.9698 69.5699 54.1898 69.2199C54.4198 68.8699 54.7998 68.6599 55.2098 68.6599H80.6198C81.0898 68.6599 81.5198 68.9299 81.7098 69.3599L85.1898 76.7899L88.6698 69.3599C88.8698 68.9299 89.2898 68.6599 89.7598 68.6599H132.32C132.73 68.6599 133.11 68.8699 133.34 69.2199C133.56 69.5699 133.59 69.9999 133.41 70.3699Z"
        :fill="background"
      />
      <path
        d="M85.73 55.5C85.47 55.13 85.43 54.64 85.64 54.25C85.84 53.84 86.26 53.59 86.71 53.59H89.44V40.41C89.44 39.74 89.98 39.2 90.65 39.2H96.9C97.57 39.2 98.1 39.74 98.1 40.41V53.59H100.83C101.28 53.59 101.69 53.85 101.9 54.25C102.11 54.66 102.07 55.13 101.81 55.5L94.74 65.39C94.52 65.72 94.15 65.9 93.76 65.9C93.37 65.9 93.01 65.7 92.78 65.39L85.73 55.5Z"
        :fill="background"
      />
      <path d="M104.88 96.12H107.76V111.99H104.88V96.12Z" :fill="fill" />
      <path d="M112.09 96.12H114.97V111.99H112.08V96.12H112.09Z" :fill="fill" />
      <path d="M116.42 96.12H119.3V111.99H116.42V96.12Z" :fill="fill" />
      <path d="M120.75 96.12H122.19V111.99H120.75V96.12Z" :fill="fill" />
      <path d="M109.21 96.12H110.65V111.99H109.21V96.12Z" :fill="fill" />
      <path
        d="M133.33 69.2199C133.11 68.8699 132.72 68.6599 132.31 68.6599H89.7598C89.2898 68.6599 88.8698 68.9399 88.6698 69.3599L85.1898 76.7899L81.7098 69.3599C81.5098 68.9399 81.0898 68.6599 80.6198 68.6599H55.2098C54.7998 68.6599 54.4198 68.8699 54.1898 69.2199C53.9698 69.5699 53.9398 69.9999 54.1198 70.3799L58.5798 79.8999V116.12C58.5798 116.79 59.1098 117.32 59.7798 117.32H127.74C128.41 117.32 128.94 116.79 128.94 116.12V79.9099L133.4 70.3799C133.58 70.0099 133.55 69.5699 133.33 69.2199ZM79.8598 71.0699L83.2998 78.4199H60.5398L57.0998 71.0699H79.8598ZM83.9798 114.9H60.9898V80.8299H83.9998V114.9H83.9798ZM126.53 114.9H86.3998V80.8199H126.53V114.9ZM126.98 78.4099H87.0898L90.5298 71.0599H130.42L126.98 78.4099Z"
        :fill="fill"
      />
      <path
        d="M77.1302 105.26H78.2902C78.9602 105.26 79.4902 104.73 79.4902 104.06C79.4902 103.39 78.9602 102.86 78.2902 102.86H77.1302C76.4602 102.86 75.9302 103.39 75.9302 104.06C75.9302 104.73 76.4602 105.26 77.1302 105.26Z"
        :fill="fill"
      />
      <path
        d="M66.6502 105.26H73.1502C73.8202 105.26 74.3502 104.73 74.3502 104.06C74.3502 103.39 73.8202 102.86 73.1502 102.86H66.6502C65.9802 102.86 65.4502 103.39 65.4502 104.06C65.4502 104.73 65.9802 105.26 66.6502 105.26Z"
        :fill="fill"
      />
      <path
        d="M66.6502 110.64H70.3002C70.9702 110.64 71.5002 110.11 71.5002 109.44C71.5002 108.77 70.9702 108.24 70.3002 108.24H66.6502C65.9802 108.24 65.4502 108.77 65.4502 109.44C65.4502 110.11 65.9802 110.64 66.6502 110.64Z"
        :fill="fill"
      />
      <path
        d="M92.7802 65.39C93.0002 65.7 93.3802 65.9 93.7602 65.9C94.1402 65.9 94.5202 65.71 94.7402 65.39L101.81 55.5C102.07 55.13 102.11 54.65 101.9 54.24C101.69 53.84 101.28 53.58 100.83 53.58H98.1002V40.41C98.1002 39.74 97.5702 39.21 96.9002 39.21H90.6502C89.9802 39.21 89.4502 39.74 89.4502 40.41V53.59H86.7202C86.2702 53.59 85.8502 53.84 85.6502 54.25C85.4402 54.65 85.4802 55.13 85.7402 55.51L92.8002 65.4L92.7802 65.39ZM90.6402 56C91.3102 56 91.8402 55.47 91.8402 54.8V41.61H95.6802V54.79C95.6802 55.46 96.2102 55.99 96.8802 55.99H98.4802L93.7502 62.6L89.0302 55.99H90.6302L90.6402 56Z"
        :fill="fill"
      />
    </g>
    <mask id="path-13-inside-1_257_822" fill="none">
      <rect x="84.251" y="115.2" width="44.6907" height="15" rx="1" />
    </mask>
    <rect
      x="84.251"
      y="115.2"
      width="44.6907"
      height="15"
      rx="1"
      :fill="background"
      :stroke="fill"
      stroke-width="4.4"
      mask="url(#path-13-inside-1_257_822)"
    />
  </svg>
</template>

<script>
export default {
  name: "Packaging",
  props: {
    width: {
      type: String,
      default: "188",
    },
    height: {
      type: String,
      default: "168",
    },
    fill: {
      type: String,
      default: "#442D64",
    },
    background: {
      type: String,
      default: "#E9E7ED",
    },
    viewBox: {
      type: String,
      default: "0 0 188 168",
    },
  },
};
</script>
