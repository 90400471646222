<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.5644 30.5836C20.2732 30.5836 20.0371 30.8197 20.0371 31.1109C20.0371 31.4021 20.2732 31.6382 20.5644 31.6382H22.6736C22.9648 31.6382 23.2009 31.4021 23.2009 31.1109C23.2009 30.8197 22.9648 30.5836 22.6736 30.5836H22.1463V5.27335H22.6736C22.9648 5.27335 23.2009 5.03724 23.2009 4.74605C23.2009 4.45486 22.9648 4.21875 22.6736 4.21875H20.5644C20.2732 4.21875 20.0371 4.45486 20.0371 4.74605C20.0371 5.03724 20.2732 5.27335 20.5644 5.27335H21.0917V30.5836H20.5644Z" :fill="fill"/>
    <path d="M0.527298 30.5834C0.236109 30.5834 0 30.8195 0 31.1107C0 31.4019 0.236109 31.638 0.527298 31.638H2.63649C2.92768 31.638 3.16379 31.4019 3.16379 31.1107C3.16379 30.8195 2.92768 30.5834 2.63649 30.5834H2.10919V13.7099H2.63649C2.92768 13.7099 3.16379 13.4738 3.16379 13.1826C3.16379 12.8914 2.92768 12.6553 2.63649 12.6553H0.527298C0.236109 12.6553 0 12.8914 0 13.1826C0 13.4738 0.236109 13.7099 0.527298 13.7099H1.0546V30.5834H0.527298Z" :fill="fill"/>
    <path d="M4.74605 0C4.45486 0 4.21875 0.236109 4.21875 0.527298V2.63649C4.21875 2.92768 4.45486 3.16379 4.74605 3.16379C5.03724 3.16379 5.27335 2.92768 5.27335 2.63649V2.10919H17.9285V2.63649C17.9285 2.92768 18.1646 3.16379 18.4558 3.16379C18.747 3.16379 18.9831 2.92768 18.9831 2.63649V0.527298C18.9831 0.236109 18.747 0 18.4558 0C18.1646 0 17.9285 0.236109 17.9285 0.527298V1.0546H5.27335V0.527298C5.27335 0.38747 5.21779 0.25329 5.11892 0.154423C5.02005 0.0555561 4.88587 0 4.74605 0Z" :fill="fill"/>
    <path d="M4.21875 31.111C4.21875 31.2508 4.2743 31.385 4.37317 31.4838C4.47204 31.5827 4.60622 31.6383 4.74605 31.6383H8.96443C9.22596 31.6383 9.44817 31.4464 9.48607 31.1877L11.6009 16.8072L13.7158 31.1877C13.7537 31.4464 13.9759 31.6383 14.2374 31.6383H18.4558C18.5956 31.6383 18.7298 31.5827 18.8287 31.4838C18.9275 31.385 18.9831 31.2508 18.9831 31.111V8.43716C18.9831 8.33311 18.9523 8.23118 18.8946 8.14456L17.9285 6.69566V4.74605C17.9285 4.60622 17.873 4.47204 17.7741 4.37317C17.6752 4.27431 17.5411 4.21875 17.4012 4.21875H5.80068C5.50949 4.21875 5.27338 4.45486 5.27338 4.74605V6.69566L4.30731 8.1448V8.14456C4.24964 8.23119 4.2188 8.33312 4.2188 8.43717L4.21875 31.111ZM17.9285 8.59686V30.5837H14.6929L12.2116 13.7101H12.6555C13.075 13.7097 13.4771 13.5428 13.7735 13.2462C14.0701 12.9498 14.237 12.5477 14.2374 12.1282V7.38255H17.1189L17.9285 8.59686ZM15.8193 5.2735H16.8739V6.32809H15.8193V5.2735ZM12.1282 5.2735H14.7647V6.32809H12.1282V5.2735ZM12.1282 7.38269H13.1828V12.1284C13.1826 12.4196 12.9467 12.6554 12.6555 12.6557H12.1282V7.38269ZM8.43713 5.2735H11.0736V6.32809H8.43713V5.2735ZM6.32794 5.2735H7.38254V6.32809H6.32794V5.2735ZM5.27335 8.59686L6.08291 7.38269H11.0736V13.1443L8.5089 30.5837H5.27335V8.59686Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "SamplingIcon",
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 32      
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 24 32'    
    },
  }
}
</script>

