<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.5 16H5V14H2.5C2.22377 14 2.00004 13.7762 2.00004 13.5V2.5C2.00004 2.22377 2.22378 2.00004 2.5 2.00004H5V0H2.5C1.83696 0 1.20098 0.263321 0.732232 0.732232C0.263303 1.20098 0 1.83696 0 2.5V13.5C0 14.163 0.263321 14.799 0.732232 15.2678C1.20098 15.7367 1.83696 16 2.5 16Z" :fill="fill"/>
    <path d="M13.9999 3.58594L12.5848 5.00089L14.5848 7.00094H6V9.00098L14.5848 9.00081L12.5848 11.0009L13.9999 12.4158L17.7074 8.70835C17.8952 8.52071 18.0007 8.26629 18.0007 8.00085C18.0007 7.73542 17.8952 7.48099 17.7074 7.29335L13.9999 3.58594Z" :fill="fill"/>
</svg>
</template>

<script>
export default {
  name: "LogoutIcon",
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 16      
    },
    fill: {
      type: String,
      default: '#442D64'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 18 16'    
    },
  }
}
</script>