<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M145.032 57.5186C144.967 55.8031 146.324 54.3675 148.054 54.3173L148.025 52.9463C145.52 53.0037 143.567 55.0853 143.675 57.5689C143.783 60.0309 145.814 61.9761 148.204 61.9187L148.176 60.5477C146.503 60.5908 145.089 59.2342 145.032 57.5115V57.5186Z"
      :fill="fill"
    />
    <path
      d="M94.7072 71.7023L94.6642 71.5085L94.6211 71.3147L94.334 71.7884C94.3986 71.7741 94.456 71.7597 94.5206 71.7525L94.7144 71.7023H94.7072Z"
      :fill="background"
    />
    <path
      d="M98.849 70.4534C98.8633 70.518 98.8777 70.5754 98.8992 70.64C99.05 71.3937 99.1576 72.1832 99.2509 72.9297C99.1648 72.2478 99.0643 71.5372 98.9351 70.841C99.4735 70.7907 100.005 70.8769 100.514 71.0491C101.024 71.2214 101.505 71.4798 101.907 71.853L102.172 71.5444C101.72 71.1209 101.175 70.841 100.608 70.6472C100.041 70.4605 99.4519 70.3672 98.8418 70.439L98.849 70.4534Z"
      :fill="background"
    />
    <path
      d="M93.4297 67.1657C93.4512 67.2303 93.4728 67.2878 93.5015 67.3524C93.7383 68.0917 93.9609 68.831 94.169 69.556C93.9824 68.8956 93.7814 68.2281 93.5661 67.5534C94.5997 67.3237 95.6979 67.4816 96.588 68.0486L96.7961 67.6969C95.8056 67.0653 94.571 66.9073 93.4369 67.1657H93.4297Z"
      :fill="background"
    />
    <path
      d="M93.5014 67.3523C93.4799 67.2877 93.4584 67.2303 93.4297 67.1657C93.0062 66.0746 92.152 65.1846 91.1184 64.718L90.9461 65.0841C91.9367 65.5291 92.7406 66.3977 93.1067 67.4672C93.5302 68.8094 93.9249 70.0871 94.2264 71.4078C93.1928 71.6232 92.1592 71.494 91.3265 70.9987C90.458 70.5537 89.7833 69.7497 89.4172 68.7305L89.4818 68.9171L89.2952 68.9889C89.2306 69.0104 89.1731 69.032 89.1157 69.0463C89.5536 70.1015 90.2929 70.9341 91.2189 71.3863C92.1161 71.8888 93.2215 72.0036 94.3269 71.7811L94.614 71.3074C94.4776 70.7188 94.3341 70.123 94.169 69.5488C93.9608 68.8238 93.7383 68.0845 93.5014 67.3451V67.3523Z"
      :fill="background"
    />
    <path
      d="M102.833 79.0093C103.184 77.7317 103.321 76.3678 103.357 74.9251C103.357 74.3437 103.22 73.7694 102.976 73.2311C102.732 72.6927 102.352 72.2549 101.921 71.8601L99.416 74.7385V74.9394C99.4375 75.485 99.5667 75.9659 99.739 76.4181C99.8969 76.8775 100.141 77.2723 100.45 77.6096C100.737 77.9542 101.067 78.2556 101.483 78.4853C101.907 78.7078 102.309 78.9088 102.833 79.0237V79.0093Z"
      :fill="fill"
    />
    <path
      d="M103.78 74.9682C103.78 74.8964 103.78 74.8318 103.78 74.76C103.751 74.1571 103.586 73.5542 103.313 72.9943C103.048 72.42 102.639 71.9678 102.187 71.5515L101.921 71.8602C102.352 72.255 102.732 72.6928 102.976 73.2311C103.22 73.7695 103.357 74.3437 103.357 74.9251C103.321 76.3679 103.184 77.7317 102.833 79.0094C102.309 78.9017 101.899 78.7007 101.483 78.471C101.067 78.2485 100.737 77.947 100.449 77.5953C100.141 77.258 99.904 76.8632 99.7389 76.4038C99.5666 75.9516 99.4446 75.4707 99.4231 74.9251V75.1189L99.2364 75.1333C99.1718 75.1333 99.1144 75.1333 99.0498 75.1405C99.0857 75.7075 99.2364 76.2172 99.4302 76.6837C99.6097 77.1575 99.8681 77.5666 100.205 77.9112C100.5 78.2772 100.866 78.5715 101.311 78.8012C101.72 79.0453 102.187 79.2391 102.746 79.3539L103.22 79.0668C103.364 78.5069 103.493 77.9112 103.593 77.2939C103.694 76.5402 103.744 75.765 103.78 74.9467V74.9682Z"
      :fill="background"
    />
    <path
      d="M99.4302 75.1333V74.9395L99.4159 74.7385L99.0498 75.162C99.1144 75.162 99.1718 75.162 99.2364 75.1548L99.4302 75.1405V75.1333Z"
      :fill="background"
    />
    <path
      d="M99.2579 72.9369C99.3368 73.5326 99.3943 74.1356 99.4158 74.7385L101.921 71.8602C101.519 71.4869 101.038 71.2213 100.528 71.0562C100.019 70.8911 99.4876 70.7978 98.9492 70.8481C99.0784 71.5443 99.1789 72.2549 99.265 72.9369H99.2579Z"
      :fill="fill"
    />
    <path
      d="M99.0214 74.7672C98.9998 74.0853 98.9065 73.4393 98.8204 72.7502C98.7342 72.0683 98.6266 71.3721 98.483 70.7045C98.3682 70.1446 98.1313 69.6206 97.8083 69.1541C97.4781 68.7019 97.0546 68.3358 96.5737 68.0415L94.6069 71.3075L94.65 71.5013C95.1812 73.5829 96.8967 74.8964 99.0142 74.7672H99.0214Z"
      :fill="fill"
    />
    <path
      d="M98.8991 70.64C98.8848 70.5754 98.8704 70.5108 98.8489 70.4533C98.7053 69.8791 98.4398 69.3336 98.0952 68.8527C97.7435 68.3789 97.2913 68.0057 96.796 67.697L96.5879 68.0487C97.0616 68.343 97.4923 68.7091 97.8225 69.1613C98.1455 69.6279 98.3823 70.1519 98.4972 70.7118C98.6407 71.3793 98.7484 72.0756 98.8345 72.7575C98.9135 73.4465 99.0068 74.0926 99.0355 74.7745C96.918 74.9037 95.2025 73.5973 94.6713 71.5085L94.7144 71.7023L94.5206 71.7526C94.456 71.7669 94.3986 71.7813 94.334 71.7884C94.98 73.9705 96.8032 75.2984 99.0499 75.1621L99.416 74.7386C99.3944 74.1284 99.337 73.5327 99.258 72.9369C99.1576 72.1904 99.0571 71.3937 98.9063 70.6472L98.8991 70.64Z"
      :fill="background"
    />
    <path
      d="M94.1687 69.5488C94.341 70.123 94.4845 70.7188 94.6137 71.3073L96.5805 68.0414C95.6904 67.4743 94.5922 67.3164 93.5586 67.5461C93.7739 68.2208 93.9749 68.8884 94.1615 69.5488H94.1687Z"
      :fill="fill"
    />
    <path
      d="M94.2337 71.408C93.9322 70.0872 93.5374 68.8024 93.1139 67.4673C92.7478 66.405 91.9439 65.5293 90.9534 65.0842L89.3599 68.5512L89.4245 68.7378C89.7905 69.7571 90.4653 70.5538 91.3338 71.006C92.1664 71.5013 93.2072 71.6305 94.2337 71.4152V71.408Z"
      :fill="fill"
    />
    <path
      d="M88.714 66.8499L89.3529 68.5439L90.9464 65.0769C90.0132 64.6678 88.9366 64.5457 87.9316 64.919C88.2044 65.5507 88.47 66.1967 88.714 66.8499Z"
      :fill="fill"
    />
    <path
      d="M42.969 57.0808C42.9044 58.7963 41.4976 60.1601 39.8251 60.1171L39.7964 61.488C42.1866 61.5455 44.218 59.6002 44.3257 57.1382C44.4333 54.6546 42.4809 52.573 39.9758 52.5156L39.9471 53.8866C41.677 53.9297 43.0336 55.3653 42.969 57.088V57.0808Z"
      :fill="fill"
    />
    <path
      d="M147.946 115.567L147.975 114.196C146.245 114.152 144.888 112.717 144.953 110.994C145.017 109.279 146.424 107.915 148.097 107.958L148.125 106.587C145.735 106.53 143.704 108.475 143.596 110.937C143.488 113.42 145.441 115.495 147.946 115.559V115.567Z"
      :fill="fill"
    />
    <path
      d="M149.848 110.886L151.068 112.236L151.219 108.424C150.257 108.381 149.281 108.69 148.477 109.357L149.848 110.886Z"
      :fill="fill"
    />
    <path
      d="M148.212 109.071C148.255 109.114 148.298 109.157 148.341 109.207L149.848 110.887L148.477 109.358C149.281 108.683 150.257 108.381 151.219 108.425L151.234 108.015C150.171 107.965 149.087 108.324 148.205 109.071H148.212Z"
      :fill="background"
    />
    <path
      d="M148.348 109.207C148.305 109.164 148.262 109.114 148.219 109.07C147.422 108.231 146.389 107.764 145.326 107.699L145.298 108.109C146.324 108.173 147.315 108.633 148.061 109.48L150.782 112.509C149.949 113.198 148.908 113.514 147.889 113.435C146.87 113.348 145.886 112.882 145.168 112.064L145.298 112.214L145.147 112.351C145.097 112.394 145.046 112.43 144.996 112.473C145.793 113.313 146.87 113.779 147.961 113.844C149.052 113.894 150.164 113.549 151.054 112.796L151.076 112.236L149.856 110.886L148.348 109.207Z"
      :fill="background"
    />
    <path
      d="M145.297 112.207L145.168 112.056L145.039 111.906L145.003 112.458C145.053 112.415 145.103 112.38 145.154 112.336L145.304 112.2L145.297 112.207Z"
      :fill="background"
    />
    <path
      d="M148.054 109.472C147.315 108.625 146.317 108.166 145.29 108.101C144.329 108.051 143.345 108.302 142.527 108.948L143.848 110.528L145.032 111.906L145.161 112.056C145.879 112.882 146.862 113.341 147.882 113.427C148.901 113.499 149.942 113.191 150.774 112.502L148.054 109.472Z"
      :fill="fill"
    />
    <path
      d="M142.269 108.654C142.312 108.704 142.355 108.747 142.398 108.791L143.855 110.528L142.534 108.948C143.352 108.302 144.336 108.044 145.298 108.101L145.326 107.692C144.264 107.635 143.18 107.929 142.276 108.654H142.269Z"
      :fill="background"
    />
    <path
      d="M144.723 112.171C143.833 111.145 142.957 110.097 142.089 109.056C141.371 108.202 140.388 107.714 139.368 107.606L138.995 111.403L139.124 111.561C140.51 113.277 143.022 113.506 144.723 112.171Z"
      :fill="fill"
    />
    <path
      d="M142.39 108.798C142.347 108.748 142.304 108.704 142.261 108.661C141.486 107.814 140.467 107.319 139.411 107.211L139.368 107.613C140.388 107.721 141.371 108.209 142.089 109.063C142.95 110.097 143.833 111.152 144.723 112.179C143.029 113.514 140.51 113.284 139.124 111.568L139.253 111.726L139.096 111.856C139.045 111.891 138.995 111.935 138.945 111.97C140.495 113.729 143.173 113.93 144.989 112.473L145.025 111.92L143.84 110.542L142.383 108.805L142.39 108.798Z"
      :fill="background"
    />
    <path
      d="M139.253 111.719L139.124 111.561L138.995 111.403L138.938 111.956C138.988 111.92 139.038 111.884 139.088 111.841L139.246 111.712L139.253 111.719Z"
      :fill="background"
    />
    <path
      d="M137.861 109.996L139.002 111.403L139.376 107.606C138.421 107.506 137.423 107.771 136.583 108.381L137.861 109.989V109.996Z"
      :fill="fill"
    />
    <path
      d="M136.332 108.073C136.375 108.123 136.411 108.166 136.454 108.216C136.913 108.819 137.38 109.401 137.854 109.989L136.576 108.381C137.416 107.771 138.406 107.506 139.368 107.606L139.411 107.204C138.356 107.097 137.258 107.398 136.332 108.08V108.073Z"
      :fill="background"
    />
    <path
      d="M138.687 111.655C137.825 110.592 136.978 109.551 136.146 108.468C135.457 107.578 134.487 107.068 133.475 106.939L132.987 110.721L133.109 110.879C134.444 112.602 136.95 112.961 138.687 111.647V111.655Z"
      :fill="fill"
    />
    <path
      d="M136.462 108.224C136.418 108.173 136.375 108.13 136.34 108.08C135.593 107.197 134.588 106.68 133.533 106.544L133.483 106.946C134.495 107.075 135.464 107.585 136.153 108.475C136.986 109.551 137.833 110.599 138.694 111.662C136.957 112.975 134.452 112.616 133.117 110.894L133.239 111.052L133.081 111.174C133.031 111.21 132.973 111.245 132.923 111.281C134.416 113.062 137.093 113.377 138.952 111.963L139.01 111.411L137.868 110.004C137.395 109.415 136.928 108.834 136.469 108.231L136.462 108.224Z"
      :fill="background"
    />
    <path
      d="M133.231 111.052L133.109 110.894L132.987 110.736L132.916 111.289C132.966 111.253 133.016 111.217 133.073 111.181L133.231 111.059V111.052Z"
      :fill="background"
    />
    <path
      d="M131.896 109.271L132.994 110.729L133.482 106.946C132.528 106.817 131.53 107.032 130.676 107.628L131.896 109.279V109.271Z"
      :fill="fill"
    />
    <path
      d="M130.432 107.312C130.475 107.362 130.511 107.405 130.554 107.455L131.896 109.271L130.676 107.62C131.538 107.025 132.535 106.809 133.483 106.939L133.533 106.537C132.478 106.393 131.38 106.644 130.432 107.305V107.312Z"
      :fill="background"
    />
    <path
      d="M132.664 110.966L130.231 107.7C129.571 106.795 128.63 106.235 127.618 106.077L127.001 109.839L127.116 110.004C127.755 110.887 128.695 111.454 129.7 111.626C130.705 111.791 131.774 111.583 132.664 110.966Z"
      :fill="fill"
    />
    <path
      d="M130.554 107.463C130.511 107.413 130.475 107.362 130.432 107.319C129.714 106.422 128.738 105.848 127.69 105.683L127.625 106.085C128.637 106.242 129.578 106.802 130.238 107.707L132.671 110.973C131.781 111.59 130.712 111.798 129.707 111.633C128.702 111.461 127.762 110.901 127.123 110.011L127.238 110.176L127.072 110.298C127.015 110.334 126.965 110.37 126.915 110.406C127.632 111.317 128.659 111.892 129.743 112.049C130.827 112.2 131.968 111.956 132.922 111.296L132.994 110.743L131.896 109.286L130.554 107.47V107.463Z"
      :fill="background"
    />
    <path
      d="M127.238 110.169L127.123 110.004L127.008 109.839L126.915 110.391C126.972 110.355 127.022 110.32 127.072 110.284L127.238 110.162V110.169Z"
      :fill="background"
    />
    <path
      d="M125.953 108.367L127.001 109.846L127.618 106.085C126.671 105.941 125.666 106.099 124.79 106.666L125.953 108.367Z"
      :fill="fill"
    />
    <path
      d="M124.567 106.343C124.603 106.393 124.639 106.443 124.682 106.494L125.96 108.36L124.797 106.659C125.673 106.092 126.671 105.934 127.625 106.077L127.69 105.675C126.642 105.517 125.536 105.704 124.567 106.343Z"
      :fill="background"
    />
    <path
      d="M126.67 110.075C125.881 108.97 125.106 107.836 124.352 106.723C123.72 105.805 122.802 105.216 121.804 105.008L121.043 108.748L121.151 108.913C122.357 110.757 124.847 111.245 126.663 110.068L126.67 110.075Z"
      :fill="fill"
    />
    <path
      d="M124.675 106.494C124.639 106.443 124.596 106.393 124.56 106.343C123.871 105.424 122.917 104.828 121.883 104.613L121.804 105.015C122.802 105.216 123.721 105.812 124.352 106.731C125.106 107.843 125.881 108.977 126.671 110.083C124.855 111.253 122.357 110.765 121.158 108.927L121.266 109.092L121.101 109.207C121.043 109.243 120.993 109.272 120.936 109.307C122.299 111.21 124.962 111.69 126.915 110.406L127.008 109.853L125.96 108.374L124.682 106.508L124.675 106.494Z"
      :fill="background"
    />
    <path
      d="M121.266 109.085L121.158 108.92L121.051 108.755L120.943 109.3C121 109.264 121.051 109.236 121.108 109.2L121.273 109.085H121.266Z"
      :fill="background"
    />
    <path
      d="M120.052 107.24L121.043 108.755L121.804 105.015C120.864 104.828 119.859 104.979 118.961 105.496L120.052 107.24Z"
      :fill="fill"
    />
    <path
      d="M118.746 105.159C118.782 105.209 118.818 105.259 118.854 105.317L120.052 107.233L118.961 105.489C119.859 104.972 120.864 104.828 121.804 105.008L121.883 104.606C120.842 104.405 119.737 104.57 118.746 105.151V105.159Z"
      :fill="background"
    />
    <path
      d="M120.706 108.97C119.952 107.843 119.227 106.687 118.516 105.532C117.928 104.584 117.031 103.974 116.047 103.716L115.128 107.42L115.229 107.592C115.789 108.539 116.693 109.157 117.691 109.401C118.681 109.652 119.765 109.53 120.698 108.963L120.706 108.97Z"
      :fill="fill"
    />
    <path
      d="M118.854 105.316C118.818 105.266 118.782 105.216 118.746 105.158C118.1 104.204 117.167 103.587 116.148 103.328L116.047 103.723C117.031 103.981 117.928 104.584 118.517 105.539C119.227 106.695 119.952 107.843 120.706 108.977C119.773 109.544 118.689 109.666 117.698 109.415C116.701 109.171 115.803 108.554 115.236 107.606L115.337 107.778L115.164 107.886C115.107 107.915 115.05 107.944 114.992 107.979C115.631 108.963 116.622 109.594 117.691 109.831C118.761 110.083 119.923 109.917 120.921 109.307L121.029 108.762L120.038 107.247L118.84 105.331L118.854 105.316Z"
      :fill="background"
    />
    <path
      d="M115.344 107.771L115.243 107.599L115.143 107.427L115.006 107.965C115.064 107.936 115.114 107.908 115.179 107.872L115.351 107.764L115.344 107.771Z"
      :fill="background"
    />
    <path
      d="M114.21 105.862L115.136 107.42L116.055 103.716C115.129 103.486 114.117 103.601 113.198 104.075C113.528 104.671 113.865 105.273 114.21 105.862Z"
      :fill="fill"
    />
    <path
      d="M112.997 103.737C113.032 103.795 113.061 103.845 113.097 103.895C113.463 104.556 113.829 105.216 114.21 105.862C113.865 105.273 113.528 104.678 113.198 104.075C114.109 103.601 115.128 103.486 116.054 103.716L116.155 103.321C115.128 103.07 114.016 103.199 113.004 103.73L112.997 103.737Z"
      :fill="background"
    />
    <path
      d="M114.784 107.628C114.08 106.465 113.406 105.288 112.753 104.096C112.214 103.113 111.346 102.474 110.384 102.173L109.286 105.826L109.379 106.006C110.413 107.944 112.875 108.669 114.784 107.628Z"
      :fill="fill"
    />
    <path
      d="M113.097 103.903C113.061 103.845 113.033 103.795 112.997 103.745C112.401 102.761 111.496 102.108 110.499 101.792L110.384 102.18C111.346 102.481 112.214 103.127 112.753 104.104C113.399 105.295 114.081 106.465 114.784 107.635C112.875 108.676 110.413 107.951 109.379 106.013L109.472 106.192L109.293 106.286C109.235 106.314 109.178 106.343 109.121 106.365C110.312 108.389 112.954 109.099 114.999 107.972L115.136 107.434L114.21 105.876C113.829 105.23 113.463 104.57 113.097 103.91V103.903Z"
      :fill="background"
    />
    <path
      d="M109.472 106.185L109.379 106.006L109.286 105.826L109.128 106.357C109.185 106.329 109.243 106.307 109.3 106.278L109.48 106.185H109.472Z"
      :fill="background"
    />
    <path
      d="M108.439 104.225L109.279 105.833L110.377 102.18C109.465 101.9 108.453 101.972 107.52 102.388C107.814 103.005 108.116 103.623 108.432 104.225H108.439Z"
      :fill="fill"
    />
    <path
      d="M107.348 102.036C107.376 102.094 107.405 102.144 107.441 102.201C107.764 102.876 108.094 103.558 108.446 104.225C108.13 103.622 107.829 103.005 107.534 102.388C108.467 101.964 109.48 101.9 110.391 102.18L110.506 101.792C109.501 101.491 108.388 101.57 107.355 102.043L107.348 102.036Z"
      :fill="background"
    />
    <path
      d="M108.927 106.013C108.281 104.814 107.664 103.608 107.082 102.381C106.594 101.383 105.776 100.687 104.843 100.328L103.522 103.91L103.608 104.096C104.067 105.101 104.907 105.819 105.876 106.156C106.845 106.515 107.944 106.479 108.927 106.013Z"
      :fill="fill"
    />
    <path
      d="M107.441 102.201C107.412 102.144 107.384 102.093 107.348 102.036C106.809 101.024 105.955 100.321 104.986 99.9473L104.843 100.328C105.776 100.687 106.601 101.383 107.082 102.381C107.664 103.601 108.281 104.807 108.927 106.013C107.944 106.479 106.845 106.515 105.876 106.156C104.907 105.812 104.067 105.101 103.608 104.096L103.694 104.283L103.508 104.369C103.443 104.39 103.386 104.419 103.328 104.441C103.859 105.489 104.785 106.228 105.84 106.572C106.888 106.939 108.073 106.874 109.121 106.372L109.279 105.84L108.439 104.232C108.094 103.572 107.764 102.883 107.434 102.208L107.441 102.201Z"
      :fill="background"
    />
    <path
      d="M103.694 104.276L103.608 104.089L103.522 103.902L103.328 104.426C103.386 104.405 103.443 104.376 103.508 104.355L103.694 104.268V104.276Z"
      :fill="background"
    />
    <path
      d="M102.782 102.259L103.529 103.91L104.85 100.328C103.96 100.012 102.962 99.9977 102 100.364C102.251 100.995 102.51 101.627 102.782 102.259Z"
      :fill="fill"
    />
    <path
      d="M101.842 100.005C101.871 100.062 101.893 100.12 101.921 100.177C102.201 100.866 102.481 101.57 102.783 102.266C102.51 101.634 102.251 100.995 102 100.371C102.962 100.005 103.96 100.019 104.85 100.335L104.993 99.9546C104.01 99.6101 102.912 99.5957 101.849 100.012L101.842 100.005Z"
      :fill="background"
    />
    <path
      d="M103.156 104.075C102.582 102.847 102.051 101.584 101.555 100.335C101.139 99.3157 100.4 98.5333 99.488 98.1313L97.8945 101.598L97.9663 101.785C98.7343 103.845 101.132 104.9 103.156 104.075Z"
      :fill="fill"
    />
    <path
      d="M101.921 100.177C101.892 100.12 101.871 100.062 101.842 100.005C101.368 98.9785 100.6 98.1817 99.6599 97.7654L99.4876 98.1315C100.399 98.5334 101.131 99.3158 101.555 100.335C102.05 101.577 102.581 102.847 103.156 104.075C101.131 104.907 98.7339 103.852 97.9659 101.785L98.0377 101.972L97.8439 102.043C97.7793 102.065 97.7218 102.079 97.6572 102.101C98.576 104.261 101.153 105.345 103.328 104.426L103.522 103.903L102.775 102.252C102.474 101.555 102.194 100.859 101.914 100.163L101.921 100.177Z"
      :fill="background"
    />
    <path
      d="M98.0378 101.979L97.966 101.792L97.8942 101.605L97.6646 102.108C97.7292 102.086 97.7866 102.072 97.8512 102.051L98.045 101.979H98.0378Z"
      :fill="background"
    />
    <path
      d="M97.2916 99.8828C97.4782 100.457 97.6792 101.038 97.8946 101.598L99.4881 98.1314C98.6339 97.7509 97.672 97.6361 96.6743 97.9376C96.8681 98.5764 97.0763 99.2224 97.2916 99.8756V99.8828Z"
      :fill="fill"
    />
    <path
      d="M96.5591 97.5786C96.5806 97.636 96.6022 97.6934 96.6165 97.758C96.8318 98.4615 97.0544 99.1721 97.2912 99.8899C97.0759 99.2367 96.8677 98.5907 96.6739 97.9518C97.6717 97.6432 98.6335 97.7652 99.4877 98.1456L99.6599 97.7724C98.7268 97.3489 97.6573 97.2412 96.5591 97.5858V97.5786Z"
      :fill="background"
    />
    <path
      d="M97.514 101.735C97.0474 100.443 96.6167 99.1507 96.2506 97.8731C95.9635 96.7892 95.2744 96.014 94.4633 95.49L92.4966 98.7559L92.5468 98.9498C92.8052 100.041 93.5517 100.866 94.4562 101.397C95.3749 101.893 96.4803 102.043 97.5211 101.728L97.514 101.735Z"
      :fill="fill"
    />
    <path
      d="M96.6167 97.7509C96.5952 97.6935 96.5737 97.6361 96.5593 97.5715C96.2219 96.4661 95.5041 95.6765 94.6715 95.1382L94.4633 95.4899C95.2744 96.0139 95.9635 96.7891 96.2506 97.873C96.6239 99.1506 97.0474 100.435 97.514 101.735C96.4732 102.051 95.3678 101.9 94.449 101.405C93.5446 100.873 92.8052 100.048 92.5397 98.9568L92.5899 99.1578L92.3961 99.2081C92.3315 99.2224 92.2669 99.2296 92.2095 99.244C92.5397 100.4 93.3651 101.261 94.3557 101.814C95.3678 102.316 96.5449 102.481 97.6719 102.115L97.9016 101.613C97.6862 101.053 97.4852 100.471 97.2986 99.8972C97.0617 99.1794 96.8392 98.4687 96.6239 97.7653L96.6167 97.7509Z"
      :fill="background"
    />
    <path
      d="M92.5898 99.1577L92.5395 98.9567L92.4893 98.7629L92.2021 99.2367C92.2667 99.2223 92.3242 99.2151 92.3888 99.2008L92.5826 99.1505L92.5898 99.1577Z"
      :fill="background"
    />
    <path
      d="M92.0804 96.9972C92.2096 97.5786 92.346 98.1672 92.4895 98.7558L94.4563 95.4898C94.0687 95.253 93.6308 95.095 93.1714 95.0089C92.7264 94.9156 92.2383 94.9012 91.7358 94.9874C91.8363 95.6477 91.944 96.3225 92.0804 96.99V96.9972Z"
      :fill="fill"
    />
    <path
      d="M91.6782 94.6141C91.6926 94.6715 91.6998 94.7361 91.7141 94.7936C91.8218 95.5114 91.9366 96.2579 92.0874 96.9972C91.951 96.3225 91.8433 95.6477 91.7428 94.9945C92.2453 94.9084 92.7262 94.9228 93.1784 95.0161C93.6378 95.095 94.0757 95.2601 94.4633 95.497L94.6714 95.1453C94.2479 94.8797 93.7598 94.7218 93.2645 94.6213C92.7693 94.5208 92.2381 94.5136 91.6782 94.6141Z"
      :fill="background"
    />
    <path
      d="M92.0946 98.8492C91.75 97.5356 91.506 96.179 91.3265 94.8798C91.2404 94.3486 91.104 93.8318 90.8671 93.4083C90.6374 92.9776 90.3216 92.6187 89.9914 92.3101L87.4863 95.1884L87.5007 95.3894C87.5366 95.9564 87.7232 96.4661 87.996 96.9255C88.2687 97.3777 88.6204 97.8227 89.0583 98.1242C89.9196 98.7558 91.0107 99.0645 92.0946 98.8492Z"
      :fill="fill"
    />
    <path
      d="M91.7141 94.7937C91.6998 94.7291 91.6926 94.6717 91.6782 94.6142C91.5706 94.0759 91.4127 93.5591 91.1686 93.1212C90.9317 92.6834 90.6016 92.3173 90.2642 92.0015L89.9986 92.3101C90.3288 92.6259 90.6446 92.9777 90.8743 93.4083C91.1112 93.8318 91.2404 94.3486 91.3337 94.8798C91.5203 96.179 91.7644 97.5357 92.1017 98.8492C91.0179 99.0645 89.9268 98.7559 89.0655 98.1242C88.6276 97.8228 88.2831 97.3849 88.0031 96.9255C87.7304 96.459 87.5509 95.9565 87.5079 95.3894L87.5222 95.5976L87.3212 95.612C87.2566 95.612 87.192 95.612 87.1274 95.612C87.4361 97.9879 89.8838 99.7536 92.2022 99.244L92.4894 98.7703C92.3386 98.1745 92.2022 97.5859 92.0802 97.0117C91.9295 96.2723 91.8146 95.5258 91.707 94.808L91.7141 94.7937Z"
      :fill="background"
    />
    <path
      d="M87.5295 95.5976L87.5152 95.3895L87.5008 95.1885L87.1348 95.612C87.1994 95.612 87.264 95.612 87.3286 95.612L87.5295 95.5976Z"
      :fill="background"
    />
    <path
      d="M87.4072 93.3794C87.4216 93.968 87.4503 94.571 87.4934 95.1811L89.9985 92.3028C89.6826 92.0156 89.2878 91.8003 88.8787 91.6424C88.4767 91.4773 88.0245 91.384 87.5149 91.3696C87.4575 92.0156 87.4216 92.6832 87.4144 93.3723L87.4072 93.3794Z"
      :fill="fill"
    />
    <path
      d="M87.5364 91.0107C87.5364 91.0682 87.5364 91.1256 87.5293 91.1902C87.4646 91.8936 87.4216 92.6258 87.4072 93.3866C87.4216 92.6976 87.4575 92.0228 87.5077 91.384C88.0102 91.3984 88.4696 91.4917 88.8715 91.6568C89.2807 91.8147 89.6755 92.03 89.9913 92.3171L90.2569 92.0085C89.9195 91.6926 89.4673 91.4701 89.0366 91.2979C88.5988 91.1256 88.0891 91.0323 87.5293 91.0179L87.5364 91.0107Z"
      :fill="background"
    />
    <path
      d="M87.0916 95.2027C86.9768 93.8389 87.0127 92.4535 87.1491 91.183C87.228 90.6447 87.2137 90.1494 87.1132 89.7116C87.0199 89.2665 86.905 88.8574 86.6682 88.52L83.4022 90.4939L83.3591 90.6949C83.2515 91.2333 83.2658 91.8219 83.4166 92.3602C83.5816 92.8842 83.8544 93.3867 84.1989 93.8317C84.9383 94.6643 85.9719 95.2242 87.0845 95.2027H87.0916Z"
      :fill="fill"
    />
    <path
      d="M87.5292 91.183C87.5292 91.1256 87.5292 91.0682 87.5364 91.0036C87.601 90.4509 87.5794 89.9484 87.4718 89.5034C87.3641 89.0584 87.2564 88.6492 87.0124 88.3047L86.6678 88.5128C86.9047 88.8502 87.0195 89.2594 87.1129 89.7044C87.2134 90.1422 87.2277 90.6375 87.1487 91.1759C87.0124 92.4464 86.9693 93.8317 87.0913 95.1955C85.9787 95.217 84.9451 94.6572 84.2058 93.8245C83.8613 93.3795 83.5885 92.877 83.4234 92.353C83.2798 91.8075 83.2655 91.2261 83.366 90.6878L83.3301 90.8887L83.1291 90.8385C83.0645 90.817 82.9999 90.7954 82.9353 90.7811C82.8492 91.3697 82.8994 92.0013 83.0717 92.5899C83.2727 93.157 83.5813 93.7025 83.9761 94.1762C84.3924 94.6141 84.8805 94.9802 85.4117 95.2457C85.95 95.497 86.5458 95.6046 87.1344 95.6118L87.5005 95.1883C87.4574 94.571 87.4287 93.9752 87.4143 93.3867C87.4287 92.6258 87.4718 91.8937 87.5364 91.1902L87.5292 91.183Z"
      :fill="background"
    />
    <path
      d="M83.323 90.8961L83.3589 90.6951L83.402 90.4941L82.9282 90.7813C82.9928 90.8028 83.0574 90.8243 83.122 90.8387L83.323 90.8889V90.8961Z"
      :fill="background"
    />
    <path
      d="M83.9335 88.7499C83.7397 89.2882 83.5244 89.8983 83.4023 90.4941L86.6683 88.5202C86.2879 87.8598 85.6562 87.386 84.802 87.0056C84.479 87.5296 84.1345 88.1326 83.9335 88.7499Z"
      :fill="fill"
    />
    <path
      d="M84.9814 86.7041C84.9527 86.7543 84.924 86.7974 84.8953 86.8477C84.5436 87.4075 84.1488 88.0679 83.9263 88.7498C84.1344 88.1325 84.479 87.5296 84.7948 87.0056C85.649 87.3788 86.2806 87.8597 86.6611 88.5201L87.0128 88.312C86.7903 87.9674 86.5677 87.6588 86.1945 87.4004C85.8571 87.142 85.4911 86.9051 84.9814 86.7041Z"
      :fill="background"
    />
    <path
      d="M83.0003 90.3793C83.1798 89.6759 83.3951 89.0227 83.6248 88.4197C83.9048 87.7953 84.2206 87.2282 84.5436 86.7186C84.8522 86.2879 85.0819 85.8859 85.1968 85.5127C85.3475 85.1322 85.448 84.7733 85.4049 84.4216H81.5935L81.4643 84.5724C81.1054 84.9959 80.8398 85.527 80.6962 86.1084C80.6173 86.6755 80.6244 87.3 80.7752 87.8886C80.969 88.4484 81.2346 89.0011 81.615 89.4318C82.0241 89.8481 82.4979 90.1783 83.0075 90.3793H83.0003Z"
      :fill="fill"
    />
    <path
      d="M84.8952 86.8549C84.9239 86.8046 84.9526 86.7615 84.9813 86.7113C85.2469 86.2734 85.5053 85.8643 85.6058 85.4982C85.7422 85.125 85.857 84.7733 85.8068 84.4287H85.3976C85.4407 84.7804 85.3402 85.1393 85.1895 85.5198C85.0818 85.893 84.8449 86.3022 84.5363 86.7256C84.2133 87.2353 83.8974 87.8023 83.6175 88.4268C83.3878 89.0298 83.1725 89.683 82.993 90.3864C82.4834 90.1854 82.0096 89.8552 81.6005 89.4389C81.2129 89.0082 80.9545 88.4555 80.7607 87.8957C80.6099 87.3142 80.6028 86.6826 80.6817 86.1155C80.8253 85.5341 81.0909 84.9958 81.4498 84.5794L81.3206 84.7374L81.1698 84.5866C81.1196 84.5364 81.0765 84.479 81.0334 84.4287C80.6674 84.9096 80.409 85.5054 80.2726 86.1586C80.208 86.7902 80.2223 87.4865 80.4233 88.1182C80.6602 88.7283 80.9329 89.3528 81.3923 89.7906C81.8517 90.2357 82.3326 90.6161 82.9284 90.7884L83.4022 90.5012C83.517 89.9055 83.7395 89.3025 83.9333 88.757C84.1558 88.0823 84.5506 87.4219 84.9023 86.8549H84.8952Z"
      :fill="background"
    />
    <path
      d="M81.3205 84.7303L81.4497 84.5724L81.5789 84.4216H81.019C81.0621 84.4719 81.1124 84.5221 81.1554 84.5795L81.3062 84.7303H81.3205Z"
      :fill="background"
    />
    <path
      d="M82.9641 83.2227C82.5119 83.5673 82.0453 83.9692 81.5859 84.4214H85.3974C85.4333 84.0841 85.3615 83.7539 85.2108 83.395C85.1175 83.0433 84.9237 82.67 84.6437 82.2681C84.1054 82.5193 83.5024 82.8279 82.9569 83.2156L82.9641 83.2227Z"
      :fill="fill"
    />
    <path
      d="M84.9814 82.1318C84.9239 82.1534 84.8737 82.1749 84.8163 82.2036C84.2133 82.462 83.5673 82.7994 82.9644 83.2229C83.5099 82.8353 84.1128 82.5266 84.6512 82.2754C84.9311 82.6774 85.1249 83.0506 85.2182 83.4023C85.369 83.7612 85.4407 84.0914 85.4049 84.4288H85.814C85.8642 84.0842 85.7494 83.7325 85.613 83.3593C85.5125 82.986 85.2541 82.5769 84.9885 82.1462L84.9814 82.1318Z"
      :fill="background"
    />
    <path
      d="M81.3278 84.1057C82.3327 83.0792 83.4668 82.3543 84.6296 81.902C85.1393 81.7154 85.4982 81.4857 85.8499 81.2273C86.2231 80.9761 86.4457 80.6603 86.6682 80.3157L83.4022 78.3418L83.2012 78.392C82.6629 78.5428 82.1891 78.8586 81.7657 79.2462C81.3278 79.6338 81.0694 80.1865 80.8469 80.732C80.6315 81.2847 80.6244 81.9308 80.6674 82.505C80.732 83.0864 81.012 83.6463 81.3278 84.0985V84.1057Z"
      :fill="fill"
    />
    <path
      d="M84.8162 82.2037C84.8736 82.1821 84.9239 82.1606 84.9813 82.1319C85.4838 81.9309 85.857 81.7012 86.1944 81.4356C86.5676 81.1772 86.7901 80.8758 87.0126 80.524L86.6681 80.3159C86.4384 80.6604 86.2159 80.9691 85.8498 81.2275C85.4981 81.4859 85.1392 81.7156 84.6296 81.9022C83.4668 82.3544 82.3326 83.0794 81.3277 84.1058C81.0047 83.6608 80.732 83.1009 80.6674 82.5123C80.6243 81.9381 80.6243 81.2921 80.8468 80.7394C81.0693 80.1867 81.3277 79.634 81.7656 79.2535C82.1891 78.8588 82.6628 78.5501 83.2012 78.3994C83.1366 78.4209 83.0648 78.4353 83.0002 78.4568C82.9787 78.385 82.9715 78.3204 82.9571 78.2558C82.9499 78.1912 82.9356 78.1266 82.9284 78.062C82.3326 78.2415 81.8517 78.6147 81.3923 79.0597C80.9258 79.4976 80.653 80.1221 80.4233 80.7322C80.2223 81.3639 80.208 82.0601 80.2726 82.6918C80.409 83.345 80.6674 83.9407 81.0334 84.4217H81.5933C82.0527 83.9694 82.5193 83.5675 82.9715 83.2229C83.5816 82.7994 84.2276 82.4621 84.8234 82.2037H84.8162Z"
      :fill="background"
    />
    <path
      d="M83 78.4495C83.0646 78.4279 83.1364 78.4136 83.201 78.392L83.402 78.3418L82.9282 78.0547C82.9354 78.1193 82.9426 78.1839 82.9569 78.2485C82.9713 78.3131 82.9785 78.3777 83 78.4495Z"
      :fill="background"
    />
    <path
      d="M85.1825 77.9828C84.6082 78.069 84.0196 78.191 83.4023 78.3417L86.6683 80.3156C86.8908 79.9926 87.0128 79.6194 87.0918 79.2031C87.1995 78.7939 87.2282 78.3417 87.1708 77.8464C86.5319 77.8608 85.8572 77.9039 85.1753 77.9828H85.1825Z"
      :fill="fill"
    />
    <path
      d="M87.5365 77.8323C87.4791 77.8323 87.4216 77.8323 87.3642 77.8466C86.668 77.861 85.9286 77.8969 85.1821 77.9902C85.864 77.9112 86.5388 77.8682 87.1776 77.8538C87.2278 78.3491 87.2063 78.8085 87.0986 79.2104C87.0197 79.6268 86.8977 80 86.6751 80.323L87.0269 80.5312C87.2709 80.1866 87.3714 79.7775 87.4862 79.3325C87.5939 78.8874 87.6154 78.385 87.5508 77.8323H87.5365Z"
      :fill="background"
    />
    <path
      d="M83.3302 77.947C84.6797 77.5881 86.0435 77.4804 87.3211 77.466C87.8738 77.4732 88.3691 77.3871 88.7998 77.222C89.2305 77.0569 89.6683 76.8416 89.9985 76.5329L87.4934 73.6546H87.2924C86.7397 73.6187 86.187 73.6976 85.6846 73.913C85.1893 74.1355 84.7299 74.4585 84.3351 74.8533C83.9619 75.2624 83.6748 75.7721 83.4809 76.2889C83.2871 76.7985 83.2584 77.4014 83.323 77.947H83.3302Z"
      :fill="fill"
    />
    <path
      d="M87.3643 77.8393C87.4217 77.8393 87.4792 77.8321 87.5366 77.8249C88.0965 77.8177 88.6061 77.7173 89.0439 77.545C89.4746 77.3655 89.9268 77.1502 90.2642 76.8344L89.9986 76.5257C89.6684 76.8344 89.2306 77.0497 88.7999 77.2148C88.3692 77.3799 87.8668 77.466 87.3212 77.4589C86.0436 77.4732 84.6798 77.5809 83.3303 77.9398C83.2657 77.4014 83.2872 76.7985 83.4882 76.2817C83.682 75.7649 83.9692 75.2624 84.3424 74.8461C84.73 74.4513 85.1966 74.1283 85.6919 73.9058C86.1943 73.6904 86.7542 73.6115 87.2997 73.633H87.0987L87.1131 73.4177C87.1203 73.3531 87.1274 73.2885 87.1418 73.2239C86.5532 73.2239 85.9574 73.3387 85.4191 73.59C84.8879 73.8555 84.3998 74.2216 83.9835 74.6595C83.5959 75.1332 83.2801 75.6787 83.0791 76.2458C82.8996 76.8344 82.8566 77.466 82.9427 78.0546L83.4165 78.3417C84.0266 78.191 84.6223 78.069 85.1966 77.9828C85.9431 77.8895 86.6824 77.8536 87.3787 77.8393H87.3643Z"
      :fill="background"
    />
    <path
      d="M87.0916 73.6332H87.2926L87.4936 73.6547L87.1275 73.2312C87.1204 73.2958 87.1132 73.3604 87.0988 73.425L87.0845 73.6332H87.0916Z"
      :fill="background"
    />
    <path
      d="M89.3025 73.8269C88.6995 73.7408 88.0966 73.6762 87.4937 73.6475L89.9987 76.5258C90.3074 76.2315 90.6017 75.8942 90.8314 75.4994C91.0611 75.1046 91.2046 74.638 91.298 74.1427C90.6376 74.0135 89.9916 73.913 89.3097 73.8197L89.3025 73.8269Z"
      :fill="fill"
    />
    <path
      d="M91.6781 74.2217C91.6135 74.2145 91.5561 74.2002 91.4915 74.193C90.7594 74.0351 90.0559 73.9346 89.3022 73.8269C89.9841 73.9202 90.6373 74.0135 91.2905 74.1499C91.1972 74.6452 91.0537 75.1118 90.824 75.5065C90.5943 75.9013 90.3 76.2387 89.9913 76.533L90.2569 76.8416C90.5943 76.5186 90.9173 76.1597 91.1613 75.7219C91.4054 75.284 91.5633 74.7672 91.671 74.2289L91.6781 74.2217Z"
      :fill="background"
    />
    <path
      d="M87.5293 73.2455C88.2112 73.2742 88.9075 73.3819 89.575 73.468C90.2426 73.5613 90.8886 73.669 91.5489 73.8125C92.0873 73.9274 92.6113 73.9346 93.0922 73.8413C93.5803 73.7623 94.054 73.5972 94.4632 73.346L92.4964 70.08L92.3026 70.0298C90.1492 69.4555 87.8523 71.0562 87.5365 73.2383L87.5293 73.2455Z"
      :fill="fill"
    />
    <path
      d="M91.4918 74.1929C91.5564 74.2001 91.6138 74.2144 91.6784 74.2216C92.2383 74.3149 92.7694 74.3149 93.2647 74.2144C93.76 74.1211 94.2481 73.956 94.6716 73.6904L94.4634 73.3387C94.0543 73.5899 93.5805 73.755 93.0924 73.834C92.6115 73.9201 92.0947 73.9201 91.5492 73.8053C90.8888 73.6617 90.2428 73.554 89.5753 73.4607C88.9149 73.3746 88.2115 73.2669 87.5295 73.2382C87.8454 71.0561 90.1423 69.4555 92.2957 70.0297L92.1019 69.9866L92.1521 69.7928C92.1737 69.7282 92.188 69.6708 92.2096 69.6062C89.8911 69.0894 87.4434 70.8623 87.1348 73.2382L87.5008 73.6617C88.1038 73.6904 88.7067 73.755 89.3097 73.8412C90.0634 73.9488 90.7668 74.0493 91.4989 74.2072L91.4918 74.1929Z"
      :fill="background"
    />
    <path
      d="M92.1019 69.9866L92.2957 70.0297L92.4895 70.0799L92.2024 69.6062C92.1809 69.6708 92.1665 69.7282 92.145 69.7928L92.0947 69.9866H92.1019Z"
      :fill="background"
    />
    <path
      d="M94.248 70.5466C93.6594 70.3672 93.0852 70.2236 92.4966 70.0801L94.4633 73.346C95.2242 72.8579 95.8846 72.1473 96.1932 71.1496C95.5544 70.9414 94.9084 70.7404 94.248 70.5395V70.5466Z"
      :fill="fill"
    />
    <path
      d="M96.5593 71.2644C96.5019 71.2501 96.4445 71.2285 96.3799 71.2142C95.6836 70.9845 94.973 70.762 94.248 70.5466C94.9084 70.7404 95.5544 70.9414 96.1933 71.1568C95.8846 72.1473 95.2242 72.8579 94.4634 73.3532L94.6715 73.7049C95.5042 73.1666 96.222 72.377 96.5593 71.2716V71.2644Z"
      :fill="background"
    />
    <path
      d="M92.5972 69.6852C93.9251 70.0082 95.2386 70.4245 96.4876 70.8337C97.5499 71.2141 98.5836 71.1136 99.4952 70.7045L97.9017 67.2375L97.715 67.1657C96.6814 66.7781 95.576 66.9002 94.6285 67.338C93.7026 67.8333 92.9202 68.6229 92.6043 69.6852H92.5972Z"
      :fill="fill"
    />
    <path
      d="M96.38 71.214C96.4446 71.2284 96.5021 71.2499 96.5595 71.2643C97.6577 71.6017 98.7272 71.494 99.6604 71.0777L99.4881 70.7116C98.5837 71.1207 97.5429 71.214 96.4805 70.8408C95.2316 70.4317 93.918 70.0153 92.5901 69.6923C92.9059 68.63 93.6883 67.8404 94.6143 67.3451C95.5618 66.9073 96.6672 66.7853 97.7008 67.1729L97.507 67.1011L97.5788 66.9145C97.6075 66.857 97.629 66.7996 97.6577 66.7422C96.538 66.3761 95.3536 66.5412 94.3415 67.0437C93.351 67.5964 92.5255 68.4577 92.1953 69.6134L92.4824 70.0871C93.071 70.2307 93.6524 70.3742 94.2338 70.5537C94.9588 70.769 95.6694 70.9915 96.3657 71.2212L96.38 71.214Z"
      :fill="background"
    />
    <path
      d="M97.5137 67.1011L97.7075 67.1729L97.8941 67.2446L97.6644 66.7422C97.6357 66.7996 97.6142 66.857 97.5855 66.9145L97.5137 67.1011Z"
      :fill="background"
    />
    <path
      d="M99.5954 67.8978L97.9014 67.2446L99.4949 70.7116C100.349 70.3383 101.045 69.6133 101.483 68.6946C100.859 68.4146 100.227 68.149 99.5954 67.905V67.8978Z"
      :fill="fill"
    />
    <path
      d="M101.842 68.8383C101.785 68.8167 101.727 68.7952 101.663 68.7737C100.974 68.465 100.285 68.1707 99.5883 67.8979C100.22 68.142 100.852 68.4076 101.476 68.6875C101.038 69.6135 100.342 70.3313 99.4878 70.7045L99.6601 71.0778C100.6 70.6615 101.361 69.8647 101.842 68.8383Z"
      :fill="background"
    />
    <path
      d="M98.0453 66.8643C99.3301 67.3453 100.579 67.8405 101.814 68.4004C102.833 68.8455 103.895 68.8455 104.843 68.5081L103.522 64.9263L103.335 64.8402C101.34 63.8999 98.8994 64.8545 98.0381 66.8572L98.0453 66.8643Z"
      :fill="fill"
    />
    <path
      d="M101.663 68.7665C101.72 68.788 101.777 68.8095 101.842 68.8311C102.904 69.2474 104.003 69.233 104.986 68.8885L104.842 68.5081C103.895 68.8454 102.825 68.8454 101.813 68.4004C100.579 67.8405 99.3297 67.3453 98.0448 66.8643C98.9062 64.8617 101.347 63.8998 103.342 64.8473L103.156 64.7612L103.242 64.5746C103.27 64.5171 103.299 64.4597 103.328 64.4023C101.16 63.4835 98.576 64.5746 97.6572 66.7279L97.8869 67.2304L99.5809 67.8836C100.277 68.1564 100.966 68.4507 101.655 68.7593L101.663 68.7665Z"
      :fill="background"
    />
    <path
      d="M103.156 64.7611L103.342 64.8473L103.529 64.9334L103.335 64.4094C103.306 64.4668 103.278 64.5243 103.249 64.5817L103.163 64.7683L103.156 64.7611Z"
      :fill="background"
    />
    <path
      d="M105.159 65.7015L103.522 64.9263L104.843 68.5081C105.726 68.1707 106.501 67.5319 106.996 66.6274L105.159 65.6943V65.7015Z"
      :fill="fill"
    />
    <path
      d="M107.347 66.8068C107.29 66.7781 107.233 66.7566 107.175 66.7279L105.158 65.7014L106.996 66.6345C106.5 67.539 105.725 68.1778 104.842 68.5152L104.986 68.8956C105.955 68.5223 106.809 67.8189 107.347 66.8068Z"
      :fill="background"
    />
    <path
      d="M103.701 64.5673C104.936 65.1272 106.156 65.7516 107.355 66.3689C108.338 66.8714 109.422 66.9575 110.391 66.6632L109.293 63.0097L109.113 62.9164C107.182 61.8684 104.713 62.6292 103.701 64.5673Z"
      :fill="fill"
    />
    <path
      d="M107.175 66.728C107.233 66.7567 107.29 66.7782 107.348 66.8069C108.381 67.2807 109.494 67.3596 110.499 67.0581L110.384 66.6705C109.415 66.9648 108.331 66.8787 107.348 66.3762C106.156 65.7589 104.936 65.1345 103.694 64.5746C104.699 62.6365 107.175 61.8757 109.106 62.9237L108.927 62.8303L109.02 62.6509C109.056 62.5935 109.085 62.5432 109.121 62.4858C108.073 61.9834 106.888 61.9188 105.84 62.2848C104.785 62.6294 103.866 63.3687 103.328 64.4167L103.522 64.9407L105.158 65.7159L107.175 66.7423V66.728Z"
      :fill="background"
    />
    <path
      d="M108.927 62.8231L109.106 62.9164L109.286 63.0097L109.128 62.4785C109.092 62.5359 109.063 62.5862 109.027 62.6436L108.934 62.8231H108.927Z"
      :fill="background"
    />
    <path
      d="M110.872 63.8783L109.286 63.0098L110.384 66.6633C111.288 66.3762 112.114 65.7948 112.652 64.9119L110.872 63.8855V63.8783Z"
      :fill="fill"
    />
    <path
      d="M112.997 65.0987C112.939 65.07 112.889 65.0412 112.831 65.0125L110.872 63.8784L112.652 64.9049C112.106 65.7877 111.288 66.3692 110.384 66.6563L110.499 67.0439C111.496 66.7281 112.401 66.0749 112.997 65.0915V65.0987Z"
      :fill="background"
    />
    <path
      d="M109.479 62.658C110.678 63.2897 111.863 63.986 113.025 64.6679C113.987 65.2206 115.064 65.3641 116.054 65.1201L115.136 61.4163L114.963 61.3158C113.09 60.1673 110.599 60.799 109.479 62.6652V62.658Z"
      :fill="fill"
    />
    <path
      d="M112.831 65.0124C112.889 65.0411 112.946 65.0698 112.997 65.0985C114.009 65.6297 115.121 65.7589 116.148 65.5077L116.047 65.1129C115.064 65.3569 113.98 65.2134 113.018 64.6607C111.855 63.986 110.671 63.2897 109.472 62.6509C110.592 60.7846 113.075 60.1601 114.956 61.3014L114.784 61.2009L114.884 61.0287C114.92 60.9712 114.956 60.921 114.992 60.8707C112.946 59.7366 110.305 60.4544 109.113 62.4786L109.271 63.0098L110.858 63.8783L112.817 65.0124H112.831Z"
      :fill="background"
    />
    <path
      d="M114.791 61.2082L114.963 61.3087L115.136 61.4092L114.999 60.8708C114.963 60.9211 114.927 60.9785 114.892 61.0288L114.791 61.201V61.2082Z"
      :fill="background"
    />
    <path
      d="M116.679 62.3567L115.136 61.4092L116.055 65.113C116.98 64.8761 117.827 64.3234 118.416 63.4692L116.679 62.3567Z"
      :fill="fill"
    />
    <path
      d="M118.746 63.6774C118.689 63.6487 118.639 63.6128 118.581 63.5841L116.672 62.3567L118.409 63.4693C117.82 64.3234 116.973 64.8761 116.047 65.113L116.148 65.5078C117.167 65.2422 118.1 64.6249 118.746 63.6703V63.6774Z"
      :fill="background"
    />
    <path
      d="M115.344 61.0718C116.507 61.7681 117.662 62.5074 118.789 63.2467C119.722 63.8497 120.799 64.022 121.804 63.8282L121.043 60.0885L120.878 59.9736C119.966 59.3635 118.882 59.1769 117.885 59.385C116.88 59.586 115.954 60.1531 115.351 61.0647L115.344 61.0718Z"
      :fill="fill"
    />
    <path
      d="M118.581 63.584C118.639 63.6198 118.689 63.6486 118.746 63.6773C119.737 64.2587 120.842 64.4238 121.883 64.2228L121.804 63.8208C120.806 64.0146 119.73 63.8424 118.789 63.2394C117.655 62.5073 116.507 61.7608 115.344 61.0645C115.947 60.1529 116.873 59.5859 117.878 59.3849C118.883 59.1767 119.959 59.3562 120.871 59.9735L120.706 59.8586L120.814 59.6935C120.85 59.6433 120.893 59.593 120.928 59.5356C119.931 58.9255 118.768 58.7676 117.698 59.0116C116.622 59.2485 115.638 59.8801 115 60.8635L115.136 61.4019L116.679 62.3494L118.588 63.5768L118.581 63.584Z"
      :fill="background"
    />
    <path
      d="M120.713 59.8659L120.878 59.9807L121.043 60.0956L120.935 59.55C120.9 59.6003 120.864 59.6505 120.821 59.708L120.713 59.8731V59.8659Z"
      :fill="background"
    />
    <path
      d="M122.55 61.1005L121.05 60.0884L121.811 63.8281C122.751 63.6415 123.62 63.1031 124.244 62.2776C123.685 61.8829 123.125 61.4809 122.558 61.1005H122.55Z"
      :fill="fill"
    />
    <path
      d="M124.567 62.4929C124.517 62.457 124.46 62.4283 124.409 62.3924C123.792 61.9545 123.175 61.5167 122.558 61.1003C123.125 61.4808 123.684 61.8827 124.244 62.2775C123.62 63.103 122.751 63.6413 121.811 63.828L121.89 64.2299C122.924 64.0146 123.885 63.4188 124.567 62.5V62.4929Z"
      :fill="background"
    />
    <path
      d="M121.273 59.7511C122.407 60.5048 123.527 61.2728 124.632 62.0624C125.551 62.7156 126.613 62.9094 127.625 62.7586L127.008 58.9974L126.843 58.8826C125.07 57.6049 122.558 57.9997 121.28 59.7583L121.273 59.7511Z"
      :fill="fill"
    />
    <path
      d="M124.403 62.3924C124.46 62.4283 124.51 62.457 124.561 62.4929C125.53 63.1245 126.628 63.3111 127.683 63.1532L127.618 62.7513C126.606 62.902 125.544 62.7082 124.625 62.055C123.52 61.2654 122.4 60.4974 121.266 59.7437C122.544 57.9779 125.056 57.5903 126.829 58.868L126.664 58.746L126.779 58.5809C126.822 58.5306 126.858 58.4804 126.901 58.4302C124.955 57.1453 122.292 57.6262 120.921 59.5284L121.029 60.0739L122.529 61.086C123.154 61.5023 123.771 61.9473 124.381 62.378L124.403 62.3924Z"
      :fill="background"
    />
    <path
      d="M126.671 58.7604L126.836 58.8824L127.001 58.9973L126.908 58.4446C126.865 58.4948 126.829 58.5451 126.786 58.5953L126.671 58.7604Z"
      :fill="background"
    />
    <path
      d="M128.465 60.0669L127.001 58.9902L127.618 62.7515C128.566 62.6007 129.463 62.1055 130.109 61.2872L128.465 60.0597V60.0669Z"
      :fill="fill"
    />
    <path
      d="M130.432 61.524C130.382 61.4881 130.331 61.4522 130.274 61.4163L128.465 60.0669L130.109 61.2943C129.456 62.1126 128.566 62.6079 127.618 62.7586L127.683 63.1606C128.731 62.9955 129.714 62.4284 130.425 61.524H130.432Z"
      :fill="background"
    />
    <path
      d="M127.238 58.6742C128.343 59.4781 129.434 60.2749 130.511 61.1003C131.401 61.7822 132.471 62.0406 133.483 61.8971L132.994 58.1143L132.837 57.9923C131.107 56.6572 128.594 56.9659 127.245 58.6742H127.238Z"
      :fill="fill"
    />
    <path
      d="M130.274 61.4163C130.324 61.4522 130.381 61.4881 130.432 61.524C131.379 62.1915 132.477 62.4427 133.532 62.292L133.482 61.89C132.47 62.0336 131.408 61.7752 130.511 61.0933C129.434 60.2678 128.35 59.4711 127.237 58.6671C128.58 56.9588 131.099 56.6501 132.829 57.9852L132.671 57.8632L132.793 57.7053C132.836 57.6551 132.872 57.6048 132.915 57.5617C131.968 56.8942 130.826 56.6573 129.735 56.8081C128.651 56.966 127.625 57.5402 126.907 58.4518L127.001 59.0045L128.465 60.0812L130.274 61.4306V61.4163Z"
      :fill="background"
    />
    <path
      d="M132.671 57.8704L132.829 57.9924L132.987 58.1145L132.915 57.5618C132.872 57.612 132.836 57.6551 132.793 57.7053L132.671 57.8632V57.8704Z"
      :fill="background"
    />
    <path
      d="M134.416 59.2341L132.987 58.1072L133.475 61.8899C134.43 61.7679 135.342 61.3157 136.016 60.519L134.409 59.2341H134.416Z"
      :fill="fill"
    />
    <path
      d="M136.332 60.763C136.282 60.7271 136.232 60.6913 136.181 60.6482L134.416 59.2341L136.023 60.519C135.349 61.3157 134.437 61.7679 133.482 61.89L133.533 62.2919C134.588 62.1627 135.593 61.6459 136.339 60.7559L136.332 60.763Z"
      :fill="background"
    />
    <path
      d="M133.239 57.7914L136.426 60.3323C137.294 61.0286 138.356 61.3373 139.369 61.2296L138.995 57.4325L138.837 57.3033C137.151 55.8964 134.631 56.1548 133.231 57.7914H133.239Z"
      :fill="fill"
    />
    <path
      d="M136.181 60.6555C136.232 60.6914 136.282 60.7273 136.332 60.7704C137.258 61.4451 138.349 61.7537 139.412 61.6461L139.368 61.2441C138.349 61.3518 137.287 61.0431 136.426 60.3469L133.239 57.8059C134.645 56.1693 137.158 55.9109 138.845 57.3178L138.687 57.1886L138.816 57.0306C138.859 56.9804 138.902 56.9373 138.945 56.8871C137.086 55.4659 134.409 55.7889 132.916 57.5618L132.987 58.1145L134.416 59.2415L136.181 60.6555Z"
      :fill="background"
    />
    <path
      d="M138.694 57.1813L138.852 57.3105L139.01 57.4397L138.952 56.887C138.909 56.9372 138.866 56.9803 138.823 57.0305L138.694 57.1884V57.1813Z"
      :fill="background"
    />
    <path
      d="M140.395 58.5953L139.002 57.4397L139.376 61.2368C140.33 61.1363 141.256 60.6985 141.96 59.9376L140.395 58.5953Z"
      :fill="fill"
    />
    <path
      d="M142.268 60.1815C142.218 60.1456 142.168 60.1026 142.117 60.0667L140.395 58.5952L141.96 59.9375C141.256 60.6983 140.33 61.1362 139.375 61.2367L139.419 61.6387C140.474 61.5238 141.493 61.0357 142.268 60.1887V60.1815Z"
      :fill="background"
    />
    <path
      d="M142.117 60.0669C142.168 60.11 142.218 60.1459 142.268 60.1818C143.173 60.9067 144.256 61.201 145.319 61.1436L145.29 60.7345C144.264 60.7919 143.216 60.4976 142.369 59.7583C141.342 58.8682 140.309 57.9853 139.261 57.124C140.711 55.5017 143.237 55.3582 144.881 56.7938L144.73 56.6574L144.859 56.5067C144.902 56.4564 144.946 56.4133 144.989 56.3703C143.173 54.9132 140.495 55.1213 138.945 56.8727L139.002 57.4254L140.395 58.5811L142.117 60.0525V60.0669Z"
      :fill="background"
    />
    <path
      d="M144.73 56.6645L144.881 56.8008L145.032 56.9372L144.996 56.3845C144.953 56.4276 144.91 56.4778 144.867 56.5209L144.738 56.6716L144.73 56.6645Z"
      :fill="background"
    />
    <path
      d="M147.917 59.514L146.388 58.143L145.032 56.9371L144.881 56.8007C143.237 55.3651 140.711 55.5087 139.261 57.1309C140.309 57.9922 141.35 58.8751 142.369 59.7652C143.216 60.5045 144.264 60.7988 145.29 60.7414C146.252 60.684 147.192 60.2677 147.917 59.514Z"
      :fill="fill"
    />
    <path
      d="M148.211 59.7726C148.161 59.7295 148.118 59.6936 148.068 59.6505L146.388 58.136L147.917 59.507C147.192 60.2607 146.252 60.6698 145.29 60.7344L145.319 61.1435C146.381 61.0789 147.415 60.6124 148.211 59.7726Z"
      :fill="background"
    />
    <path
      d="M145.297 56.6359L148.326 59.3491C149.152 60.11 150.193 60.4617 151.219 60.4186L151.068 56.6071L150.918 56.4708C150.114 55.7314 149.087 55.3725 148.068 55.4013C147.049 55.4371 146.044 55.8606 145.297 56.6359Z"
      :fill="fill"
    />
    <path
      d="M148.068 59.6505C148.118 59.6935 148.169 59.7294 148.212 59.7725C149.095 60.519 150.171 60.8779 151.241 60.8277L151.226 60.4185C150.2 60.4616 149.159 60.1099 148.334 59.349L145.305 56.6357C146.051 55.8605 147.056 55.437 148.075 55.4011C149.095 55.3796 150.128 55.7313 150.925 56.4707L150.774 56.3343L150.911 56.1835C150.961 56.1405 151.004 56.0902 151.047 56.0472C150.164 55.2935 149.044 54.9489 147.953 54.9992C146.862 55.071 145.793 55.5303 144.989 56.3702L145.025 56.9229L146.381 58.1288L148.061 59.6433L148.068 59.6505Z"
      :fill="background"
    />
    <path
      d="M150.774 56.3342L150.925 56.4706L151.075 56.607L151.054 56.0471C151.011 56.0902 150.968 56.1333 150.917 56.1835L150.781 56.3342H150.774Z"
      :fill="background"
    />
    <path
      d="M148.032 52.9534L148.061 54.3244C149.791 54.2956 151.205 55.6882 151.205 57.4037C151.205 59.1264 149.855 60.5261 148.183 60.5548L148.212 61.9258C150.602 61.8899 152.554 59.8729 152.561 57.3965C152.569 54.9273 150.537 52.9175 148.032 52.9605V52.9534Z"
      :fill="fill"
    />
    <path
      d="M39.9468 107.312L39.9755 108.683C41.6479 108.64 43.062 109.997 43.1194 111.719C43.184 113.435 41.8274 114.87 40.0975 114.921L40.1262 116.292C42.6313 116.234 44.5837 114.153 44.476 111.669C44.3684 109.207 42.337 107.262 39.9468 107.319V107.312Z"
      :fill="fill"
    />
    <path
      d="M38.052 112.028L36.7241 110.793L36.8749 114.605C37.8654 114.562 38.8416 114.167 39.5809 113.428L38.052 112.028Z"
      :fill="fill"
    />
    <path
      d="M39.8892 113.686C39.839 113.643 39.7888 113.607 39.7385 113.564L38.0517 112.028L39.5806 113.428C38.8413 114.167 37.8651 114.562 36.8745 114.605L36.8889 115.014C37.9871 114.964 39.071 114.519 39.8821 113.686H39.8892Z"
      :fill="background"
    />
    <path
      d="M39.7389 113.564C39.7891 113.607 39.8394 113.643 39.8896 113.686C40.7869 114.418 41.9138 114.748 43.012 114.684L42.9833 114.275C41.9282 114.339 40.8515 114.009 40.0117 113.262L36.9969 110.499C37.7578 109.731 38.7483 109.336 39.7461 109.315C40.7438 109.279 41.7559 109.609 42.5742 110.341L42.4234 110.205L42.5526 110.054C42.5957 110.004 42.6388 109.961 42.6819 109.918C41.7846 109.185 40.6936 108.855 39.6312 108.92C38.5617 108.97 37.5137 109.408 36.7026 110.241L36.7242 110.8L38.0521 112.035L39.7389 113.571V113.564Z"
      :fill="background"
    />
    <path
      d="M42.4233 110.204L42.5741 110.341L42.7248 110.477L42.6889 109.925C42.6459 109.968 42.6028 110.018 42.5597 110.061L42.4305 110.212L42.4233 110.204Z"
      :fill="background"
    />
    <path
      d="M42.5738 110.341C41.7555 109.616 40.7435 109.279 39.7457 109.315C38.748 109.343 37.7574 109.738 36.9966 110.499L40.0113 113.262C40.8511 114.009 41.935 114.339 42.983 114.275L42.7246 110.47L42.5738 110.334V110.341Z"
      :fill="fill"
    />
    <path
      d="M44.0884 111.669L42.7246 110.47L42.983 114.275C43.9736 114.224 44.9354 113.772 45.6604 113.011L44.0884 111.669Z"
      :fill="fill"
    />
    <path
      d="M45.9694 113.262C45.9192 113.227 45.8689 113.183 45.8187 113.148L44.0888 111.669L45.6608 113.011C44.943 113.772 43.974 114.224 42.9834 114.275L43.0121 114.684C44.1103 114.619 45.1798 114.117 45.9694 113.262Z"
      :fill="background"
    />
    <path
      d="M42.9902 110.176C44.0095 111.08 45.0359 111.956 46.0767 112.846C46.9381 113.586 48.0291 113.887 49.0843 113.772L48.711 109.975L48.5603 109.846C46.8878 108.468 44.4761 108.597 42.9902 110.176Z"
      :fill="fill"
    />
    <path
      d="M45.8185 113.147C45.8688 113.191 45.919 113.226 45.9693 113.262C46.8952 113.987 48.0222 114.289 49.1204 114.174L49.0773 113.772C48.0293 113.887 46.9383 113.578 46.0698 112.846C45.029 111.956 43.9953 111.08 42.9833 110.176C44.4619 108.597 46.8809 108.467 48.5533 109.846L48.4026 109.716L48.5318 109.558C48.5749 109.508 48.6179 109.465 48.661 109.415C46.8235 108.022 44.2753 108.216 42.689 109.91L42.7249 110.463L44.0887 111.662L45.8185 113.14V113.147Z"
      :fill="background"
    />
    <path
      d="M48.4025 109.716L48.5533 109.846L48.704 109.975L48.6466 109.422C48.6035 109.472 48.5605 109.515 48.5174 109.566L48.3882 109.724L48.4025 109.716Z"
      :fill="background"
    />
    <path
      d="M50.1106 111.131L48.7109 109.975L49.0842 113.772C50.0747 113.671 51.0294 113.234 51.7257 112.437L50.1178 111.131H50.1106Z"
      :fill="fill"
    />
    <path
      d="M52.0343 112.674C51.9841 112.638 51.9338 112.602 51.8764 112.566C51.2806 112.1 50.692 111.619 50.1034 111.138L51.7113 112.444C51.015 113.241 50.0604 113.679 49.0698 113.779L49.1129 114.181C50.2111 114.066 51.2663 113.571 52.0271 112.681L52.0343 112.674Z"
      :fill="background"
    />
    <path
      d="M48.9624 109.666C50.0032 110.535 51.0655 111.411 52.1279 112.243C53.0108 112.939 54.1162 113.219 55.1641 113.083L54.676 109.3L54.5181 109.178C52.7954 107.822 50.398 108.073 48.9624 109.659V109.666Z"
      :fill="fill"
    />
    <path
      d="M51.8835 112.559C51.9338 112.595 51.984 112.631 52.0414 112.667C52.9818 113.348 54.1302 113.621 55.2213 113.485L55.171 113.083C54.123 113.212 53.0176 112.939 52.1348 112.243C51.0653 111.403 50.0101 110.528 48.9693 109.666C50.4049 108.073 52.8023 107.821 54.525 109.185L54.3671 109.063L54.4891 108.905C54.5322 108.855 54.5681 108.805 54.6111 108.762C52.7305 107.405 50.1895 107.707 48.6606 109.429L48.7181 109.982L50.1178 111.138C50.7064 111.619 51.2949 112.1 51.8907 112.566L51.8835 112.559Z"
      :fill="background"
    />
    <path
      d="M54.3604 109.056L54.5183 109.178L54.6762 109.3L54.6044 108.748C54.5613 108.798 54.5254 108.841 54.4824 108.891L54.3604 109.049V109.056Z"
      :fill="background"
    />
    <path
      d="M56.1119 110.406L54.6763 109.3L55.1644 113.083C56.1477 112.947 57.0881 112.466 57.7628 111.655L56.1119 110.406Z"
      :fill="fill"
    />
    <path
      d="M58.0928 111.891C58.0426 111.855 57.9851 111.82 57.9349 111.784L56.1189 110.406L57.7698 111.654C57.0951 112.458 56.1619 112.947 55.1714 113.083L55.2216 113.485C56.3127 113.334 57.3535 112.789 58.0928 111.884V111.891Z"
      :fill="background"
    />
    <path
      d="M54.9199 108.984L58.1715 111.461C59.0831 112.135 60.1885 112.358 61.2365 112.193L60.6192 108.432L60.4541 108.31C59.5712 107.664 58.5376 107.427 57.547 107.549C56.5565 107.664 55.609 108.152 54.9199 108.984Z"
      :fill="fill"
    />
    <path
      d="M57.9276 111.784C57.985 111.82 58.0352 111.856 58.0855 111.891C59.0545 112.552 60.203 112.76 61.294 112.595L61.2294 112.193C60.1814 112.358 59.076 112.136 58.1645 111.461L54.9129 108.984C55.6019 108.152 56.5494 107.664 57.54 107.549C58.5305 107.427 59.5641 107.656 60.447 108.31L60.2819 108.188L60.3968 108.023C60.4399 107.972 60.4757 107.922 60.5116 107.872C59.5498 107.219 58.4372 107.01 57.3821 107.161C56.3269 107.305 55.322 107.843 54.5898 108.74L54.6616 109.293L56.0972 110.398L57.9132 111.777L57.9276 111.784Z"
      :fill="background"
    />
    <path
      d="M60.2964 108.188L60.4615 108.31L60.6266 108.432L60.5333 107.879C60.4974 107.929 60.4543 107.979 60.4184 108.03L60.3036 108.195L60.2964 108.188Z"
      :fill="background"
    />
    <path
      d="M62.0911 109.487L60.6196 108.425L61.2369 112.186C62.2203 112.042 63.1463 111.497 63.7851 110.671L62.0911 109.487Z"
      :fill="fill"
    />
    <path
      d="M64.1223 110.894C64.0649 110.858 64.0147 110.829 63.9573 110.793L62.091 109.487L63.785 110.671C63.139 111.504 62.2202 112.042 61.2368 112.186L61.3014 112.588C62.3925 112.423 63.4117 111.812 64.1223 110.887V110.894Z"
      :fill="background"
    />
    <path
      d="M60.8491 108.101C61.9473 108.905 63.0527 109.673 64.1797 110.456C65.1128 111.109 66.2326 111.288 67.2734 111.073L66.5125 107.333L66.3474 107.219C64.5458 106.013 62.1699 106.386 60.8491 108.094V108.101Z"
      :fill="fill"
    />
    <path
      d="M63.9571 110.793C64.0145 110.829 64.0647 110.858 64.1222 110.894C65.1127 111.525 66.2755 111.705 67.3594 111.482L67.2805 111.08C66.2397 111.296 65.1199 111.116 64.1868 110.463C63.0598 109.681 61.9544 108.913 60.8562 108.109C62.1769 106.407 64.5528 106.034 66.3545 107.233L66.1894 107.118L66.2971 106.953C66.333 106.903 66.3689 106.853 66.4119 106.802C64.4452 105.604 61.9401 106.049 60.5332 107.879L60.6265 108.432L62.098 109.494L63.9643 110.8L63.9571 110.793Z"
      :fill="background"
    />
    <path
      d="M66.1895 107.118L66.3545 107.233L66.5196 107.348L66.412 106.802C66.3761 106.852 66.3402 106.903 66.2971 106.953L66.1895 107.118Z"
      :fill="background"
    />
    <path
      d="M68.0341 108.353L66.5195 107.341L67.2804 111.08C68.2566 110.886 69.1682 110.341 69.7783 109.472L68.0341 108.353Z"
      :fill="fill"
    />
    <path
      d="M70.116 109.674C70.0586 109.645 70.0084 109.609 69.951 109.58L68.0344 108.353L69.7787 109.473C69.1686 110.341 68.2641 110.894 67.2808 111.08L67.3597 111.482C68.4436 111.274 69.4485 110.643 70.116 109.681V109.674Z"
      :fill="background"
    />
    <path
      d="M66.7422 107.011C67.8691 107.779 69.0104 108.511 70.1661 109.243C71.1279 109.853 72.2548 110.004 73.2885 109.738L72.3697 106.034L72.1974 105.927C71.2715 105.367 70.2235 105.195 69.2473 105.388C68.2711 105.589 67.3738 106.149 66.7422 107.011Z"
      :fill="fill"
    />
    <path
      d="M69.9505 109.58C70.008 109.616 70.0654 109.645 70.1156 109.673C71.1349 110.255 72.3049 110.406 73.3816 110.126L73.2811 109.731C72.2475 109.996 71.1205 109.846 70.1587 109.236C69.003 108.511 67.8618 107.771 66.7348 107.003C67.3665 106.142 68.2709 105.582 69.2399 105.381C70.2161 105.187 71.2641 105.359 72.19 105.919L72.0178 105.812L72.1183 105.639C72.1542 105.582 72.1901 105.532 72.2259 105.481C71.221 104.929 70.1013 104.785 69.0605 105.008C68.0269 105.252 67.0722 105.855 66.3975 106.788L66.5051 107.333L68.0197 108.345L69.9362 109.573L69.9505 109.58Z"
      :fill="background"
    />
    <path
      d="M72.0322 105.819L72.2045 105.927L72.3768 106.034L72.2404 105.496C72.2045 105.546 72.1686 105.604 72.1327 105.654L72.0322 105.826V105.819Z"
      :fill="background"
    />
    <path
      d="M73.9272 106.967L72.3696 106.027L73.2884 109.731C74.2574 109.487 75.1475 108.913 75.7217 108.008C75.1188 107.671 74.523 107.326 73.9272 106.967Z"
      :fill="fill"
    />
    <path
      d="M76.0736 108.202C76.0162 108.173 75.9588 108.145 75.9013 108.116C75.241 107.743 74.5806 107.369 73.9274 106.975C74.5232 107.334 75.1189 107.678 75.7219 108.015C75.1548 108.92 74.2648 109.494 73.2886 109.738L73.3891 110.133C74.4658 109.867 75.4491 109.207 76.0736 108.209V108.202Z"
      :fill="background"
    />
    <path
      d="M72.5776 105.69C73.7333 106.4 74.9105 107.104 76.0948 107.764C77.0782 108.324 78.2267 108.425 79.2459 108.102L78.1477 104.448L77.9754 104.355C76.0733 103.314 73.762 103.902 72.5776 105.69Z"
      :fill="fill"
    />
    <path
      d="M75.901 108.116C75.9584 108.145 76.0158 108.173 76.0732 108.202C77.114 108.733 78.3056 108.834 79.3679 108.496L79.2531 108.109C78.2338 108.432 77.0853 108.324 76.102 107.771C74.9104 107.104 73.7404 106.408 72.5848 105.697C73.7691 103.91 76.0804 103.321 77.9826 104.362L77.8103 104.269L77.9036 104.089C77.9395 104.032 77.9682 103.981 77.9969 103.924C75.9369 102.912 73.5035 103.565 72.2402 105.496L72.3766 106.034L73.9342 106.975C74.5874 107.369 75.2406 107.743 75.9082 108.116H75.901Z"
      :fill="background"
    />
    <path
      d="M77.8032 104.261L77.9755 104.355L78.1478 104.448L77.9899 103.917C77.9611 103.974 77.9252 104.024 77.8965 104.082L77.8032 104.261Z"
      :fill="background"
    />
    <path
      d="M79.7486 105.309L78.1479 104.448L79.2462 108.102C80.208 107.807 81.0694 107.19 81.5934 106.257C80.9689 105.948 80.3588 105.64 79.7486 105.309Z"
      :fill="fill"
    />
    <path
      d="M81.9667 106.422C81.9093 106.393 81.8518 106.372 81.7872 106.343C81.1053 106.006 80.4306 105.668 79.7559 105.309C80.366 105.64 80.9833 105.948 81.6006 106.257C81.0766 107.19 80.2153 107.807 79.2534 108.102L79.3683 108.489C80.4378 108.166 81.3853 107.456 81.9595 106.422H81.9667Z"
      :fill="background"
    />
    <path
      d="M78.3418 104.096C79.5333 104.749 80.7392 105.388 81.9666 105.984C82.9859 106.494 84.1344 106.522 85.1465 106.135L83.8257 102.553L83.6463 102.467C82.6701 102.007 81.6149 101.957 80.6674 102.237C79.7271 102.539 78.8873 103.163 78.3418 104.089V104.096Z"
      :fill="fill"
    />
    <path
      d="M81.7869 106.35C81.8443 106.379 81.9017 106.4 81.9663 106.429C83.043 106.903 84.2345 106.924 85.2897 106.522L85.1461 106.142C84.1341 106.522 82.9856 106.494 81.9663 105.991C80.7389 105.395 79.533 104.757 78.3415 104.103C78.887 103.177 79.7268 102.553 80.6671 102.251C81.6146 101.964 82.6698 102.022 83.646 102.481L83.4665 102.395L83.5455 102.216C83.5742 102.158 83.6029 102.101 83.6316 102.043C82.5764 101.598 81.4567 101.577 80.4518 101.9C79.454 102.244 78.564 102.919 77.9897 103.924L78.1477 104.455L79.7483 105.316C80.4231 105.683 81.0978 106.013 81.7797 106.35H81.7869Z"
      :fill="background"
    />
    <path
      d="M83.4668 102.388L83.6462 102.474L83.8257 102.56L83.6319 102.036C83.6032 102.094 83.5745 102.144 83.5458 102.208L83.4668 102.388Z"
      :fill="background"
    />
    <path
      d="M85.4834 103.328L83.8325 102.56L85.1533 106.142C86.1079 105.804 86.9262 105.101 87.3928 104.139C86.7468 103.874 86.1151 103.608 85.4834 103.328Z"
      :fill="fill"
    />
    <path
      d="M87.7664 104.283C87.7018 104.261 87.6444 104.24 87.5798 104.218C86.8692 103.924 86.1729 103.63 85.4767 103.328C86.1083 103.608 86.74 103.874 87.386 104.139C86.9194 105.101 86.094 105.797 85.1465 106.142L85.29 106.522C86.3524 106.149 87.2568 105.345 87.7664 104.283Z"
      :fill="background"
    />
    <path
      d="M83.9976 102.201C85.225 102.79 86.4668 103.321 87.7444 103.852C88.8068 104.304 89.9552 104.204 90.953 103.766L89.3595 100.299L89.1728 100.227C87.1487 99.4522 84.9881 100.306 84.0047 102.208L83.9976 102.201Z"
      :fill="fill"
    />
    <path
      d="M87.5795 104.218C87.6441 104.24 87.7016 104.261 87.7662 104.283C88.8788 104.699 90.0775 104.591 91.1183 104.125L90.946 103.759C89.9554 104.204 88.7998 104.297 87.7375 103.845C86.4598 103.321 85.218 102.79 83.9906 102.194C84.974 100.292 87.1345 99.4377 89.1587 100.213L88.9721 100.141L89.0367 99.9546C89.0582 99.8971 89.0869 99.8397 89.1084 99.7823C86.9335 99.043 84.6725 99.9976 83.6245 102.029L83.8183 102.553L85.4692 103.321C86.1583 103.63 86.8618 103.917 87.5724 104.211L87.5795 104.218Z"
      :fill="background"
    />
    <path
      d="M88.9795 100.148L89.1661 100.22L89.3527 100.292L89.123 99.7896C89.1015 99.847 89.0728 99.9044 89.0513 99.9618L88.9867 100.148H88.9795Z"
      :fill="background"
    />
    <path
      d="M91.0537 100.909C90.4795 100.715 89.9196 100.515 89.3525 100.292L90.946 103.759C91.8792 103.343 92.6472 102.539 93.042 101.57C92.3673 101.354 91.7069 101.139 91.0537 100.916V100.909Z"
      :fill="fill"
    />
    <path
      d="M93.4299 101.677C93.3652 101.663 93.3006 101.656 93.2432 101.634C92.5039 101.405 91.7718 101.161 91.054 100.917C91.7072 101.139 92.3675 101.362 93.0422 101.57C92.6475 102.546 91.8794 103.343 90.9463 103.759L91.1186 104.132C92.1594 103.666 93.0064 102.768 93.4299 101.677Z"
      :fill="background"
    />
    <path
      d="M89.4888 99.9187C90.7449 100.392 92.0369 100.83 93.3649 101.232C94.4487 101.534 95.6331 101.397 96.5806 100.787L94.6138 97.5213L94.42 97.4711C93.3936 97.2127 92.3384 97.2916 91.4842 97.751C90.6085 98.1673 89.8907 98.921 89.4888 99.9187Z"
      :fill="fill"
    />
    <path
      d="M93.2429 101.627C93.3075 101.649 93.3721 101.656 93.4295 101.67C94.5708 101.929 95.7982 101.771 96.7888 101.139L96.5806 100.787C95.6403 101.39 94.456 101.527 93.3649 101.232C92.037 100.83 90.745 100.385 89.4888 99.9187C89.8908 98.921 90.6086 98.1745 91.4843 97.751C92.3385 97.2988 93.3936 97.2198 94.4201 97.4711L94.2263 97.4208L94.2693 97.227C94.2837 97.1624 94.3052 97.105 94.3196 97.0476C93.2213 96.8179 92.1088 96.9399 91.2115 97.4423C90.2856 97.9017 89.5463 98.7272 89.1084 99.7823L89.3381 100.285C89.9051 100.507 90.4722 100.708 91.0393 100.902C91.7571 101.146 92.482 101.39 93.2285 101.62L93.2429 101.627Z"
      :fill="background"
    />
    <path
      d="M94.2334 97.4279L94.4272 97.4782L94.621 97.5284L94.3339 97.0547C94.3195 97.1121 94.298 97.1767 94.2836 97.2341L94.2406 97.4279H94.2334Z"
      :fill="background"
    />
    <path
      d="M96.3867 97.959C95.7838 97.8226 95.1952 97.6791 94.6138 97.5283L96.5805 100.794C97.0256 100.522 97.4275 100.177 97.7505 99.7678C98.0664 99.3372 98.3032 98.8562 98.4396 98.3323C97.7362 98.2174 97.0543 98.1026 96.3795 97.959H96.3867Z"
      :fill="fill"
    />
    <path
      d="M98.8491 98.3897C98.7845 98.3897 98.7199 98.3753 98.6481 98.3681C97.8729 98.2461 97.1192 98.1169 96.3799 97.959C97.0546 98.1025 97.7365 98.2174 98.44 98.3322C98.3036 98.849 98.0667 99.3371 97.7509 99.7678C97.4279 100.177 97.0259 100.522 96.5809 100.794L96.789 101.146C97.2843 100.837 97.7365 100.464 98.0882 99.9903C98.4399 99.5094 98.6984 98.9711 98.8419 98.3897H98.8491Z"
      :fill="background"
    />
    <path
      d="M94.7075 97.1407C96.0211 97.4925 97.3418 97.7509 98.7343 97.9662C99.3157 98.0667 99.8756 97.9806 100.421 97.8155C100.967 97.6432 101.483 97.3776 101.914 96.9756L99.4091 94.0973L99.2153 94.0829C97.0762 93.8748 95.3176 95.1022 94.7075 97.1407Z"
      :fill="fill"
    />
    <path
      d="M98.6551 98.3682C98.7197 98.3682 98.7843 98.3825 98.8561 98.3897C99.459 98.4687 100.055 98.3682 100.622 98.1815C101.189 97.9877 101.734 97.7078 102.187 97.2843L101.921 96.9756C101.49 97.3776 100.974 97.6432 100.428 97.8155C99.8825 97.9806 99.3226 98.0667 98.7412 97.9662C97.3487 97.7509 96.028 97.4996 94.7144 97.1407C95.3245 95.1022 97.0831 93.8748 99.2222 94.0829L99.0283 94.0686V93.8676C99.0427 93.803 99.0499 93.7456 99.0499 93.681C96.796 93.5446 94.98 94.8725 94.334 97.0546L94.6211 97.5283C95.2025 97.6791 95.7911 97.8226 96.3941 97.959C97.1334 98.1169 97.8871 98.2461 98.6623 98.3682H98.6551Z"
      :fill="background"
    />
    <path
      d="M99.0215 94.0686L99.2153 94.083L99.4091 94.0973L99.043 93.6738C99.043 93.7384 99.0358 93.7959 99.0287 93.8605V94.0614L99.0215 94.0686Z"
      :fill="background"
    />
    <path
      d="M101.232 94.2051C100.608 94.1835 100.005 94.1477 99.4092 94.0974L101.914 96.9758C102.323 96.6097 102.682 96.1934 102.926 95.6981C103.17 95.1956 103.321 94.6573 103.35 94.1189C102.625 94.1692 101.914 94.1979 101.232 94.2051Z"
      :fill="fill"
    />
    <path
      d="M103.773 94.0759C103.701 94.0831 103.637 94.0903 103.572 94.1046C102.768 94.1692 101.986 94.198 101.232 94.2051C101.914 94.2051 102.624 94.1764 103.349 94.119C103.321 94.6645 103.17 95.2029 102.926 95.6981C102.682 96.1934 102.323 96.6026 101.914 96.9758L102.179 97.2845C102.632 96.8681 103.041 96.4159 103.306 95.8417C103.579 95.2818 103.737 94.6789 103.773 94.0759Z"
      :fill="background"
    />
    <path
      d="M99.4305 93.7026C100.78 93.8318 102.122 93.8103 103.558 93.6811C104.132 93.6021 104.699 93.3868 105.209 93.0638C105.718 92.7336 106.128 92.2383 106.472 91.7215L103.206 89.7476L103.012 89.7834C102.481 89.8768 102.029 90.0562 101.62 90.2787C101.189 90.4941 100.83 90.7668 100.543 91.1114C99.9043 91.7574 99.531 92.6618 99.4233 93.7026H99.4305Z"
      :fill="fill"
    />
    <path
      d="M103.572 94.1046C103.644 94.0974 103.708 94.0902 103.773 94.0759C104.376 93.9682 104.957 93.717 105.496 93.3652C106.034 93.0135 106.465 92.4824 106.831 91.9297L106.486 91.7215C106.142 92.2383 105.725 92.7336 105.223 93.0638C104.713 93.3868 104.146 93.6021 103.572 93.6811C102.136 93.8103 100.787 93.8318 99.4447 93.7026C99.5524 92.6618 99.9257 91.7502 100.564 91.1114C100.852 90.7668 101.211 90.4941 101.641 90.2787C102.05 90.0562 102.495 89.8839 103.034 89.7835L102.84 89.8193L102.79 89.6327C102.775 89.5753 102.761 89.5179 102.754 89.4604C102.194 89.5825 101.734 89.7691 101.318 90.0132C100.873 90.2428 100.507 90.5443 100.213 90.9032C99.8754 91.2478 99.617 91.6569 99.4376 92.1306C99.2438 92.5972 99.093 93.0997 99.0571 93.6739L99.4232 94.0974C100.012 94.1476 100.622 94.1835 101.246 94.2051C102 94.2051 102.775 94.1692 103.586 94.1046H103.572Z"
      :fill="background"
    />
    <path
      d="M102.825 89.8193L103.019 89.7835L103.213 89.7476L102.739 89.4604C102.754 89.5179 102.768 89.5753 102.775 89.6327L102.825 89.8193Z"
      :fill="background"
    />
    <path
      d="M104.95 89.245C104.347 89.4532 103.816 89.6398 103.22 89.7474L106.486 91.7214C107.096 90.6949 107.319 89.4029 106.917 88.3047C106.256 88.6851 105.646 89.0225 104.95 89.245Z"
      :fill="fill"
    />
    <path
      d="M107.29 88.0679C107.233 88.1109 107.175 88.1468 107.111 88.1899C106.371 88.6206 105.711 89.001 104.95 89.2451C105.646 89.0225 106.249 88.6852 106.917 88.3047C107.312 89.403 107.096 90.695 106.486 91.7214L106.838 91.9296C107.197 91.3625 107.398 90.6663 107.506 90.0274C107.592 89.3599 107.498 88.6565 107.297 88.0679H107.29Z"
      :fill="background"
    />
    <path
      d="M103.12 89.3815C103.737 89.2307 104.383 89.0298 105.051 88.7857C105.668 88.513 106.3 88.1828 106.953 87.7952C107.455 87.4578 107.864 86.9625 108.187 86.3811C108.475 85.7925 108.604 85.1034 108.647 84.4287H104.835L104.685 84.5579C103.156 85.9433 102.632 87.3286 103.127 89.3887L103.12 89.3815Z"
      :fill="fill"
    />
    <path
      d="M107.111 88.1899C107.175 88.1468 107.233 88.1109 107.29 88.0679C107.828 87.7018 108.223 87.1419 108.568 86.5246C108.876 85.893 108.998 85.1536 109.049 84.4215H108.639C108.596 85.1034 108.467 85.7925 108.18 86.3739C107.857 86.9553 107.448 87.4506 106.945 87.7879C106.292 88.1755 105.661 88.5129 105.043 88.7785C104.376 89.0297 103.73 89.2235 103.112 89.3743C102.61 87.3142 103.134 85.9288 104.67 84.5435L104.519 84.6655L104.383 84.5363L104.261 84.4143C103.837 84.7876 103.507 85.1465 103.292 85.5197C102.99 85.8858 102.847 86.259 102.718 86.661C102.459 87.4506 102.452 88.3765 102.732 89.4604L103.206 89.7475C103.802 89.6327 104.34 89.446 104.936 89.2451C105.696 89.001 106.357 88.6206 107.096 88.1899H107.111Z"
      :fill="background"
    />
    <path
      d="M104.519 84.6729L104.677 84.5508L104.828 84.4216H104.268L104.39 84.5437L104.519 84.6729Z"
      :fill="background"
    />
    <path
      d="M106.02 83.072C105.625 83.5744 105.223 84.0266 104.828 84.4214H108.64C108.604 83.7826 108.475 83.1366 108.238 82.5695C107.951 82.0168 107.57 81.5359 107.125 81.177C106.773 81.8733 106.436 82.5049 106.02 83.072Z"
      :fill="fill"
    />
    <path
      d="M107.291 80.7751C107.269 80.8397 107.247 80.9043 107.226 80.969C106.86 81.7442 106.472 82.4476 106.02 83.0721C106.436 82.505 106.774 81.8734 107.125 81.1771C107.57 81.536 107.944 82.0169 108.238 82.5696C108.475 83.1367 108.604 83.7827 108.64 84.4215H109.049C108.999 83.6894 108.877 82.9501 108.568 82.3184C108.231 81.6939 107.836 81.1412 107.291 80.7751Z"
      :fill="background"
    />
    <path
      d="M104.527 84.1632C105.467 83.2803 106.264 82.1032 106.838 80.7394C107.053 80.1867 107.175 79.5263 107.097 78.909C107.003 78.306 106.824 77.6457 106.486 77.1145L103.22 79.0884L103.17 79.2751C103.019 79.7847 102.919 80.28 102.926 80.725C102.948 81.17 102.926 81.6007 103.084 81.9883C103.228 82.3759 103.335 82.7564 103.608 83.1081C103.859 83.467 104.118 83.8187 104.52 84.1632H104.527Z"
      :fill="fill"
    />
    <path
      d="M107.226 80.9689C107.247 80.9043 107.269 80.8397 107.29 80.7751C107.491 80.1793 107.585 79.483 107.498 78.8155C107.391 78.1695 107.19 77.4732 106.831 76.9133L106.486 77.1215C106.824 77.6527 107.003 78.3058 107.096 78.916C107.175 79.5333 107.061 80.2008 106.838 80.7463C106.264 82.1102 105.46 83.2873 104.527 84.1702C104.125 83.8257 103.866 83.4668 103.615 83.1151C103.335 82.7633 103.228 82.3829 103.091 81.9953C102.94 81.6077 102.962 81.177 102.933 80.732C102.926 80.287 103.019 79.7917 103.177 79.282C103.163 79.3466 103.149 79.4041 103.127 79.4687C103.07 79.4543 102.998 79.44 102.933 79.4328C102.869 79.4184 102.811 79.4041 102.754 79.3897C102.481 80.4664 102.481 81.3995 102.739 82.1891C102.869 82.5911 103.012 82.9643 103.314 83.3304C103.536 83.7036 103.859 84.0625 104.283 84.4358H104.843C105.245 84.041 105.646 83.596 106.034 83.0863C106.486 82.4619 106.867 81.7584 107.24 80.9832L107.226 80.9689Z"
      :fill="background"
    />
    <path
      d="M103.113 79.4616C103.134 79.397 103.148 79.3396 103.163 79.275L103.213 79.0884L102.739 79.3755C102.797 79.3899 102.861 79.4042 102.919 79.4186C102.983 79.4329 103.055 79.4401 103.113 79.4545V79.4616Z"
      :fill="background"
    />
    <path
      d="M103.593 77.3153C103.486 77.9398 103.364 78.5284 103.22 79.0883L106.486 77.1144C106.163 76.6263 105.776 76.1669 105.309 75.8295C104.835 75.5137 104.311 75.2912 103.773 75.1907C103.737 75.93 103.687 76.6334 103.601 77.3153H103.593Z"
      :fill="fill"
    />
    <path
      d="M103.773 74.76C103.773 74.8246 103.773 74.8964 103.773 74.9682C103.744 75.7865 103.687 76.5617 103.586 77.3154C103.68 76.6334 103.73 75.93 103.759 75.1907C104.297 75.2912 104.821 75.5209 105.295 75.8295C105.754 76.1669 106.149 76.6263 106.472 77.1144L106.824 76.9062C106.458 76.3535 106.02 75.8223 105.489 75.4706C104.95 75.1189 104.369 74.8677 103.766 74.76H103.773Z"
      :fill="background"
    />
    <path
      d="M89.4888 68.9172L89.4242 68.7306L89.3596 68.5439L89.1299 69.0464C89.1873 69.0249 89.2519 69.0033 89.3093 68.989L89.496 68.9172H89.4888Z"
      :fill="background"
    />
    <path
      d="M87.7661 64.5603C87.7948 64.6177 87.8235 64.6751 87.8523 64.7325C88.1537 65.4288 88.448 66.1322 88.7208 66.85C88.4767 66.1968 88.2111 65.5508 87.9384 64.9192C88.9433 64.5459 90.02 64.6679 90.9531 65.0771L91.1254 64.7038C90.0846 64.2373 88.8859 64.1296 87.7733 64.5459L87.7661 64.5603Z"
      :fill="background"
    />
    <path
      d="M88.9791 68.6876C88.5197 67.4242 88.0244 66.1322 87.4789 64.8833C87.0339 63.8353 86.1653 63.0601 85.1533 62.7012L83.8325 66.283L83.9115 66.4624C84.3637 67.4458 85.1245 68.1708 86.0218 68.5655C86.9334 68.946 87.9598 69.0321 88.9791 68.6876Z"
      :fill="fill"
    />
    <path
      d="M87.8525 64.7323C87.8237 64.6749 87.795 64.6175 87.7663 64.5601C87.2567 63.4977 86.3523 62.701 85.2899 62.3206L85.1464 62.701C86.1585 63.0599 87.027 63.8351 87.472 64.8831C88.0176 66.132 88.5128 67.4169 88.9722 68.6874C87.953 69.0319 86.9265 68.9458 86.0149 68.5654C85.1177 68.1706 84.3568 67.4384 83.9046 66.4622L83.9836 66.6417L83.8041 66.7278C83.7467 66.7493 83.6893 66.778 83.6318 66.7996C84.6798 68.8381 86.9337 69.7928 89.1158 69.0535L89.3455 68.551L88.7066 66.857C88.4339 66.1392 88.1468 65.4358 87.8381 64.7395L87.8525 64.7323Z"
      :fill="background"
    />
    <path
      d="M83.9904 66.6419L83.9114 66.4624L83.8325 66.283L83.6387 66.8069C83.6961 66.7854 83.7535 66.7567 83.8109 66.7352L83.9904 66.649V66.6419Z"
      :fill="background"
    />
    <path
      d="M83.0718 64.6247L83.8326 66.2828L85.1534 62.7011C84.2059 62.3422 83.1292 62.3422 82.1602 62.7657L83.0789 64.6247H83.0718Z"
      :fill="fill"
    />
    <path
      d="M81.9595 62.4141C81.9882 62.4715 82.0241 62.5217 82.0528 62.5792L83.0649 64.6249L82.1461 62.7658C83.1223 62.3351 84.1918 62.3351 85.1393 62.7012L85.2829 62.3207C84.2277 61.9188 83.029 61.9403 81.9595 62.4141Z"
      :fill="background"
    />
    <path
      d="M83.4665 66.4479C82.9138 65.1989 82.318 63.9931 81.6935 62.7656C81.1839 61.7392 80.2723 61.0501 79.253 60.7415L78.1548 64.395L78.2481 64.5745C79.2674 66.491 81.4925 67.2734 83.4665 66.4551V66.4479Z"
      :fill="fill"
    />
    <path
      d="M82.0599 62.5863C82.024 62.5289 81.9953 62.4715 81.9666 62.4212C81.3924 61.3876 80.4449 60.677 79.3754 60.354L79.2605 60.7416C80.287 61.0503 81.1914 61.7393 81.701 62.7658C82.3183 63.9932 82.9213 65.2063 83.474 66.4481C81.5 67.2663 79.2677 66.4911 78.2556 64.5674L78.3489 64.7469L78.1767 64.8402C78.1192 64.8689 78.0618 64.8976 78.0044 64.9263C78.5786 65.9313 79.4759 66.5988 80.4664 66.9505C81.4713 67.2735 82.5911 67.252 83.6462 66.807L83.84 66.283L83.0792 64.6249L82.0671 62.5792L82.0599 62.5863Z"
      :fill="background"
    />
    <path
      d="M78.3343 64.7468L78.241 64.5674L78.1477 64.3879L77.9897 64.9191C78.0472 64.8904 78.1046 64.8617 78.162 64.833L78.3343 64.7397V64.7468Z"
      :fill="background"
    />
    <path
      d="M77.2937 62.7873L78.1479 64.3951L79.2461 60.7416C78.2842 60.4401 77.2147 60.5119 76.2744 60.9856L77.2937 62.7873Z"
      :fill="fill"
    />
    <path
      d="M76.0732 60.6409C76.1091 60.6984 76.145 60.7486 76.1809 60.806L77.3007 62.7871L76.2814 60.9855C77.2289 60.5117 78.2912 60.4399 79.2531 60.7414L79.3679 60.3538C78.2984 60.0164 77.114 60.1169 76.0732 60.6481V60.6409Z"
      :fill="background"
    />
    <path
      d="M77.7961 64.5746C77.1717 63.3615 76.5041 62.1987 75.8222 61C75.2552 60.0022 74.322 59.3634 73.2884 59.105L72.3696 62.8088L72.4701 62.9811C73.5827 64.8473 75.8653 65.5149 77.7961 64.5746Z"
      :fill="fill"
    />
    <path
      d="M76.1739 60.799C76.138 60.7416 76.1022 60.6913 76.0663 60.6339C75.4418 59.629 74.4584 58.9758 73.3817 58.7102L73.2812 59.105C74.3148 59.3634 75.2552 60.0022 75.815 61C76.4969 62.1987 77.1645 63.3615 77.789 64.5746C75.8581 65.5149 73.5755 64.8473 72.4629 62.9811L72.5634 63.1533L72.3912 63.261C72.3337 63.2897 72.2835 63.3184 72.2261 63.3543C73.4894 65.2852 75.9155 65.9384 77.9828 64.9263L78.1407 64.3951L77.2865 62.7873L76.1668 60.8062L76.1739 60.799Z"
      :fill="background"
    />
    <path
      d="M72.5776 63.1534L72.4771 62.9811L72.3766 62.8088L72.2402 63.3472C72.2977 63.3185 72.3479 63.2898 72.4053 63.2539L72.5776 63.1462V63.1534Z"
      :fill="background"
    />
    <path
      d="M71.4366 61.2513L72.3697 62.816L73.2885 59.1122C72.3195 58.861 71.2572 58.9759 70.3384 59.4998L71.4366 61.2513Z"
      :fill="fill"
    />
    <path
      d="M70.1157 59.1624C70.1516 59.2127 70.1875 59.2629 70.2306 59.3203L71.4365 61.244L70.3382 59.4926C71.257 58.9686 72.3193 58.8538 73.2884 59.105L73.3889 58.7102C72.3122 58.4303 71.135 58.581 70.1229 59.1624H70.1157Z"
      :fill="background"
    />
    <path
      d="M72.025 63.017C71.3359 61.8399 70.6181 60.6986 69.886 59.5357C69.2759 58.5739 68.3212 57.9566 67.2804 57.7556L66.5195 61.4953L66.6272 61.6604C67.223 62.5648 68.1059 63.1606 69.0677 63.4046C70.0295 63.6415 71.0847 63.5195 72.025 63.0099V63.017Z"
      :fill="fill"
    />
    <path
      d="M70.2306 59.3204C70.1947 59.2702 70.1588 59.2127 70.1158 59.1625C69.4482 58.1935 68.4433 57.569 67.3594 57.3608L67.2805 57.7628C68.3213 57.9638 69.2831 58.5739 69.8861 59.5429C70.6182 60.6986 71.336 61.8399 72.0251 63.0242C71.0848 63.5339 70.0296 63.6631 69.0678 63.419C68.106 63.1678 67.2231 62.5792 66.6273 61.6748L66.735 61.8399L66.5699 61.9547C66.5125 61.9906 66.4622 62.0193 66.4048 62.0552C67.0795 62.9883 68.0342 63.5913 69.0678 63.8353C70.1086 64.065 71.2284 63.9215 72.2333 63.3616L72.3696 62.8232L71.4365 61.2585L70.2306 59.3348V59.3204Z"
      :fill="background"
    />
    <path
      d="M66.7346 61.8326L66.627 61.6675L66.5193 61.5024L66.4116 62.048C66.469 62.0121 66.5193 61.9834 66.5767 61.9475L66.7418 61.8326H66.7346Z"
      :fill="background"
    />
    <path
      d="M65.5217 59.9807L66.5194 61.4952L67.2803 57.7555C66.3041 57.5545 65.2561 57.6981 64.3589 58.2651C64.7537 58.8322 65.1413 59.3992 65.5217 59.9735V59.9807Z"
      :fill="fill"
    />
    <path
      d="M64.1221 57.9492C64.158 57.9995 64.201 58.0497 64.2369 58.1C64.6676 58.7244 65.0983 59.3417 65.5218 59.9806C65.1413 59.3992 64.7465 58.8393 64.3589 58.2722C65.2562 57.7052 66.3042 57.5688 67.2804 57.7626L67.3593 57.3606C66.2754 57.1381 65.1126 57.3176 64.1221 57.9492Z"
      :fill="background"
    />
    <path
      d="M66.1824 61.7178C65.443 60.5765 64.6822 59.4424 63.8998 58.3298C63.2538 57.4038 62.2776 56.8081 61.2296 56.6501L60.6123 60.4114L60.7272 60.5765C61.9833 62.3638 64.3448 62.8232 66.1824 61.7178Z"
      :fill="fill"
    />
    <path
      d="M64.2369 58.1C64.1938 58.0497 64.1579 57.9995 64.122 57.9492C63.4114 57.0233 62.3993 56.4131 61.3011 56.248L61.2365 56.65C62.2845 56.8079 63.2607 57.4037 63.9067 58.3296C64.6891 59.4422 65.4499 60.5763 66.1893 61.7176C64.3517 62.823 61.9974 62.3636 60.734 60.5763L60.8489 60.7414L60.6838 60.8635C60.6264 60.8993 60.5761 60.9352 60.5259 60.9711C61.9399 62.8015 64.445 63.2537 66.4046 62.0478L66.5123 61.5023L65.5145 59.9877C65.091 59.3489 64.6675 58.7316 64.2297 58.1071L64.2369 58.1Z"
      :fill="background"
    />
    <path
      d="M60.8489 60.7416L60.734 60.5765L60.6192 60.4114L60.5259 60.9641C60.5761 60.9282 60.6335 60.8923 60.6838 60.8564L60.8489 60.7344V60.7416Z"
      :fill="background"
    />
    <path
      d="M59.5643 58.9399L60.6195 60.4114L61.2368 56.6501C60.2534 56.4922 59.2126 56.6788 58.3369 57.2603L59.5572 58.9399H59.5643Z"
      :fill="fill"
    />
    <path
      d="M58.0928 56.9516C58.1358 57.0018 58.1717 57.0449 58.2148 57.1023L59.5642 58.947L58.344 57.2674C59.2197 56.686 60.2605 56.4993 61.2439 56.6573L61.3085 56.2553C60.2174 56.083 59.069 56.2984 58.1 56.9587L58.0928 56.9516Z"
      :fill="background"
    />
    <path
      d="M60.2894 60.6483C59.507 59.5501 58.7031 58.4303 57.8848 57.3393C57.2101 56.4348 56.2123 55.8965 55.1644 55.7529L54.6763 59.5357L54.7983 59.6936C56.119 61.4378 58.4949 61.8183 60.2894 60.6411V60.6483Z"
      :fill="fill"
    />
    <path
      d="M58.2146 57.0952C58.1716 57.0449 58.1357 56.9947 58.0926 56.9444C57.3533 56.0472 56.3125 55.4945 55.2215 55.3438L55.1712 55.7457C56.2192 55.8893 57.2169 56.4276 57.8916 57.332C58.7099 58.4231 59.5067 59.5428 60.2962 60.6411C58.5018 61.8182 56.1187 61.4378 54.8051 59.6936L54.9272 59.8515L54.7692 59.9735C54.719 60.0094 54.6688 60.0453 54.6113 60.0812C55.3435 60.9784 56.3484 61.5168 57.4035 61.6675C58.4587 61.8182 59.5713 61.6101 60.5331 60.9569L60.6264 60.4042L59.5713 58.9327L58.2218 57.088L58.2146 57.0952Z"
      :fill="background"
    />
    <path
      d="M54.9198 59.8586L54.7978 59.7006L54.6758 59.5427L54.604 60.0954C54.6542 60.0595 54.7045 60.0236 54.7619 59.9878L54.9198 59.8657V59.8586Z"
      :fill="background"
    />
    <path
      d="M53.5706 58.1073L54.676 59.5429L55.1641 55.7601C54.1808 55.6381 53.1471 55.8678 52.293 56.4779L53.5706 58.1073Z"
      :fill="fill"
    />
    <path
      d="M52.0347 56.1692C52.0777 56.2194 52.1208 56.2625 52.1639 56.3127L53.5707 58.1072L52.2931 56.4778C53.1472 55.8677 54.1809 55.6308 55.1642 55.76L55.2145 55.3581C54.1163 55.2217 52.975 55.4945 52.0347 56.1764V56.1692Z"
      :fill="background"
    />
    <path
      d="M54.3603 59.7798L51.848 56.5641C51.1446 55.6668 50.1325 55.1787 49.0774 55.071L48.7041 58.8682L48.8333 59.0261C50.2043 60.7057 52.5945 61.0431 54.3603 59.7869V59.7798Z"
      :fill="fill"
    />
    <path
      d="M52.1635 56.3127C52.1204 56.2624 52.0773 56.2194 52.0343 56.1691C51.2662 55.2719 50.2111 54.7838 49.12 54.6689L49.077 55.0709C50.1249 55.1786 51.1442 55.6667 51.8476 56.5639L54.3599 59.7796C52.6013 61.0358 50.2111 60.7056 48.8329 59.0188L48.9621 59.1767L48.8114 59.3059C48.7611 59.3418 48.7109 59.3848 48.6606 59.4207C50.1895 61.1434 52.7305 61.4449 54.6111 60.0883L54.6829 59.5356L53.5775 58.1L52.1707 56.3055L52.1635 56.3127Z"
      :fill="background"
    />
    <path
      d="M48.9623 59.1767L48.8331 59.0188L48.7039 58.8608L48.6465 59.4135C48.6967 59.3777 48.747 59.3418 48.7972 59.2987L48.948 59.1695L48.9623 59.1767Z"
      :fill="background"
    />
    <path
      d="M47.5628 57.454L48.7112 58.8681L49.0845 55.071C48.0939 54.9705 47.0747 55.2217 46.2349 55.8749L47.5628 57.4612V57.454Z"
      :fill="fill"
    />
    <path
      d="M45.9692 55.5734C46.0123 55.6237 46.0554 55.6667 46.0984 55.717L47.5627 57.4612L46.2348 55.8749C47.0746 55.2289 48.0939 54.9705 49.0845 55.071L49.1275 54.669C48.0293 54.5542 46.8952 54.8556 45.9764 55.5806L45.9692 55.5734Z"
      :fill="background"
    />
    <path
      d="M46.0983 55.7169C46.0553 55.6666 46.0122 55.6236 45.9691 55.5733C45.1796 54.7263 44.11 54.2167 43.0118 54.1521L42.9831 54.5612C44.0383 54.6187 45.0575 55.1211 45.7897 55.9753C46.6726 57.0161 47.5411 58.0569 48.3953 59.1192C46.6797 60.4041 44.2751 60.1816 42.8539 58.5163L42.9831 58.667L42.8324 58.8034C42.7821 58.8465 42.7319 58.8824 42.6816 58.9254C44.2608 60.6194 46.8161 60.8132 48.6537 59.4207L48.7111 58.868L47.5626 57.4539L46.0983 55.7097V55.7169Z"
      :fill="background"
    />
    <path
      d="M42.9833 58.6672L42.8541 58.5165L42.7249 58.3657L42.689 58.9184C42.7392 58.8754 42.7895 58.8395 42.8397 58.7964L42.9904 58.66L42.9833 58.6672Z"
      :fill="background"
    />
    <path
      d="M45.7896 55.9754C45.0575 55.1213 44.031 54.6188 42.983 54.5614C41.9925 54.4968 40.9804 54.7839 40.1621 55.4443L41.5331 57.0019L42.7246 58.3657L42.8538 58.5164C44.2751 60.1745 46.6797 60.4042 48.3952 59.1194C47.541 58.057 46.6725 57.0162 45.7896 55.9754Z"
      :fill="fill"
    />
    <path
      d="M39.8896 55.15C39.9327 55.1931 39.9758 55.2433 40.026 55.2864L41.5334 56.9947L40.1624 55.4371C40.9807 54.7768 41.9928 54.4896 42.9833 54.5542L43.012 54.1451C41.9138 54.0733 40.7941 54.4035 39.8896 55.1428V55.15Z"
      :fill="background"
    />
    <path
      d="M42.4161 58.6313L39.7172 55.5591C38.9635 54.7265 37.9227 54.2814 36.8675 54.2312L36.7168 58.0427L36.8532 58.1934C37.5853 59.0045 38.5615 59.4496 39.5521 59.5142C40.5498 59.6003 41.5691 59.3132 42.4089 58.6313H42.4161Z"
      :fill="fill"
    />
    <path
      d="M40.026 55.2934C39.9829 55.2432 39.9327 55.2001 39.8896 55.157C39.0713 54.3316 37.9946 53.8793 36.8964 53.8291L36.882 54.2382C37.9372 54.2885 38.9708 54.7263 39.7317 55.5662L42.4306 58.6383C41.5908 59.3202 40.5643 59.6073 39.5738 59.5212C38.576 59.4566 37.607 59.0116 36.8749 58.2005L37.0112 58.3512L36.8677 58.4876C36.8174 58.5306 36.7744 58.5665 36.7241 58.6096C37.5352 59.4351 38.5832 59.8801 39.6527 59.9303C40.7222 59.9949 41.8061 59.6719 42.7033 58.9326L42.7392 58.3799L41.5477 57.0161L40.0403 55.3078L40.026 55.2934Z"
      :fill="background"
    />
    <path
      d="M36.9896 58.3442L36.8532 58.1935L36.7168 58.0427L36.6953 58.6026C36.7456 58.5595 36.7886 58.5236 36.8389 58.4806L36.9824 58.3442H36.9896Z"
      :fill="background"
    />
    <path
      d="M39.7961 61.4878L39.8248 60.1168C38.1524 60.0881 36.7958 58.6884 36.8029 56.9657C36.8029 55.2502 38.2242 53.8505 39.9469 53.8864L39.9756 52.5154C37.4705 52.4795 35.4391 54.4821 35.4463 56.9513C35.4535 59.4277 37.4059 61.4447 39.7961 61.4806V61.4878Z"
      :fill="fill"
    />
    <path
      d="M36.9465 111.834C36.9465 110.111 38.2959 108.712 39.9684 108.683L39.9397 107.312C37.5494 107.348 35.597 109.365 35.5899 111.841C35.5827 114.31 37.614 116.32 40.1191 116.277L40.0904 114.906C38.3605 114.935 36.9465 113.542 36.9465 111.827V111.834Z"
      :fill="fill"
    />
    <path
      d="M151.118 111.116C151.118 112.832 149.697 114.231 147.975 114.196L147.946 115.567C150.451 115.602 152.482 113.6 152.475 111.131C152.468 108.654 150.515 106.637 148.125 106.601L148.097 107.972C149.769 108.001 151.126 109.401 151.118 111.123V111.116Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "Drawcord",
  props: {
    width: {
      type: String,
      default: "188",
    },
    height: {
      type: String,
      default: "168",
    },
    fill: {
      type: String,
      default: "#442D64",
    },
    background: {
      type: String,
      default: "#E9E7ED",
    },
    viewBox: {
      type: String,
      default: "0 0 188 168",
    },
  },
};
</script>
