export default Object.freeze({
  LOGIN: "Login",
  INQUIRY: "Inquiry",
  COLLECTION: "Collection",
  COLLECTION_LIST: "All Collections",
  MANAGEMENT_DASHBOARD: "Collection Management Dashboard",
  FACTORY_PROFILE: "Factory Profile",
  GLOBAL_ACCOUNT_LIST: "Global Account List",
  COMPANY_PROFILE: "Company Profile",
  ACCOUNT_PROFILE: "Account Profile",
  TRACE: "Trace",
  FACTORY_REQUESTS: "Factory Requests",
  ADD_VALUE_CHAIN: "Add a Value Chain",
  PUBLIC_BOM: "Public Bom",
  PUBLIC_STYLE_FILE: "Public Style File",
  USERS: "Users",
});
