<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M28.816 3.94362H16.5612C15.7617 3.94487 15.1135 4.593 15.1135 5.39254V14.4614C15.1135 15.261 15.7617 15.9091 16.5612 15.9091L18.4643 15.864L18.861 18.2477C18.9473 18.7632 19.3039 19.1923 19.7957 19.3713C20.2862 19.549 20.8355 19.4489 21.2321 19.1098L24.9459 15.9103H28.8147L28.816 15.9091C29.6155 15.9091 30.2624 15.2609 30.2636 14.4614V5.39253C30.2624 4.59299 29.6155 3.94486 28.816 3.94362ZM28.7621 14.4078H24.9459C24.6005 14.4078 24.2664 14.5317 24.0037 14.7569L20.3424 18.0014L19.9458 15.6178V15.619C19.8307 14.9196 19.2251 14.4065 18.5169 14.4078L16.615 14.4616L16.5612 5.44625L28.7621 5.39245L28.7621 14.4078Z" :fill="fill"/>
    <path d="M13.1892 29.5003C14.8783 29.4991 16.2485 28.129 16.2497 26.4398V25.9005C16.2547 23.5694 15.051 21.4022 13.0703 20.1735C13.8773 19.2526 14.2977 18.0551 14.2427 16.8313C14.1889 15.6076 13.6646 14.4527 12.78 13.6057C11.8954 12.7598 10.718 12.2869 9.4929 12.2869C8.26784 12.2869 7.09051 12.7598 6.20579 13.6057C5.32117 14.4528 4.7969 15.6077 4.74309 16.8313C4.68803 18.055 5.10844 19.2524 5.91552 20.1735C3.9348 21.4022 2.7311 23.5695 2.7361 25.9005V26.4398C2.73735 28.1289 4.10745 29.4991 5.79665 29.5003H13.1892ZM4.23785 26.4398V25.9005C4.2366 24.8569 4.54691 23.8359 5.13 22.9688C5.71433 22.103 6.54267 21.431 7.51112 21.0394C7.76763 20.9356 7.94531 20.6991 7.97408 20.425C8.00411 20.151 7.88149 19.882 7.65376 19.7256C6.47758 18.9198 5.96334 17.4421 6.38501 16.0794C6.80793 14.7181 8.06668 13.7896 9.49309 13.7896C10.9195 13.7896 12.1783 14.7181 12.6012 16.0794C13.0228 17.442 12.5086 18.9197 11.3324 19.7256C11.1047 19.882 10.9821 20.151 11.0121 20.425C11.0409 20.6991 11.2186 20.9356 11.4751 21.0394C12.4435 21.4311 13.2719 22.103 13.8562 22.9688C14.4393 23.8359 14.7496 24.8569 14.7483 25.9005V26.4398C14.7471 27.3006 14.0501 27.9988 13.1893 27.9988H5.79697C4.93611 27.9988 4.2391 27.3006 4.23785 26.4398Z" :fill="fill"/>
    <path d="M20.8393 10.3839C20.9406 10.4665 20.9832 10.6017 20.9456 10.7268L20.3888 12.5824C20.3488 12.715 20.3988 12.8589 20.5127 12.9378C20.6266 13.0166 20.778 13.0141 20.8881 12.9315L22.3132 11.8629C22.4271 11.7766 22.5848 11.7766 22.6986 11.8629L24.1238 12.9315C24.2339 13.0141 24.3865 13.0166 24.4992 12.9378C24.613 12.8589 24.6631 12.715 24.623 12.5824L24.0662 10.7268C24.0287 10.6017 24.0712 10.4665 24.1738 10.3839L25.3888 9.41172L25.3876 9.41297C25.4939 9.32789 25.5352 9.18399 25.4902 9.05636C25.4451 8.92748 25.3237 8.84114 25.1874 8.84114H23.796C23.6683 8.84114 23.552 8.76482 23.5019 8.6472L22.8012 7.01181C22.7499 6.8942 22.6348 6.81787 22.5059 6.81787C22.3783 6.81787 22.2619 6.8942 22.2106 7.01181L21.5099 8.6472C21.4599 8.76482 21.3435 8.84114 21.2159 8.84114H19.8245C19.6881 8.84114 19.5667 8.92748 19.5217 9.05636C19.4766 9.18399 19.5179 9.32788 19.6243 9.41297L20.8393 10.3839Z" :fill="fill"/>  
  </svg>
</template>

<script>
export default {
  name: "CustomerFeedbackIcon",
  props: {
    width: {
      type: [Number, String],
      default: 33
    },
    height: {
      type: [Number, String],
      default: 33    
    },
    fill: {
      type: String,
      default: '#19BEBB'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 33 33'    
    },
  }
}
</script>
