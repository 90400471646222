export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const isValidHex = (hex) => {
  return /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);
};

export const getChunksFromString = (str, chunkSize) => {
  return str.match(new RegExp(`.{${chunkSize}}`, "g"));
};

export const convertHexUnitTo256 = (hex) => {
  return parseInt(hex.repeat(2 / hex.length), 16);
};

export const getAlphaFloat = (a, alpha) => {
  if (typeof a !== "undefined") {
    return a / 255;
  }
  if (typeof alpha != "number" || alpha < 0 || alpha > 1) {
    return 1;
  }
  return alpha;
};

export const hexToRGBA = (hex, alpha) => {
  if (!isValidHex(hex)) {
    return;
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);
  const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
  return {
    r,
    g,
    b,
    a: getAlphaFloat(a, alpha),
  };
};

export const isLightColor = ({ r, g, b, a }) => {
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  const opacity = a !== undefined ? a : 1;
  return brightness * opacity > 155;
};

export const increaseBrightness = (color, factor) => {
  const { r, g, b, a } = color;
  const newColor = {
    r: Math.min(Math.abs(r + factor), 255),
    g: Math.min(Math.abs(g + factor), 255),
    b: Math.min(Math.abs(b + factor), 255),
    a,
  };
  return newColor;
};
