<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.0881 11.3896C15.7415 11.3896 15.3946 11.6671 15.3946 12.0832V30.3903C15.3946 31.2918 14.7011 31.9851 13.7997 31.9851H2.98192C2.08035 31.9851 1.38706 31.2916 1.38706 30.3903V12.0832C1.38706 11.7365 1.1096 11.3896 0.69353 11.3896C0.27746 11.3896 0 11.6671 0 12.0832V30.3903C0 32.0546 1.31764 33.3722 2.98192 33.3722H13.7997C15.464 33.3722 16.7817 32.0546 16.7817 30.3903V12.0832C16.7817 11.6671 16.5042 11.3896 16.0881 11.3896Z" :fill="fill"/>
    <path d="M9.22287 21.999C9.22287 22.6119 8.72623 23.1086 8.11327 23.1086C7.50052 23.1086 7.00391 22.612 7.00391 21.999C7.00391 21.3863 7.50055 20.8896 8.11327 20.8896C8.7262 20.8896 9.22287 21.3863 9.22287 21.999Z" :fill="fill"/>
    <path d="M14.2859 24.1487H13.1071C13.1071 23.5246 12.6216 23.0391 11.9975 23.0391C11.3734 23.0391 10.9574 23.5246 10.9574 24.1487H2.56641V30.3202H14.2859V24.1487ZM6.31104 27.1998C5.68693 27.1998 5.20144 26.7143 5.20144 26.0902C5.20144 25.4661 5.68693 24.9806 6.31104 24.9806C6.93515 24.9806 7.42064 25.4661 7.42064 26.0902C7.35122 26.7145 6.86573 27.1998 6.31104 27.1998ZM9.50099 29.3495C8.87689 29.3495 8.39139 28.864 8.39139 28.2399C8.39139 27.6158 8.87689 27.1303 9.50099 27.1303C10.1251 27.1303 10.6106 27.6158 10.6106 28.2399C10.5412 28.9335 10.0557 29.3495 9.50099 29.3495Z" :fill="fill"/>
    <path d="M12.0666 14.7873C12.0666 15.4001 11.57 15.8969 10.9573 15.8969C10.3443 15.8969 9.84766 15.4001 9.84766 14.7873C9.84766 14.1746 10.3443 13.6777 10.9573 13.6777C11.57 13.6777 12.0666 14.1746 12.0666 14.7873Z" :fill="fill"/>
    <path d="M12.0666 18.8108C12.0666 19.4235 11.57 19.9201 10.9573 19.9201C10.3443 19.9201 9.84766 19.4235 9.84766 18.8108C9.84766 18.1978 10.3443 17.7012 10.9573 17.7012C11.57 17.7012 12.0666 18.1978 12.0666 18.8108Z" :fill="fill"/>
    <path d="M31.8979 12.3611L21.8427 2.30586L22.0507 2.09783C22.5362 1.61233 22.5362 0.849616 22.0507 0.364121C21.5652 -0.121374 20.8025 -0.121374 20.317 0.364121L14.7001 5.98107C14.2146 6.46657 14.2146 7.22928 14.7001 7.71478C15.1856 8.20027 15.9483 8.20027 16.4338 7.71478L16.6418 7.50674L26.697 17.562C28.1533 19.0182 30.5111 19.0182 31.9673 17.562C33.3542 16.1749 33.3542 13.8173 31.8979 12.3611ZM23.1604 12.2917L17.5435 6.67472L21.0107 3.20755L26.6276 8.8245L23.1604 12.2917Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "DyeingIcon",
  props: {
    width: {
      type: [Number, String],
      default: 33
    },
    height: {
      type: [Number, String],
      default: 34      
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '-3 3 30 30'    
    },
  }
}
</script>

