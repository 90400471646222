<template>
  <span>
    <ellipse-icon
      :width="ellipseSize ? `${ellipseSize}px` : '59px'"
      :height="ellipseSize ? `${ellipseSize}px` : '59px'"
      border="2px"
      :variant="variant"
      :fill="background"
      v-slot="props"
    >
      <design-icon
        v-if="traceName == 'DesignIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <raw-material-icon
        v-else-if="traceName == 'RawMaterialIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <spinning-icon
        v-else-if="traceName == 'SpinningIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <weaving-icon
        v-else-if="traceName == 'WeavingIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <dyeing-icon
        v-else-if="traceName == 'DyeingIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <sampling-icon
        v-else-if="traceName == 'SamplingIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <trims-icon
        v-else-if="traceName == 'TrimsIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <manufacture-icon
        v-else-if="traceName == 'ManufacturingIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <delivery-icon
        v-else-if="traceName == 'DeliveryIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <repairs-icon
        v-else-if="traceName == 'RepairsIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <resales-icon
        v-else-if="traceName == 'ResalesIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <recycles-icon
        v-else-if="traceName == 'RecyclesIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <upcycles-icon
        v-else-if="traceName == 'UpcyclesIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
      <donations-icon
        v-else-if="traceName == 'DonationsIcon'"
        :fill="props.fill"
        :height="iconSize"
        :width="iconSize"
      />
    </ellipse-icon>
  </span>
</template>

<script>
import { size } from "vee-validate/dist/rules";

export default {
  name: "TraceServicesIcon",
  props: {
    traceName: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: "primary",
    },
    background: {
      type: String,
      default: "white",
    },
    ellipseSize: {
      type: Number,
      default: undefined,
    },
    iconSize: {
      type: Number,
      default: undefined,
    },
  },
};
</script>

<style scoped></style>
