<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.25" y="0.25" width="35.5" height="35.5" rx="17.75" fill="#EEF1FA"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7608 14.2339C19.7608 15.1744 18.9983 15.9369 18.0578 15.9369C17.1173 15.9369 16.3548 15.1744 16.3548 14.2339C16.3548 13.2934 17.1173 12.5311 18.0578 12.5311C18.9983 12.5311 19.7608 13.2934 19.7608 14.2339ZM21.4636 14.2339C21.4636 16.115 19.9388 17.6399 18.0578 17.6399C16.1768 17.6399 14.652 16.115 14.652 14.2339C14.652 12.3529 16.1768 10.8281 18.0578 10.8281C19.9388 10.8281 21.4636 12.3529 21.4636 14.2339ZM13.3771 23.6002C13.4461 21.7075 15.0021 20.1944 16.9116 20.1944H19.204C21.1135 20.1944 22.6696 21.7075 22.7385 23.6002H13.3771ZM11.6719 23.7312C11.6719 20.8374 14.0179 18.4914 16.9117 18.4914H19.2041C22.0979 18.4914 24.4439 20.8374 24.4439 23.7312C24.4439 24.5993 23.74 25.3031 22.8719 25.3031H13.244C12.3758 25.3031 11.672 24.5993 11.672 23.7312H11.6719Z"
      :fill="fill"
    />
    <rect x="0.25" y="0.25" width="35.5" height="35.5" rx="17.75" :stroke="fill" stroke-width="0.5"/>
  </svg>
</template>

<script>
export default {
  name: "AccountIcon",
  props: {
    width: {
      type: [Number, String],
      default: 36
    },
    height: {
      type: [Number, String],
      default: 36     
    },
    fill: {
      type: String,
      default: '#442D65'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 36 36'    
    },
  }
}
</script>

