<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5809 0.983317C14.8992 0.983317 15.2213 1.10579 15.4621 1.34658C15.4621 1.34623 15.4625 1.34693 15.4628 1.34727L15.4635 1.34797L19.1649 5.08816C19.1676 5.09093 19.1702 5.09319 19.1727 5.09596C19.639 5.59105 19.6449 6.38142 19.1633 6.86336L6.24657 19.7801C6.0122 20.0145 5.68935 20.1434 5.36756 20.1434L1.62963 20.1624H1.62842H1.6272C1.30592 20.1624 0.982899 20.033 0.748545 19.7992C0.501341 19.552 0.384762 19.2229 0.384762 18.9011V18.8999V18.8986L0.403845 15.1413C0.403845 14.7919 0.559629 14.4989 0.747334 14.2849C0.754272 14.2767 0.760862 14.2692 0.767975 14.2623L11.1005 3.9489L13.683 1.3469C13.6865 1.34343 13.69 1.33996 13.6934 1.33684L13.6938 1.33649C13.9413 1.10334 14.2616 0.982422 14.5794 0.982422L14.5809 0.983317ZM14.5835 4.0022L13.7577 4.82796L15.7208 6.79103L16.5465 5.96526L14.5835 4.0022ZM11.9808 6.60504L2.90778 15.6781L2.89304 17.637L4.87138 17.6219L13.944 8.56793L11.9808 6.60504ZM22.7383 21.689C23.4308 21.689 24 22.2466 24 22.9313C24 23.602 23.4501 24.1736 22.7577 24.1736H1.64728C0.962921 24.1736 0.404507 23.616 0.404507 22.9313C0.404507 22.2606 0.954794 21.689 1.64728 21.689H22.7383Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "EditInquiry",
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 25
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 24 25'    
    },
  }
}
</script>