<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 13.9984C0 6.27964 6.27964 0 13.9984 0C17.8063 0 21.3851 1.56256 23.9973 4.23755V2.99954C23.9973 2.44738 24.4446 1.99959 24.9972 1.99959C25.5499 1.99959 25.9972 2.44713 25.9972 2.99954V6.99903C25.9972 7.55118 25.5499 7.99897 24.9972 7.99897H20.9977C20.4451 7.99897 19.9978 7.55143 19.9978 6.99903C19.9978 6.44687 20.4451 5.99908 20.9977 5.99908H22.9316C20.6694 3.47425 17.4374 1.99959 13.9986 1.99959C7.38214 1.99959 2.00001 7.38238 2.00001 13.9982C2.00001 20.6147 7.38214 25.9969 13.9986 25.9969C20.6152 25.9969 25.9973 20.6147 25.9973 13.9982C25.9973 13.4461 26.4446 12.9983 26.9972 12.9983C27.5499 12.9983 27.9972 13.4458 27.9972 13.9982C27.9972 21.717 21.7175 27.9966 13.9988 27.9966C6.27941 27.9966 0.000356906 21.717 0.000356906 13.9982L0 13.9984Z"
      :fill="fill"
    />
    <path
      d="M13.9979 4C13.4453 4 12.9979 4.44754 12.9979 4.99995V11.1838C11.8366 11.5978 10.998 12.6973 10.998 13.9992C10.998 15.6534 12.3434 16.9988 13.9976 16.9988C15.2995 16.9988 16.399 16.1602 16.813 14.9989H20.9972C21.5498 14.9989 21.9971 14.5513 21.9971 13.9989C21.9971 13.4468 21.5498 12.999 20.9972 12.999H16.8133C16.5113 12.1518 15.8448 11.4855 14.9978 11.1835V5.00024C14.9978 4.44785 14.5505 4.0003 13.9978 4.0003L13.9979 4ZM13.9979 14.9987C13.4462 14.9987 12.9979 14.55 12.9979 13.9988C12.9979 13.4476 13.4462 12.9989 13.9979 12.9989C14.5496 12.9989 14.9978 13.4476 14.9978 13.9988C14.9978 14.55 14.5496 14.9987 13.9979 14.9987Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "UpdateStatus",
  props: {
    width: {
      type: [Number, String],
      default: 28
    },
    height: {
      type: [Number, String],
      default: 28
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 28 28'    
    },
  }
}
</script>