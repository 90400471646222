<template>
  <svg
    :width="width"
    :height="width"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M122.397 107.593C111.297 114.993 97.8582 129.63 92.7966 152M70.5966 137.2H33.752C16.8356 137.2 8.37744 137.2 5.11404 131.761C1.85805 126.329 5.94284 119.018 14.0902 104.396L24.6574 85.437C30.1334 75.632 32.8566 70.7184 37.1412 70.6C41.4258 70.4816 44.4302 75.2324 50.4464 84.734L55.6412 92.9258M48.3966 48.6516L55.7374 34.7471C66.5414 14.2713 71.9508 4.02966 80.1574 4.00006C88.3714 3.97046 93.8474 14.1677 104.785 34.5694L106.598 37.9438C113.383 50.5978 116.78 56.9322 114.057 60.869C111.334 64.8058 104.659 63.2222 91.2944 60.055L90.6876 59.9144M100.197 132.294C82.6734 92.8814 118.023 79.5836 144.922 78.1036C148.178 77.926 149.799 77.8372 150.961 78.9916C152.13 80.1386 152.063 81.7814 151.923 85.0596C150.813 112.321 139.631 149.832 100.197 132.294Z"
      :stroke="fill"
      stroke-width="7.15317"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "RepairRecycleResaleIcon",
  props: {
    width: {
      type: [Number, String],
      default: 30,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
    fill: {
      type: String,
      default: "#5BCDCB",
    },
    viewBox: {
      type: [Number, String],
      default: "0 0 156 156",
    },
  },
};
</script>
