import { render, staticRenderFns } from "./BaseIcon.vue?vue&type=template&id=a8454cf6&v-slot=%7B&slotColor=true&%7D=true"
import script from "./BaseIcon.vue?vue&type=script&lang=js"
export * from "./BaseIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports