<template>
  <component
    :is="tag"
    :fill="fill"
    :background="background"
    :width="width"
    :height="height"
    :pageColor="pageColor"
  />
</template>

<script>
export default {
  name: "BomItemGenericIcon",
  props: {
    tag: {
      type: String,
      default: "",
    },
    fill: {
      type: String,
      default: "#442D64",
    },
    background: {
      type: String,
      default: "#E9E7ED",
    },
    pageColor: {
      type: String,
      default: "#FFFFFF",
    },
    width: {
      type: String,
      default: "188px",
    },
    height: {
      type: String,
      default: "168px",
    },
  },
};
</script>
