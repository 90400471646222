<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.01532 4.67732C6.22776 4.58642 6.44361 4.49212 6.65946 4.38602H6.65973C6.94997 4.24666 7.29836 4.36742 7.44037 4.65662C7.58235 4.94582 7.46473 5.29526 7.17684 5.43961C6.94187 5.55487 6.70716 5.6573 6.47687 5.75605C6.17982 5.87996 5.83823 5.74139 5.71145 5.44538C5.58493 5.14937 5.72036 4.80648 6.01531 4.67734L6.01532 4.67732Z" :fill="fill"/>
    <path d="M2.88989 6.8668C3.31216 6.33032 3.81747 5.86486 4.3864 5.48765C4.65753 5.31344 5.0185 5.39072 5.19481 5.66053C5.3711 5.93034 5.29671 6.29184 5.02847 6.47021C4.56167 6.77958 4.14777 7.16254 3.80308 7.60366C3.5985 7.85173 3.23229 7.88893 2.98214 7.68696C2.73171 7.485 2.69058 7.11931 2.88993 6.86682L2.88989 6.8668Z" :fill="fill"/>
    <path d="M22.5374 24.5182C20.8911 26.5876 18.5935 28.0399 16.018 28.6384C15.3062 27.852 11.9315 24.4713 6.91054 24.8223H6.91028C4.67082 25.0322 2.56212 25.971 0.907883 27.495C0.681817 27.7012 0.582535 28.0124 0.647503 28.3113C0.712467 28.6104 0.931722 28.8522 1.22301 28.9462C5.03993 30.2091 4.71905 32.3681 9.18436 33.2436C11.8875 33.7722 14.1442 32.0318 15.8725 29.8741C18.8685 29.2792 21.5558 27.6399 23.4557 25.2483C23.6545 24.9945 23.6112 24.6277 23.3587 24.4271C23.1065 24.2267 22.7395 24.2673 22.5372 24.5182L22.5374 24.5182ZM9.41047 32.092C5.45239 31.3164 5.82196 29.4654 2.10429 28.0178H2.10455C3.49082 26.8636 5.19454 26.1577 6.99101 25.9932C10.7029 25.7394 13.4406 27.8009 14.607 28.882H14.6067C14.1881 28.9292 13.7674 28.954 13.3462 28.9564C11.7444 28.9598 10.1591 28.6329 8.68897 27.9964C8.39166 27.8675 8.04614 28.0042 7.91752 28.3018C7.78863 28.5991 7.92537 28.9446 8.22295 29.0733C9.83997 29.7737 11.5841 30.1336 13.3462 30.13C13.6047 30.13 13.8628 30.1109 14.1208 30.0954C12.5454 31.7201 10.965 32.3969 9.41065 32.092L9.41047 32.092Z" :fill="fill"/>
    <path d="M29.878 16.0869C29.5493 13.8008 27.7737 12.0662 24.6068 10.9238V10.9241C23.4864 8.92096 21.8521 7.25258 19.8727 6.09056C17.8931 4.92882 15.6398 4.31529 13.3446 4.31348C13.0206 4.31348 12.7578 4.57622 12.7578 4.90025C12.7578 5.22428 13.0206 5.48702 13.3446 5.48702C15.3462 5.48938 17.3137 6.00412 19.0599 6.98199C20.8063 7.95986 22.2733 9.36865 23.3214 11.0737C22.9153 11.7915 20.623 16.2161 22.6796 21.0133H22.6793C23.6168 23.0581 25.199 24.7386 27.1836 25.7974C27.4531 25.9425 27.7793 25.9333 28.0402 25.7733C28.3011 25.6132 28.4569 25.3267 28.4496 25.0207C28.3812 21.0081 30.5253 20.5898 29.878 16.087L29.878 16.0869ZM27.2818 24.4956C25.7353 23.5675 24.5064 22.1919 23.7577 20.5508C22.2691 17.0784 23.37 13.7597 24.0016 12.338C24.843 14.1536 25.2021 16.1557 25.0439 18.1508C25.0319 18.3058 25.0822 18.4593 25.1833 18.5777C25.2846 18.6959 25.4285 18.769 25.5838 18.781C25.9068 18.8059 26.1892 18.5644 26.2141 18.2411C26.3689 16.2896 26.076 14.3283 25.358 12.507C27.359 13.4534 28.4941 14.7069 28.7165 16.2537C29.2857 20.2142 27.4193 20.5515 27.2817 24.4953L27.2818 24.4956Z" :fill="fill"/>
    <path d="M9.62659 8.02266C7.48302 13.1208 2.25134 14.4159 1.93415 14.4884C1.55824 16.0487 1.5069 17.6696 1.78353 19.2505C2.06041 20.8316 2.65926 22.3387 3.54304 23.6785C3.71751 23.9486 3.6418 24.3091 3.3733 24.4864C3.1048 24.6635 2.74357 24.5914 2.56362 24.3248C1.52553 22.7507 0.845478 20.9681 0.571471 19.1028C0.297471 17.2372 0.436044 15.3343 0.97776 13.5284C-0.373161 10.4523 -0.482604 7.9242 1.6607 5.57845C1.87995 5.34217 2.24852 5.32697 2.48634 5.54439C2.72446 5.76156 2.74253 6.13012 2.52694 6.36984C1.36312 7.64478 0.702176 9.07242 1.60172 11.8604C2.39463 10.1241 3.56241 8.58543 5.02097 7.35454C5.26904 7.14786 5.63708 7.18008 5.84534 7.42684C6.05359 7.67334 6.02346 8.04191 5.77801 8.25148C4.23041 9.5576 3.0474 11.2422 2.34408 13.1414C3.74476 12.6499 7.03854 11.1507 8.54477 7.56807C9.2062 5.89003 9.34245 4.05058 8.93534 2.29314C7.18474 3.86904 6.38572 3.9306 5.04721 4.54018C4.75304 4.67168 4.40778 4.54123 4.27421 4.2481C4.14035 3.95497 4.26818 3.60894 4.56026 3.47275C5.86739 2.87603 6.83715 2.75186 8.50238 1.08871H8.50211C8.71717 0.870762 9.03178 0.783792 9.32802 0.860287C9.62455 0.936775 9.85769 1.1652 9.94021 1.4599C10.5568 3.62312 10.4468 5.92857 9.62665 8.02312L9.62659 8.02266Z" :fill="fill"/>
    <path d="M3.91482 4.86314C3.91482 5.10047 3.77179 5.31449 3.55254 5.40538C3.33329 5.49628 3.08103 5.44598 2.91311 5.27807C2.7452 5.11042 2.69517 4.8579 2.7858 4.63865C2.8767 4.41939 3.09072 4.27637 3.32804 4.27637C3.48364 4.27637 3.63296 4.33819 3.74298 4.44847C3.853 4.55849 3.91482 4.70754 3.91482 4.86314Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "SustainabilityIcon",
  props: {
    width: {
      type: [Number, String],
      default: 32
    },
    height: {
      type: [Number, String],
      default: 35      
    },
    fill: {
      type: String,
      default: '#70D0CE'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 26 34'    
    },
  }
}
</script>

