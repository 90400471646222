<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 23V22.407C0 20.6491 0.670405 19.3494 1.80122 18.5076C2.9062 17.6854 4.38135 17.3497 5.9272 17.3497C7.47337 17.3497 8.94948 17.6854 10.0534 18.5076C11.1838 19.3489 11.8554 20.6506 11.8554 22.407V23H0ZM2.6356 12.575C2.6356 10.762 4.11016 9.29018 5.92675 9.29018C7.74404 9.29018 9.21893 10.762 9.21893 12.575C9.21893 14.3879 7.7441 15.8594 5.92675 15.8594C4.11016 15.8594 2.6356 14.3879 2.6356 12.575ZM11.9531 9.07321C11.9217 9.0429 11.8924 9.01234 11.863 8.98077L12.2017 7.96739C11.8823 7.54651 11.6488 7.08081 11.501 6.59506L10.48 6.27339C10.2656 5.09717 10.4494 3.86183 11.0324 2.78714L12.1043 2.79591C12.2499 2.58722 12.4172 2.38905 12.6027 2.20342C12.7882 2.01828 12.9871 1.85243 13.1958 1.70738L13.1867 0.636924C14.2641 0.0564617 15.5017 -0.127679 16.6805 0.0862775L17.0033 1.10517C17.4899 1.25248 17.9564 1.48547 18.3775 1.80391L19.3941 1.46571C19.4253 1.49502 19.4566 1.52558 19.4878 1.5564C20.3467 2.41444 20.8511 3.49343 21 4.61103L20.1278 5.23282C20.1368 5.74715 20.0562 6.26275 19.887 6.75427L20.5245 7.61508C20.2704 8.14268 19.9246 8.63673 19.4878 9.07335C19.0484 9.51076 18.5522 9.85674 18.0235 10.1092L17.1615 9.47241C16.6689 9.64201 16.1527 9.72243 15.6377 9.71266L15.0145 10.583C13.8947 10.4351 12.8126 9.9311 11.9531 9.07331L11.9531 9.07321ZM16.9534 6.54544C17.6348 5.86601 17.6348 4.76272 16.9534 4.08405C16.2735 3.40391 15.1672 3.40391 14.4868 4.08405C13.8053 4.76272 13.8053 5.86601 14.4868 6.54544C15.1672 7.22488 16.2735 7.22488 16.9534 6.54544Z" :fill="fill"/>
</svg>
</template>

<script>
export default {
  name: "ProfileSettingsIcon",
  props: {
    width: {
      type: [Number, String],
      default: 25
    },
    height: {
      type: [Number, String],
      default: 23      
    },
    fill: {
      type: String,
      default: '#442D64'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 21 23'    
    },
  }
}
</script>