<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92.2889 60.6711L68.5774 77.7546C67.7826 78.3293 67.3057 79.2587 67.3057 80.237V146.089C67.3057 147.788 68.6753 149.158 70.3751 149.158H117.786C119.486 149.158 120.855 147.788 120.855 146.089V80.237C120.855 79.2465 120.378 78.3293 119.583 77.7546L95.8841 60.6711C94.808 59.9007 93.365 59.9007 92.3011 60.6711H92.2889Z"
      :fill="background"
      :stroke="fill"
      stroke-width="0.611435"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="2.45 2.45"
    />
    <path
      d="M94.0864 88.0022C90.748 88.0022 88.0454 85.2997 88.0454 81.9735C88.0454 78.6473 90.748 75.9325 94.0864 75.9325C97.4248 75.9325 100.115 78.635 100.115 81.9735C100.115 85.3119 97.4126 88.0022 94.0864 88.0022Z"
      :stroke="fill"
      stroke-width="0.611435"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="2.45 2.45"
    />
    <g opacity="0.17">
      <path
        d="M95.004 104.193H98.697V106.039H96.8505V107.886H93.1574V113.438H89.4644V111.591H91.3109V106.052H95.004V104.205V104.193Z"
        :fill="fill"
      />
      <path
        d="M74.6675 104.193V117.131H87.6054V104.193H74.6675ZM85.7589 115.284H76.514V106.039H85.7589V115.284Z"
        :fill="fill"
      />
      <path
        d="M100.556 104.193V117.131H113.494V104.193H100.556ZM111.647 115.284H102.402V106.039H111.647V115.284Z"
        :fill="fill"
      />
      <path d="M78.373 107.898H83.9126V113.438H78.373V107.898Z" :fill="fill" />
      <path
        d="M104.249 107.898H109.788V113.438H104.249V107.898Z"
        :fill="fill"
      />
      <path
        d="M95.0039 109.745H96.8504V111.591H95.0039V109.745Z"
        :fill="fill"
      />
      <path
        d="M96.8503 111.591H98.6968V118.99H96.8503V115.297H95.0038V117.143H93.1572V113.45H96.8503V111.604V111.591Z"
        :fill="fill"
      />
      <path
        d="M89.4641 115.284H91.3106V117.131H93.1571V118.977H91.3106V122.67H93.1571V120.824H95.0037V118.977H96.8502V124.529H93.1571V126.376H91.3106V124.529H89.4641V120.836H85.771V118.99H89.4641V115.297V115.284Z"
        :fill="fill"
      />
      <path d="M78.373 118.99H82.0661V120.836H78.373V118.99Z" :fill="fill" />
      <path
        d="M98.6968 118.99H102.39V120.836H100.543V122.683H98.6968V118.99Z"
        :fill="fill"
      />
      <path d="M104.249 118.99H106.095V120.836H104.249V118.99Z" :fill="fill" />
      <path
        d="M74.6675 120.836H78.3605V122.683H80.2071V124.529H78.3605V126.376H76.514V122.683H74.6675V120.836Z"
        :fill="fill"
      />
      <path
        d="M82.0659 120.836H85.759V122.683H83.9125V124.529H85.759V126.376H82.0659V120.824V120.836Z"
        :fill="fill"
      />
      <path
        d="M106.095 120.836H107.942V122.683H109.788V128.235H107.942V126.388H104.249V128.235H102.402V124.541H106.095V120.848V120.836Z"
        :fill="fill"
      />
      <path
        d="M85.7588 122.683H87.6053V124.529H85.7588V122.683Z"
        :fill="fill"
      />
      <path
        d="M111.647 122.683H113.494V124.529H111.647V122.683Z"
        :fill="fill"
      />
      <path
        d="M87.6053 124.529H89.4519V128.222H85.7588V126.376H87.6053V124.529Z"
        :fill="fill"
      />
      <path
        d="M96.8501 124.529H98.6966V126.376H96.8501V124.529Z"
        :fill="fill"
      />
      <path d="M74.6675 126.376H76.514V128.222H74.6675V126.376Z" :fill="fill" />
      <path d="M78.373 126.376H80.2196V128.222H78.373V126.376Z" :fill="fill" />
      <path
        d="M93.1574 126.376H96.8505V128.222H95.004V131.915H93.1574V135.609H91.3109V131.915H89.4644V128.222H91.3109V130.069H93.1574V126.376Z"
        :fill="fill"
      />
      <path
        d="M98.6968 126.376H100.543V128.222H98.6968V126.376Z"
        :fill="fill"
      />
      <path
        d="M111.647 126.376H113.494V128.222H111.647V126.376Z"
        :fill="fill"
      />
      <path
        d="M96.8501 128.234H98.6966V131.928H96.8501V128.234Z"
        :fill="fill"
      />
      <path
        d="M106.095 128.234H107.942V131.928H106.095V128.234Z"
        :fill="fill"
      />
      <path
        d="M109.788 128.234H111.635V130.081H109.788V128.234Z"
        :fill="fill"
      />
      <path
        d="M102.402 130.081H104.249V131.928H106.095V135.621H111.635V137.467H104.236V141.16H102.39V137.467H100.543V139.314H98.6967V141.16H100.543V143.007H91.2983V141.16H96.8502V137.467H98.6967V131.928H102.39V130.081H102.402Z"
        :fill="fill"
      />
      <path
        d="M74.6675 130.081V143.019H87.6054V130.081H74.6675ZM85.7589 141.172H76.514V131.928H85.7589V141.172Z"
        :fill="fill"
      />
      <path
        d="M95.0039 131.927H96.8504V133.774H95.0039V131.927Z"
        :fill="fill"
      />
      <path
        d="M107.941 131.927H111.634V133.774H107.941V131.927Z"
        :fill="fill"
      />
      <path d="M78.373 133.774H83.9126V139.314H78.373V133.774Z" :fill="fill" />
      <path
        d="M111.647 133.774H113.494V135.621H111.647V133.774Z"
        :fill="fill"
      />
      <path
        d="M89.4644 135.621H91.3109V137.467H93.1574V139.314H89.4644V135.621Z"
        :fill="fill"
      />
      <path
        d="M93.1572 135.621H96.8503V137.467H93.1572V135.621Z"
        :fill="fill"
      />
      <path
        d="M107.941 139.326H109.788V141.172H107.941V139.326Z"
        :fill="fill"
      />
      <path
        d="M111.647 139.326H113.494V141.172H111.647V139.326Z"
        :fill="fill"
      />
      <path
        d="M104.249 141.172H106.095V143.019H104.249V141.172Z"
        :fill="fill"
      />
    </g>
    <path
      d="M100.653 62.6154C100.702 59.3993 98.1466 56.7579 94.9304 56.6967C91.7143 56.6478 89.0729 59.2036 89.0117 62.4198"
      :stroke="fill"
      stroke-width="2.44574"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M95.8842 81.0316C96.141 65.2322 101.008 40.0288 101.216 27.1031C101.338 19.8026 99.5283 16.4152 97.9386 14.8622C96.5567 13.5048 94.6735 13.4803 93.2428 14.7888C91.6041 16.2929 89.6842 19.6191 89.5742 26.9197C89.3663 39.8454 93.414 65.1832 93.1572 80.995"
      :stroke="fill"
      stroke-width="2.44574"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "HangTagThread",
  props: {
    width: {
      type: String,
      default: "188",
    },
    height: {
      type: String,
      default: "168",
    },
    fill: {
      type: String,
      default: "#442D64",
    },
    background: {
      type: String,
      default: "#E9E7ED",
    },
    viewBox: {
      type: String,
      default: "0 0 188 168",
    },
  },
};
</script>
