<template>
  <v-menu v-model="showPicker" bottom offset-y>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <div class="label-container">
          <v-tooltip v-if="!showPicker && description" color="#473068" bottom>
            <template v-slot:activator="{ on }">
              <div class="picker-label" v-on="on">{{ label }}</div>
            </template>
            <div class="hover-container">
              <span>{{ description }}</span>
            </div>
          </v-tooltip>
          <div v-else class="picker-label">{{ label }}</div>
          <div class="color-preview-container">
            <div class="color-preview" :style="{ background: color }"></div>
            <v-icon>{{ showPicker ? "mdi-menu-up" : "mdi-menu-down" }}</v-icon>
          </div>
        </div>
      </div>
    </template>
    <div class="picker-container" @click.stop>
      <v-color-picker v-model="color" show-swatches></v-color-picker>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: "ColorPicker",
  props: {
    label: {
      type: String,
      default: "",
    },
    currentColor: {
      type: String,
      default: "#000000",
    },
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      color: this.currentColor,
      showPicker: false,
    };
  },
  watch: {
    color: {
      handler(newColor) {
        this.$emit("input", newColor);
      },
      immediate: true,
    },
    currentColor: {
      handler(newColor) {
        this.color = newColor;
      },
    },
  },
};
</script>

<style scoped>
.picker-label {
  flex: 1;
  display: block;
  font-size: 14px;
  text-align: left;
  color: #442d65;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.v-icon {
  color: #442d65;
}
.label-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 5px 0;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}
.color-preview-container {
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}
.color-preview {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  border: 1px solid #e0e0e0;
}
.picker-container {
  height: fit-content;
  width: fit-content;
  background-color: white;
}
.v-menu__content {
  min-width: unset !important;
}
.hover-container {
  max-width: 150px;
  word-wrap: anywhere;
}
</style>
