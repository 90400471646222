/**
 * This module handle the validations
 */
export default {
    emailFormatter(value) {
        if (!value) return value;
        const parts = value.split('@');
        parts[1] = parts[1] && parts[1].toLowerCase();
        return parts.join('@');
    }
}