<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.76658 1.36604C8.07888 1.05328 8.07888 0.546995 7.76658 0.234226C7.45383 -0.0780752 6.94754 -0.0780752 6.63477 0.234226L0.234226 6.63477C-0.0780752 6.94752 -0.0780752 7.45381 0.234226 7.76658L6.63477 14.1671C6.94753 14.4794 7.45381 14.4794 7.76658 14.1671C8.07888 13.8544 8.07888 13.3481 7.76658 13.0353L1.9318 7.20053L7.76658 1.36604Z"
    :fill="fill"
  />
</template>

<script>
export default {
  name: "PopupBack",
  props: {
    fill: {
      type: String,
      default: '#473068'
    }
  }
}
</script>
