<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :stroke="fill"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.75 9.40337C24.859 17.2698 36.5837 0.294032 43.5225 6.45503C47.5129 9.99812 46.2497 17.8264 41.0698 22.925"
      :stroke="stroke"
      stroke-width="3.8125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.0435 35.5419C35.0892 34.6904 35.3256 33.1349 34.0345 31.953C33.541 31.5221 32.9651 31.1961 32.3417 30.9948C29.678 30.0544 26.4044 33.1984 28.7198 36.0782C29.9652 37.6235 30.9235 38.0988 30.8345 39.8551C30.771 41.0903 29.556 42.3815 27.9573 42.8746C26.567 43.3016 25.0344 42.7348 24.066 41.652C22.8816 40.3304 22.9985 39.0824 22.9909 38.5385"
      :stroke="stroke"
      stroke-width="3.8125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.0345 31.9556L35.4934 30.4941"
      :stroke="fill"
      stroke-width="3.8125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.1703 41.8172L22.785 43.2024"
      :stroke="fill"
      stroke-width="3.8125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M46.441 16.8702C48.792 17.3227 49.3207 18.807 50.0222 22.9296C50.6551 26.6404 50.833 31.096 50.833 32.9946C50.7654 33.6907 50.4819 34.3483 50.0222 34.8754C45.1041 40.0299 35.3415 49.7645 30.4158 54.5987C28.4841 56.3271 25.5663 56.3652 23.5126 54.7792C19.3113 50.9998 15.2751 46.7272 11.3203 42.869C9.73171 40.8229 9.76984 37.9127 11.4982 35.9861C16.7111 30.58 26.1433 21.3258 31.4655 16.212C31.9993 15.7545 32.655 15.4698 33.354 15.4062C34.5485 15.4062 36.5971 15.5664 38.5949 15.6807"
      :stroke="stroke"
      stroke-width="3.8125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ResaleIcon",
  props: {
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    fill: {
      type: String,
      default: "#EA7A66",
    },
    viewBox: {
      type: [Number, String],
      default: "15 25 35 15",
    },
  },
};
</script>
