<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92.2083 64.0329L68.4969 81.1164C67.702 81.6911 67.2251 82.6205 67.2251 83.5988V149.45C67.2251 151.15 68.5947 152.52 70.2945 152.52H117.705C119.405 152.52 120.775 151.15 120.775 149.45V83.5988C120.775 82.6083 120.298 81.6911 119.503 81.1164L95.8036 64.0329C94.7274 63.2625 93.2845 63.2625 92.2206 64.0329H92.2083Z"
      :fill="background"
      :stroke="fill"
      stroke-width="2.87694"
      stroke-miterlimit="10"
    />
    <path
      d="M94.0058 91.3642C90.6674 91.3642 87.9648 88.6616 87.9648 85.3354C87.9648 82.0092 90.6674 79.2944 94.0058 79.2944C97.3443 79.2944 100.035 81.997 100.035 85.3354C100.035 88.6738 97.332 91.3642 94.0058 91.3642Z"
      :fill="background"
      :stroke="fill"
      stroke-width="2.87694"
      stroke-miterlimit="10"
    />
    <path
      d="M100.573 65.9772C100.622 62.7611 98.066 60.1197 94.8498 60.0586C91.6337 60.0096 88.9923 62.5654 88.9312 65.7816"
      :stroke="fill"
      stroke-width="2.44574"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M95.8036 84.3937C96.0604 68.5942 100.927 43.3909 101.135 30.4651C101.258 23.1646 99.4477 19.7773 97.858 18.2242C96.4762 16.8668 94.593 16.8424 93.1622 18.1508C91.5235 19.655 89.6036 22.9812 89.4936 30.2817C89.2857 43.2074 93.3334 68.5453 93.0766 84.357"
      :stroke="fill"
      stroke-width="2.44574"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M93.3402 128.489C95.0338 128.489 96.4039 129.91 96.4039 131.643H99.4675C99.4675 128.715 97.5075 126.266 94.872 125.561V123.759H91.8084V125.561C89.1729 126.266 87.2129 128.715 87.2129 131.643C87.2129 132.172 87.4698 132.662 87.8979 132.956L96.3182 138.734C95.9852 140.095 94.7769 141.104 93.3497 141.104C91.6561 141.104 90.2861 139.684 90.2861 137.95H87.2224C87.2224 140.879 89.1824 143.327 91.8179 144.032V145.835H94.8816V144.032C97.5171 143.327 99.477 140.879 99.477 137.95C99.477 137.422 99.2202 136.932 98.792 136.638L90.3717 130.86C90.7047 129.498 91.913 128.489 93.3402 128.489Z"
      :fill="fill"
    />
    <path d="M93.3447 99.9619H96.2247V115.832H93.3447V99.9619Z" :fill="fill" />
    <path
      d="M100.555 99.9619H103.435V115.832H100.545V99.9619H100.555Z"
      :fill="fill"
    />
    <path d="M104.885 99.9619H107.765V115.832H104.885V99.9619Z" :fill="fill" />
    <path d="M109.215 99.9619H110.655V115.832H109.215V99.9619Z" :fill="fill" />
    <path d="M97.6748 99.9619H99.1148V115.832H97.6748V99.9619Z" :fill="fill" />
    <path d="M77.3447 99.9619H80.2247V115.832H77.3447V99.9619Z" :fill="fill" />
    <path
      d="M84.5549 99.9619H87.4349V115.832H84.5449V99.9619H84.5549Z"
      :fill="fill"
    />
    <path d="M88.8848 99.9619H91.7648V115.832H88.8848V99.9619Z" :fill="fill" />
    <path d="M93.2148 99.9619H94.6548V115.832H93.2148V99.9619Z" :fill="fill" />
    <path d="M81.6748 99.9619H83.1148V115.832H81.6748V99.9619Z" :fill="fill" />
  </svg>
</template>

<script>
export default {
  name: "MainLabel",
  props: {
    width: {
      type: String,
      default: "188",
    },
    height: {
      type: String,
      default: "168",
    },
    fill: {
      type: String,
      default: "#442D64",
    },
    background: {
      type: String,
      default: "#E9E7ED",
    },
    viewBox: {
      type: String,
      default: "0 0 188 168",
    },
  },
};
</script>
