<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.165 25.6666C20.2428 25.6666 18.665 24.0889 18.665 22.1666V5.8331C18.665 3.91085 20.2428 2.3331 22.165 2.3331C24.0875 2.3331 25.665 3.91085 25.665 5.8331V22.1666C25.665 24.0889 24.0875 25.6666 22.165 25.6666ZM22.165 28C25.3762 28 27.9985 25.3776 27.9985 22.1665V5.833C27.9985 2.62205 25.3763 0 22.165 0C18.9541 0 16.332 2.62225 16.332 5.833V22.1665C16.332 25.3775 18.9543 28 22.165 28Z" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6665 22.166V4.66602H0V27.9995H22.1665V25.6661C20.1648 25.6661 18.6665 24.1794 18.6665 22.1661V22.166ZM2.3335 25.666V6.99952H16.3335V22.166C16.3335 23.4969 16.7462 24.7016 17.4538 25.666H2.3335Z" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.6651 22.1665C25.6651 24.0993 24.0979 25.6665 22.1651 25.6665C20.2323 25.6665 18.6651 24.0993 18.6651 22.1665C18.6651 20.2337 20.2323 18.6665 22.1651 18.6665C24.0979 18.6665 25.6651 20.2337 25.6651 22.1665ZM27.9985 22.1665C27.9985 18.945 25.3866 16.333 22.165 16.333C18.9435 16.333 16.332 18.9449 16.332 22.1665C16.332 25.388 18.9437 28 22.165 28C25.3865 28 27.9985 25.3881 27.9985 22.1665Z" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.73194 4.95508L0.398438 13.1216L1.93494 14.8776L11.2679 6.71113L9.73194 4.95508Z" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7246 16.6279L6.22461 25.9614L7.77461 27.7054L18.2746 18.3719L16.7246 16.6279Z" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7417 4.94824L0.408203 18.9482L1.92675 20.7199L18.2598 6.71994L16.7417 4.94824Z" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7417 10.7803L0.408203 24.7803L1.92675 26.5518L18.2598 12.5518L16.7417 10.7803Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "FabricIcon",
  props: {
    width: {
      type: [Number, String],
      default: 28
    },
    height: {
      type: [Number, String],
      default: 28
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 28 28'    
    },
  }
}
</script>
