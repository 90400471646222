<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="58.0327"
      y="29.8623"
      width="71.7979"
      height="116.525"
      :fill="background"
      :stroke="fill"
      stroke-width="2.87694"
      stroke-miterlimit="10"
    />
    <path d="M93.2764 120.357H96.1564V136.227H93.2764V120.357Z" :fill="fill" />
    <path
      d="M100.487 120.357H103.367V136.227H100.477V120.357H100.487Z"
      :fill="fill"
    />
    <path d="M104.816 120.357H107.696V136.227H104.816V120.357Z" :fill="fill" />
    <path d="M109.146 120.357H110.586V136.227H109.146V120.357Z" :fill="fill" />
    <path d="M97.6064 120.357H99.0464V136.227H97.6064V120.357Z" :fill="fill" />
    <path d="M77.2764 120.357H80.1564V136.227H77.2764V120.357Z" :fill="fill" />
    <path
      d="M84.4866 120.357H87.3666V136.227H84.4766V120.357H84.4866Z"
      :fill="fill"
    />
    <path d="M88.8164 120.357H91.6964V136.227H88.8164V120.357Z" :fill="fill" />
    <path d="M93.1465 120.357H94.5865V136.227H93.1465V120.357Z" :fill="fill" />
    <path d="M81.6064 120.357H83.0464V136.227H81.6064V120.357Z" :fill="fill" />
    <path
      d="M103.961 59.3591C103.607 59.0076 103.035 59.0076 102.682 59.3591L100.223 61.8173H92.1597C91.6585 61.8173 91.2526 62.2232 91.2526 62.7243C91.2526 63.2255 91.6585 63.6314 92.1597 63.6314H98.4093L95.8695 66.1712H92.6221L85.8189 59.3591C85.4629 59.003 84.8869 59.003 84.5309 59.3591C84.1749 59.7151 84.1749 60.2911 84.5309 60.6471L90.1274 66.2436C88.336 66.4749 86.6884 67.3491 85.4946 68.704C84.2995 70.059 83.6374 71.8029 83.6328 73.6089C83.6328 73.8492 83.7281 74.0805 83.8981 74.2506C84.0682 74.4207 84.2995 74.5159 84.5399 74.5159H87.524L84.5309 77.5001C84.3392 77.6645 84.2247 77.9015 84.2145 78.1543C84.2043 78.406 84.3007 78.6521 84.4799 78.8301C84.6579 79.0092 84.9039 79.1056 85.1556 79.0954C85.4085 79.0852 85.6454 78.9707 85.8098 78.7791L90.0822 74.5157H98.3999L102.672 78.7881C103.032 79.0965 103.569 79.076 103.904 78.7404C104.239 78.406 104.26 77.8685 103.951 77.5091L100.967 74.5159H103.044C103.32 74.5216 103.583 74.4014 103.76 74.1894C103.935 73.9785 104.007 73.6973 103.951 73.4274L102.681 67.1506C102.682 67.1267 102.682 67.1018 102.681 67.078C102.682 66.9329 102.647 66.79 102.582 66.6607L101.811 62.7968L103.96 60.6471C104.132 60.477 104.229 60.2446 104.229 60.003C104.229 59.7615 104.132 59.5292 103.961 59.3591ZM85.52 72.7021C85.7365 71.3857 86.4134 70.1896 87.4305 69.3255C88.4475 68.4615 89.7367 67.9865 91.0712 67.9853H91.8785L92.967 69.0738L89.3387 72.7021L85.52 72.7021ZM91.8694 72.7021L94.2459 70.3528L96.5952 72.7021H91.8694ZM99.1259 72.7021L95.4977 69.0739L96.5862 67.9854H100.967L101.874 72.7021H99.1259ZM100.595 66.1713H98.4275L100.242 64.3571L100.595 66.1713Z"
      :fill="fill"
    />
    <path
      d="M92.0261 95.5104C91.6455 95.5104 91.2096 95.6741 90.9098 95.8644C90.9374 95.1564 91.0459 94.5036 91.8347 94.5036C92.2153 94.5036 92.4067 94.6939 92.4875 94.9395H93.5219C93.4135 93.9593 92.6778 93.6063 92.025 93.6063C90.3643 93.6063 89.8477 94.9948 89.8477 96.3822C89.8477 97.7972 90.2836 99.1316 91.9166 99.1316C92.951 99.1316 93.658 98.397 93.658 97.2264C93.6857 96.192 93.1137 95.5115 92.025 95.5115L92.0261 95.5104ZM91.7816 98.2599C91.2096 98.2599 90.9108 97.824 90.9108 97.3349C90.9108 96.8448 91.183 96.41 91.7816 96.41C92.3801 96.41 92.6523 96.8724 92.6523 97.3349C92.6523 97.7708 92.3259 98.2599 91.7816 98.2599ZM96.1629 93.6052C94.5299 93.6052 94.1759 94.9937 94.1759 96.3811C94.1759 97.7696 94.5565 99.1569 96.1629 99.1569C97.796 99.1569 98.15 97.7684 98.15 96.3811C98.15 94.9926 97.796 93.6052 96.1629 93.6052ZM96.1629 98.2599C95.3741 98.2599 95.2104 97.6613 95.2104 96.3823C95.2104 95.1034 95.3741 94.5048 96.1629 94.5048C96.9518 94.5048 97.1155 95.1034 97.1155 96.3823C97.1155 97.6613 96.9518 98.2599 96.1629 98.2599Z"
      :fill="fill"
    />
    <path
      d="M104.246 87.6996L103.593 91.1836C102.859 91.4823 102.124 91.7545 101.334 91.7545C100.409 91.7545 99.9731 91.7003 99.1566 91.4005C98.5581 91.2368 97.3875 90.8561 96.7613 90.8561C96.1085 90.8561 94.7743 91.2367 94.1215 91.4547C93.4687 91.6992 92.7883 91.7545 92.1344 91.7545C91.4817 91.7545 90.8012 91.6461 90.175 91.4547C89.3585 91.2102 88.4602 90.8561 87.6171 90.8561C86.5008 90.8561 85.4397 91.3728 84.4872 91.7811L83.6164 87.6719H81.6836L84.7594 101.907H103.159L106.179 87.6996L104.246 87.6996ZM101.742 100.137H86.1744L84.5956 92.6528C85.4121 92.1626 86.6369 91.7278 87.6173 91.7278C88.2977 91.7278 89.414 91.9723 90.0944 92.2445C90.7472 92.4624 91.4011 92.6251 92.0815 92.6251C92.7077 92.6251 93.6061 92.4614 94.2046 92.2445C94.9935 92 95.9461 91.7278 96.7626 91.7278C97.4153 91.7278 97.9597 91.8915 98.5859 92.0819C99.3205 92.354 100.327 92.6262 101.144 92.6262C101.933 92.6262 102.695 92.2998 103.43 92.0819L101.742 100.139L101.742 100.137Z"
      :fill="fill"
    />
    <path d="M58.8398 40.6411H129.967" :stroke="fill" stroke-dasharray="2 2" />
  </svg>
</template>

<script>
export default {
  name: "CareLabel",
  props: {
    width: {
      type: String,
      default: "188",
    },
    height: {
      type: String,
      default: "168",
    },
    fill: {
      type: String,
      default: "#442D64",
    },
    background: {
      type: String,
      default: "#E9E7ED",
    },
    viewBox: {
      type: String,
      default: "0 0 188 168",
    },
  },
};
</script>
