<template>
  <path
    d="M5.5294 28.8086H24.4706C28.163 28.8086 30 27.4373 30 24.7209V12.8628C30 11.1778 29.7504 10.4467 28.3235 9.37576L18.4778 2.04951C17.1232 1.03037 16.0167 0.808594 14.0016 0.808594H5.5294C1.85531 0.808594 0 2.19271 0 4.90969V24.7209C0 27.4501 1.85531 28.8086 5.5294 28.8086ZM5.67171 26.7062C3.83466 26.7062 2.87124 25.9874 2.87124 24.6819V4.94869C2.87124 3.65596 3.83466 2.91096 5.68997 2.91096H13.6089V10.4991C13.6089 12.1446 14.7504 12.9542 16.9618 12.9542H27.1288V24.6819C27.1288 25.9874 26.1828 26.7062 24.3283 26.7062H5.67171ZM17.283 10.9816C16.5874 10.9816 16.3013 10.7732 16.3013 10.2506V3.31606L26.5748 10.9822L17.283 10.9816ZM21.5636 16.5454H8.02547C7.38395 16.5454 6.9205 16.8981 6.9205 17.3422C6.9205 17.7992 7.38471 18.1519 8.02623 18.1519H21.5636C21.7095 18.1536 21.8543 18.1339 21.9895 18.0938C22.1247 18.0537 22.2476 17.9941 22.3507 17.9186C22.4539 17.8431 22.5352 17.7531 22.59 17.6541C22.6447 17.5551 22.6717 17.449 22.6693 17.3422C22.6693 16.8981 22.1876 16.5454 21.5636 16.5454ZM21.5636 21.1034H8.02547C7.38395 21.1034 6.9205 21.469 6.9205 21.9259C6.9205 22.37 7.38471 22.7099 8.02623 22.7099H21.5636C22.1876 22.7099 22.6693 22.37 22.6693 21.9259C22.6693 21.469 22.1876 21.1034 21.5636 21.1034Z"
    :fill="fill"
  />
</template>

<script>
export default {
  name: "BomIcon",
  props: {
    fill: {
      type: String,
      default: "#70D0CE",
    },
  },
};
</script>
