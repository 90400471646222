<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.50216 12.7529C9.08902 12.7529 9.56486 13.2288 9.56486 13.8156V22.3173C9.56486 22.9041 9.08902 23.38 8.50216 23.38C7.9153 23.38 7.43945 22.9041 7.43945 22.3173V13.8156C7.43945 13.2288 7.9153 12.7529 8.50216 12.7529Z" :fill="fill"/>
    <path d="M15.9438 13.8156C15.9438 13.2288 15.4679 12.7529 14.8811 12.7529C14.2942 12.7529 13.8184 13.2288 13.8184 13.8156V22.3173C13.8184 22.9041 14.2942 23.38 14.8811 23.38C15.4679 23.38 15.9438 22.9041 15.9438 22.3173V13.8156Z" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0659 3.18811V1.0627C18.0659 0.475842 17.5901 0 17.0032 0H6.37622C5.78936 0 5.31351 0.475842 5.31351 1.0627V3.18811H1.0627C0.475842 3.18811 0 3.66395 0 4.25081V8.50162C0 9.08848 0.475842 9.56433 1.0627 9.56433V26.5676C1.0627 28.3284 2.49 29.7557 4.25081 29.7557H19.1287C20.8895 29.7557 22.3168 28.3284 22.3168 26.5676V9.56433C22.9036 9.56433 23.3795 9.08848 23.3795 8.50162V4.25081C23.3795 3.66395 22.9036 3.18811 22.3168 3.18811H18.0659ZM7.43892 3.18811H15.9405V2.12541H7.43892V3.18811ZM2.12541 5.31351V7.43892H21.2541V5.31351H2.12541ZM3.18811 26.5676V9.56433H20.1914V26.5676C20.1914 27.1544 19.7155 27.6303 19.1287 27.6303H4.25081C3.66395 27.6303 3.18811 27.1544 3.18811 26.5676Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "UpdateStatus",
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 30
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 24 30'    
    },
  }
}
</script>