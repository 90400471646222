<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.5001 4.14286e-05C17.6305 4.14286e-05 20.1815 2.48011 20.296 5.58254L20.3 5.8H23.2C26.4032 5.8 29 8.39679 29 11.6V23.2C29 26.4032 26.4032 29 23.2 29H5.8C2.59679 29 0 26.4032 0 23.2V11.6C0 8.39679 2.59679 5.8 5.8 5.8H8.7C8.7 2.59679 11.2968 0 14.5 0L14.5001 4.14286e-05ZM23.2001 7.7332H5.8001C3.66456 7.7332 1.93336 9.4644 1.93336 11.5999V23.1999C1.93336 25.3355 3.66456 27.0667 5.8001 27.0667H23.2001C25.3356 27.0667 27.0668 25.3355 27.0668 23.1999V11.5999C27.0668 9.4644 25.3356 7.7332 23.2001 7.7332ZM8.7001 19.3332C9.23396 19.3332 9.66683 19.7661 9.66683 20.2999V22.2332C9.66683 22.7672 9.23394 23.1999 8.7001 23.1999H6.76683C6.23282 23.1999 5.8001 22.7672 5.8001 22.2332V20.2999C5.8001 19.7661 6.23279 19.3332 6.76683 19.3332H8.7001ZM18.3669 20.2999C18.9008 20.2999 19.3335 20.7326 19.3335 21.2667C19.3335 21.8005 18.9008 22.2332 18.3669 22.2332H13.5335C12.9996 22.2332 12.5669 21.8005 12.5669 21.2667C12.5669 20.7327 12.9996 20.2999 13.5335 20.2999H18.3669ZM8.7001 11.5999C9.23396 11.5999 9.66683 12.0326 9.66683 12.5667V14.4999C9.66683 15.0338 9.23394 15.4667 8.7001 15.4667H6.76683C6.23282 15.4667 5.8001 15.0338 5.8001 14.4999V12.5667C5.8001 12.0327 6.23279 11.5999 6.76683 11.5999H8.7001ZM22.2333 12.5667C22.7673 12.5667 23.2 12.9994 23.2 13.5332C23.2 14.0672 22.7673 14.4999 22.2333 14.4999H13.5333C12.9994 14.4999 12.5667 14.0672 12.5667 13.5332C12.5667 12.9993 12.9994 12.5667 13.5333 12.5667H22.2333ZM14.5001 1.933C12.3646 1.933 10.6334 3.66419 10.6334 5.79973H18.367L18.3622 5.60675C18.2617 3.5608 16.5711 1.93302 14.5003 1.93302L14.5001 1.933Z"
    :fill="fill"
  />
</template>

<script>
export default {
  name: "ProjectBoard",
  props: {
    fill: {
      type: String,
      default: '#70D0CE'
    }
  }
}
</script>


