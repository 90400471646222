<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="90.1593"
      cy="84.1998"
      r="34.8204"
      :fill="background"
      :stroke="fill"
      stroke-width="3"
      stroke-miterlimit="10"
    />
    <circle
      cx="90.1591"
      cy="84.1998"
      r="26.5575"
      fill="none"
      :stroke="fill"
      stroke-width="3"
      stroke-miterlimit="10"
    />
    <circle
      cx="80.8333"
      cy="76.1914"
      r="5.19755"
      :fill="background"
      :stroke="fill"
      stroke-width="3"
      stroke-miterlimit="10"
    />
    <circle
      cx="80.8333"
      cy="94.636"
      r="5.19755"
      :fill="background"
      :stroke="fill"
      stroke-width="3"
      stroke-miterlimit="10"
    />
    <circle
      cx="99.4856"
      cy="76.1914"
      r="5.19755"
      :fill="background"
      :stroke="fill"
      stroke-width="3"
      stroke-miterlimit="10"
    />
    <circle
      cx="113.475"
      cy="99.8337"
      r="19.1867"
      :fill="pageColor"
      stroke-width="3"
      stroke-miterlimit="10"
    />
    <circle
      cx="113.475"
      cy="99.8337"
      r="19.1867"
      :fill="background"
      :stroke="fill"
      stroke-width="3"
      stroke-miterlimit="10"
    />
    <circle
      cx="107.461"
      cy="94.3256"
      r="2.86395"
      :fill="background"
      :stroke="fill"
      stroke-width="1.65306"
      stroke-miterlimit="10"
    />
    <circle
      cx="107.461"
      cy="105.341"
      r="2.86395"
      :fill="background"
      :stroke="fill"
      stroke-width="1.65306"
      stroke-miterlimit="10"
    />
    <circle
      cx="119.489"
      cy="94.3256"
      r="2.86395"
      :fill="background"
      :stroke="fill"
      stroke-width="1.65306"
      stroke-miterlimit="10"
    />
    <circle
      cx="119.489"
      cy="105.341"
      r="2.86395"
      :fill="background"
      :stroke="fill"
      stroke-width="1.65306"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  name: "Body",
  props: {
    width: {
      type: String,
      default: "188",
    },
    height: {
      type: String,
      default: "168",
    },
    fill: {
      type: String,
      default: "#442D64",
    },
    background: {
      type: String,
      default: "#E9E7ED",
    },
    pageColor: {
      type: String,
      default: "#FFFFFF",
    },
    viewBox: {
      type: String,
      default: "0 0 188 168",
    },
  },
};
</script>
