<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.03176 14.3506C3.64542 14.3506 0.890625 11.5958 0.890625 8.20949C0.890625 4.82341 3.64542 2.06836 7.03176 2.06836C10.4181 2.06836 13.1729 4.82315 13.1729 8.20949C13.1729 11.5956 10.4181 14.3506 7.03176 14.3506ZM7.03176 2.8448C4.0737 2.8448 1.66758 5.25149 1.66758 8.20949C1.66758 11.1675 4.07365 13.5742 7.03176 13.5742C9.98986 13.5742 12.3959 11.1675 12.3959 8.20949C12.3959 5.25149 9.98986 2.8448 7.03176 2.8448Z" :fill="fill"/>
    <path d="M7.03129 13.1704C4.29621 13.1704 2.07031 10.9453 2.07031 8.21021C2.07031 5.47513 4.29598 3.25 7.03129 3.25C9.7666 3.25 11.9923 5.47508 11.9923 8.21021C11.9923 10.9453 9.7666 13.1704 7.03129 13.1704ZM7.03129 4.02644C4.72442 4.02644 2.84752 5.90321 2.84752 8.21021C2.84752 10.5172 4.72429 12.3945 7.03129 12.3945C9.33829 12.3945 11.2151 10.5177 11.2151 8.21072C11.2151 5.90385 9.33829 4.02644 7.03129 4.02644Z" :fill="fill"/>
    <path d="M7.03309 8.5978C6.93389 8.5978 6.8342 8.5602 6.75831 8.4843C6.60661 8.3332 6.60661 8.0871 6.75831 7.93542L8.7821 5.91163C8.9338 5.75993 9.17989 5.75993 9.3316 5.91163C9.48329 6.06272 9.48329 6.30883 9.3316 6.46051L7.3078 8.4843C7.23201 8.5602 7.13231 8.5978 7.03312 8.5978H7.03309Z" :fill="fill"/>
    <path d="M6.64453 1.09277H7.42161V2.45647H6.64453V1.09277Z" :fill="fill"/>
    <path d="M9.28975 1.48111H4.77344V1.09281C4.77344 0.509831 5.24772 0.0361328 5.83073 0.0361328H8.23322C8.81621 0.0361328 9.29052 0.509831 9.29052 1.09281L9.29042 1.48111H9.28975Z" :fill="fill"/>
</svg>
</template>

<script>
export default {
  name: "AlarmIcon",
  props: {
    width: {
      type: [Number, String],
      default: 14
    },
    height: {
      type: [Number, String],
      default: 15      
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 14 15'    
    },
  }
}
</script>