<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.22413 0.679036C1.00164 0.679036 0.000211716 1.69174 0.000211716 2.91397V15.075C0.000211716 16.2975 1.00189 17.3099 2.22413 17.3099H4.40429C4.77576 18.6266 5.98482 19.5997 7.41716 19.5997C8.849 19.5997 10.0693 18.6273 10.441 17.3099H22.7004C23.0721 18.6271 24.2931 19.5997 25.7243 19.5997C27.1554 19.5997 28.3654 18.6268 28.7371 17.3099H29.022C30.0278 17.3099 30.8518 16.4821 30.8518 15.4802V11.032V11.0323C30.8621 10.9325 30.8508 10.8315 30.8188 10.7364C30.8156 10.7217 30.8122 10.707 30.808 10.6926C30.7772 10.6036 30.7288 10.5217 30.6654 10.4517L26.9186 5.25843C26.7854 5.07771 26.5733 4.97182 26.3488 4.97353L21.8132 4.96252V2.91365C21.8132 1.69116 20.8115 0.678711 19.5893 0.678711H2.22386L2.22413 0.679036ZM2.22413 2.08126H19.5895C20.049 2.08126 20.4112 2.4542 20.4112 2.91397V15.9077H10.5071C10.2425 14.4445 8.95148 13.322 7.41741 13.322C5.88333 13.322 4.60362 14.4443 4.33874 15.9077H2.22438C1.76487 15.9077 1.40269 15.5347 1.40269 15.0749V2.91391C1.40269 2.45415 1.76487 2.0812 2.22438 2.0812L2.22413 2.08126ZM23.0516 6.90185H25.4509C25.6776 6.90259 25.8896 7.01288 26.0207 7.19776L28.453 10.6161V10.6159C28.6054 10.8311 28.6242 11.1138 28.5017 11.3474C28.3791 11.5809 28.1358 11.7259 27.8722 11.7225H23.0516C22.6645 11.7225 22.3505 11.4087 22.3505 11.0214V7.60302V7.60327C22.3505 7.2159 22.6645 6.9019 23.0516 6.9019L23.0516 6.90185Z" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.7568 8.30762H25.0869L26.5176 10.3191H23.7568V8.30762Z" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3985 5.59381C11.2112 5.59136 11.0305 5.66424 10.897 5.79581C10.7637 5.92763 10.6886 6.10737 10.6886 6.29494C10.6886 6.48227 10.7637 6.66201 10.897 6.79383C11.0305 6.92564 11.2112 6.99827 11.3985 6.99607H18.2744C18.4618 6.99828 18.6425 6.92564 18.776 6.79383C18.9093 6.66201 18.9844 6.48227 18.9844 6.29494C18.9844 6.10737 18.9093 5.92763 18.776 5.79581C18.6425 5.66424 18.4618 5.59137 18.2744 5.59381H11.3985Z" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25263 8.74029C8.0653 8.73785 7.88458 8.81072 7.7513 8.94254C7.61778 9.07411 7.5427 9.25386 7.5427 9.44143C7.5427 9.629 7.61778 9.8085 7.7513 9.94031C7.88458 10.0721 8.0653 10.145 8.25263 10.1426H18.2744C18.4618 10.145 18.6425 10.0721 18.776 9.94031C18.9093 9.8085 18.9844 9.629 18.9844 9.44143C18.9844 9.25386 18.9093 9.07411 18.776 8.94254C18.6425 8.81072 18.4618 8.73785 18.2744 8.74029H8.25263Z" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.74132 20.2227C1.55399 20.2203 1.37327 20.2931 1.23999 20.425C1.10646 20.5565 1.03138 20.7363 1.03138 20.9238C1.03138 21.1112 1.10646 21.2909 1.23999 21.4227C1.37327 21.5543 1.55399 21.6272 1.74132 21.625H28.376C28.5633 21.6272 28.7441 21.5543 28.8773 21.4227C29.0109 21.2909 29.0859 21.1112 29.0859 20.9238C29.0859 20.7363 29.0109 20.5565 28.8773 20.425C28.7441 20.2931 28.5633 20.2203 28.376 20.2227H1.74132Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "DeliveryIcon",
  props: {
    width: {
      type: [Number, String],
      default: 32
    },
    height: {
      type: [Number, String],
      default: 22      
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 31 22'    
    },
  }
}
</script>

