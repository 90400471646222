import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import "@/sass/overrides.sass";

Vue.use(Vuetify);

const theme = {
  primary: "#473068",
  secondary: "#9C27b0",
  accent: "#70D0CE",
  info: "#00CAE3",
  danger: "#EA7A66",
  lighter: '#F3EDF7'
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
});
