<template v-slot="{" slotColor }>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :fill="fill"
    :opacity="opacity"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
  >
    <slot :slotColor="childIconColor" />
  </svg>
</template>

<script>
export default {
  name: "BaseIcon",
  props: {
    width: {
      type: [Number, String],
      default: 28,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    fill: {
      type: String,
      default: "none",
    },
    opacity: {
      type: [Number, String],
      default: 1,
    },
    viewBox: {
      type: [Number, String],
      default: "0 0 28 24",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    childIconColor() {
      if (this.active) {
        return this.colors.success;
      } else {
        return this.colors.primaryDark;
      }
    },
  },
  data: () => ({
    colors: {
      primaryDark: "#442D65",
      success: "#70D0CE",
    },
  }),
};
</script>
