<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.3811 26.6113H0.924378C0.420272 26.6113 0 26.1912 0 25.687V0.924833C0 0.420729 0.420092 0.000457764 0.924378 0.000457764H22.3531C22.8572 0.000457764 23.2775 0.420547 23.2775 0.924833V25.687C23.3056 26.1913 22.8854 26.6113 22.3812 26.6113H22.3811ZM21.4568 1.84921H1.84876V24.7626H21.4568V1.84921Z" :fill="fill" />
    <path d="M10.0317 19.7207H17.7622C18.2663 19.7207 18.6866 20.1408 18.6866 20.645C18.6866 21.1491 18.2665 21.5693 17.7622 21.5693H10.0317C9.52766 21.5693 9.10742 21.1493 9.10742 20.645C9.10742 20.141 9.52767 19.7207 10.0317 19.7207Z" :fill="fill" />
    <path d="M10.0317 14.8184H17.7622C18.2663 14.8184 18.6866 15.2384 18.6866 15.7427C18.6866 16.2468 18.2665 16.667 17.7622 16.667H10.0317C9.52766 16.667 9.10742 16.2469 9.10742 15.7427C9.10742 15.2386 9.52767 14.8184 10.0317 14.8184Z" :fill="fill" />
    <path d="M10.0317 9.94436H17.7622C18.2663 9.94436 18.6866 10.3644 18.6866 10.8687C18.6866 11.3727 18.2665 11.793 17.7622 11.793H10.0317C9.52766 11.793 9.10742 11.3729 9.10742 10.8687C9.10742 10.3644 9.52767 9.94436 10.0317 9.94436Z" :fill="fill" />
    <path d="M5.54149 19.7207H6.52191C7.02597 19.7207 7.44621 20.1408 7.44621 20.645C7.44621 21.1491 7.02615 21.5693 6.52191 21.5693H5.54149C5.03742 21.5693 4.61719 21.1493 4.61719 20.645C4.61719 20.141 5.03724 19.7207 5.54149 19.7207Z" :fill="fill" />
    <path d="M5.54149 14.8184H6.52191C7.02597 14.8184 7.44621 15.2384 7.44621 15.7427C7.44621 16.2468 7.02615 16.667 6.52191 16.667H5.54149C5.03742 16.667 4.61719 16.2469 4.61719 15.7427C4.61719 15.2386 5.03724 14.8184 5.54149 14.8184Z" :fill="fill" />
    <path d="M5.54149 9.94436H6.52191C7.02597 9.94436 7.44621 10.3644 7.44621 10.8687C7.44621 11.3727 7.02615 11.793 6.52191 11.793H5.54149C5.03742 11.793 4.61719 11.3729 4.61719 10.8687C4.61719 10.3644 5.03724 9.94436 5.54149 9.94436Z" :fill="fill" />
    <path d="M10.0317 5.04104H17.7622C18.2663 5.04104 18.6866 5.4611 18.6866 5.96535C18.6866 6.46941 18.2665 6.88965 17.7622 6.88965H10.0317C9.52766 6.88965 9.10742 6.46959 9.10742 5.96535C9.10742 5.4611 9.52767 5.04104 10.0317 5.04104Z" :fill="fill" />
    <path d="M5.54149 5.04104H6.52191C7.02597 5.04104 7.44621 5.4611 7.44621 5.96535C7.44621 6.46941 7.02615 6.88965 6.52191 6.88965H5.54149C5.03742 6.88965 4.61719 6.46959 4.61719 5.96535C4.61719 5.4611 5.03724 5.04104 5.54149 5.04104Z" :fill="fill" />
  </svg>
</template>

<script>
export default {
  name: "AssignIcon",
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 27
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 24 27'    
    },
  }
}
</script>
