<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75002 26.2512V25.0847H4.66654V23.3347H2.91653V22.1677H4.66654V20.4177H1.75002V19.2511H4.66654V17.5011H2.91653V16.3346H4.66654V14.5846H1.75002V13.4176H4.66654V11.6676H2.91653V10.5011H4.66654V8.75105H1.75002V7.58454H4.66654V5.83453H2.91653V4.66752H4.66654V2.9175H1.75002V1.75099H4.66654V0.000976562H0V28.0012H4.66654V26.2512H1.75002Z"
      :fill="fill"
    />
    <path
      d="M22.1652 12.251H12.832V18.6673H14.582V14.0007H22.1652V12.251Z"
      :fill="fill"
    />
    <path
      d="M24.5 4.66654H20.5417L23.5588 1.64945L21.9066 0L17.2401 4.66654H7V23.333H18.6663V28H24.4999C26.4323 28 27.9999 26.4334 27.9999 24.5V8.16607C27.9999 6.23268 26.4323 4.66604 24.4999 4.66604L24.5 4.66654ZM24.5 25.6667H20.9999V23.3337H24.5C25.1435 23.3337 25.6665 23.8552 25.6665 24.5002C25.6665 25.1452 25.1435 25.6667 24.5 25.6667ZM25.6665 21.2143C25.2998 21.0844 24.9111 21.0002 24.5 21.0002H9.33358V7.00007H14.9071L13.6586 8.24933L12.8339 10.724L15.3085 9.89903L18.2075 7.00007H24.5C25.1436 7.00007 25.6665 7.52254 25.6665 8.16658L25.6665 21.2143Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "TechPackIcon",
  props: {
    width: {
      type: [Number, String],
      default: 28
    },
    height: {
      type: [Number, String],
      default: 28
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 28 28'    
    },
  }
}
</script>
