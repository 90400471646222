<template>
    <svg
      :width="width"
      :height="height"
      :viewBox="viewBox"
      fill="none"
      overflow="visible"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M22.7868 0.23644H12.8288C5.89505 0.23644 0.289062 5.87931 0.289062 12.7762C0.289062 16.8333 2.24378 20.595 5.48942 22.9554C5.00988 23.8407 4.53057 24.6521 4.05104 25.3159C3.31348 26.3117 3.31348 27.6395 4.01425 28.6721C4.56737 29.4835 5.45263 29.9261 6.37467 29.9261C6.63297 29.9261 6.891 29.8893 7.14931 29.8154C11.1325 28.746 14.7469 26.8281 17.218 25.3526H22.824C29.7577 25.3526 35.3637 19.7098 35.3637 12.8129C35.3635 5.91674 29.6838 0.236328 22.7871 0.236328L22.7868 0.23644ZM22.7868 22.3285H16.7382C16.4431 22.3285 16.1851 22.4023 15.9268 22.5497C14.1196 23.693 10.5052 25.7215 6.5958 26.828C7.33336 25.7586 8.07093 24.5044 8.77174 23.0661C9.10362 22.3652 8.84558 21.517 8.14479 21.1113C5.12037 19.4147 3.2763 16.2428 3.2763 12.7761C3.2763 7.50219 7.55462 3.2238 12.8286 3.2238H22.7497C28.0237 3.2238 32.302 7.50212 32.302 12.7761C32.302 18.0501 28.024 22.3284 22.7866 22.3284L22.7868 22.3285Z" fill="#473068"/>
    <path d="M22.1999 8.83123L16.3358 14.6953L13.4591 11.8186C12.8689 11.2284 11.91 11.2284 11.3567 11.8186C10.7665 12.4087 10.7665 13.3676 11.3567 13.9209L15.3031 17.8674C15.5982 18.1625 15.9669 18.3099 16.3726 18.3099C16.7783 18.3099 17.1472 18.1625 17.442 17.8674L24.3757 10.9337C24.9659 10.3435 24.9659 9.38465 24.3757 8.83129C23.712 8.24113 22.7529 8.24113 22.1998 8.83129L22.1999 8.83123Z" fill="#473068"/>
    </svg>
  </template>
  
  <script>
  export default {
    name: "Success",
    props: {
      width: {
        type: [Number, String],
        default: 50
      },
      height: {
        type: [Number, String],
        default: 27      
      },
      fill: {
        type: String,
        default: '#70D0CE'
      },
      viewBox: {
        type: [Number, String],
        default: '0 0 50 27'    
      },
    }
  }
  </script>
  
  