<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.828282 14.8682C0.903016 14.885 0.980183 14.8954 1.05978 14.8954H1.26231L0 13.6328V13.8347C0 13.9152 0.0103917 13.9921 0.0278595 14.0673L0.828282 14.8682Z" :fill="fill"/>
    <path d="M11.8223 25.6581C11.8223 25.7379 11.8331 25.8153 11.8499 25.8896L12.6516 26.6913C12.7261 26.7081 12.8035 26.7191 12.8827 26.7191H13.0857L11.8225 25.4551L11.8223 25.6581Z" :fill="fill"/>
    <path d="M11.823 24.7534L13.787 26.7184H13.7943C13.9133 26.7184 14.0258 26.6936 14.1322 26.6571L11.8223 24.3477L11.823 24.7534Z" :fill="fill"/>
    <path d="M11.8223 22.5389L14.8539 25.5707V25.1636L11.8223 22.1318V22.5389Z" :fill="fill"/>
    <path d="M11.8225 23.523V23.6464L14.5611 26.3855C14.6286 26.3141 14.6832 26.2336 14.7287 26.1467L11.8223 23.2402L11.8225 23.523Z" :fill="fill"/>
    <path d="M0.332031 12.1563L3.07112 14.8954H3.47774L0.570649 11.9883C0.48353 12.0338 0.403052 12.0893 0.332077 12.1563L0.332031 12.1563Z" :fill="fill"/>
    <path d="M4.58602 14.895L1.5531 11.8623H1.14648L4.17963 14.895H4.58602Z" :fill="fill"/>
    <path d="M2.37002 14.8954L0.0599199 12.5859C0.0234374 12.6927 0 12.8051 0 12.9238V12.9313L1.96363 14.8949L2.37002 14.8954Z" :fill="fill"/>
    <path d="M8.90234 11.8633L14.8542 17.8151V17.4098L9.30819 11.8634L8.90234 11.8633Z" :fill="fill"/>
    <path d="M17.8307 14.8952L11.8223 8.88672V9.29311L17.4242 14.8951L17.8307 14.8952Z" :fill="fill"/>
    <path d="M11.1172 11.8633L14.8535 15.6V15.2429L11.4741 11.8633L11.1172 11.8633Z" :fill="fill"/>
    <path d="M11.8223 11.5099L15.2085 14.8962H15.6145L11.8223 11.1035L11.8223 11.5099Z" :fill="fill"/>
    <path d="M11.8223 10.4019L16.3163 14.8959H16.7231L11.8223 9.99512L11.8223 10.4019Z" :fill="fill"/>
    <path d="M10.0098 11.8633L14.854 16.7075V16.3007L10.4162 11.8633L10.0098 11.8633Z" :fill="fill"/>
    <path d="M26.3923 14.5959L23.6603 11.8633H23.2539L26.1553 14.7649C26.2417 14.7182 26.3224 14.6625 26.3923 14.596L26.3923 14.5959Z" :fill="fill"/>
    <path d="M24.3613 11.8633L26.6599 14.1617C26.6933 14.0585 26.717 13.9492 26.717 13.8351V13.8124L24.7679 11.8633L24.3613 11.8633Z" :fill="fill"/>
    <path d="M22.5392 11.8633H22.1465L25.1796 14.896H25.5862L22.5533 11.8633H22.5392Z" :fill="fill"/>
    <path d="M25.9096 11.8976C25.8278 11.8772 25.7442 11.8633 25.6558 11.8633H25.4688L26.7165 13.111V12.9248C26.7165 12.8364 26.7028 12.7521 26.6826 12.6697L25.9096 11.8976Z" :fill="fill"/>
    <path d="M14.8542 1.94915L12.9064 0.000976562H12.8832C12.7687 0.000976562 12.6608 0.0237503 12.5566 0.0578006L14.8542 2.35556L14.8542 1.94915Z" :fill="fill"/>
    <path d="M14.8531 3.18477V3.05631L12.1229 0.325195C12.0548 0.394846 12 0.475992 11.9531 0.562001L14.8531 3.46224V3.18477Z" :fill="fill"/>
    <path d="M14.8539 4.18004V4.16345L11.8223 1.13184V1.53867L14.8539 4.57046V4.18004Z" :fill="fill"/>
    <path d="M14.854 1.06063C14.854 0.972405 14.8396 0.8875 14.8195 0.80525L14.0492 0.0349351C13.9667 0.0143722 13.8831 0 13.7947 0H13.6074L14.854 1.24703L14.854 1.06063Z" :fill="fill"/>
    <path d="M25.6569 18.4462H21.4776V14.8961H21.1538L18.1209 11.8632H17.7145L20.7477 14.8961H20.0457L17.0134 11.8632H16.6068L19.6397 14.8961H18.9386L15.9061 11.8632H15.4995L18.5324 14.8961H18.4464V18.4462H14.7836L11.8221 15.484V15.89L14.3776 18.4455H13.676L11.822 16.5911V16.9977L13.2689 18.4446H12.5678L11.8215 17.6983V18.105L12.1609 18.4441L8.27057 18.4444V14.8942H7.90994L4.87679 11.8613H4.47018L7.5031 14.8942H7.36181V19.3531H18.4464V20.5671H7.36181V25.6559C7.36181 25.7368 7.29172 25.8078 7.21057 25.8078H6.29915C6.21866 25.8078 6.14791 25.7364 6.14791 25.6559V20.5685H1.05978C0.978409 20.5685 0.908979 20.499 0.908979 20.4172V19.5058C0.908979 19.4244 0.979071 19.3539 1.05978 19.3539H6.14791V14.8957H5.69376L2.66084 11.8628H2.25444L5.28736 14.8957H5.2396V18.4458L1.05978 18.446C0.476261 18.446 0 18.9241 0 19.5076V20.4183C0 21.0016 0.476046 21.4783 1.05978 21.4783H5.23938L5.2396 25.6579C5.2396 26.2412 5.71697 26.7194 6.29938 26.7194H7.21081C7.79473 26.7194 8.27099 26.2412 8.27099 25.6579L8.27077 21.4779H11.8695L14.8536 24.4619V24.0553L12.2768 21.4779H12.978L14.8536 23.3538V22.9478L13.3837 21.4779H14.0853L14.8536 22.2455V21.8398L14.4917 21.4779H18.4464V25.6575C18.4464 26.2408 18.9231 26.7188 19.5057 26.7188H20.4171C21.0007 26.7188 21.478 26.2407 21.478 25.6575L21.4778 21.4779H25.6571C26.2398 21.4779 26.7178 21.0012 26.7178 20.4179V19.5064C26.7164 18.9238 26.2391 18.446 25.6569 18.446L25.6569 18.4462ZM25.8079 20.4183C25.8079 20.4994 25.737 20.5695 25.656 20.5695H21.4776V19.3554H25.6569C25.7374 19.3554 25.8088 19.4264 25.8088 19.5075L25.8079 20.4183Z" :fill="fill"/>
    <path d="M3.76923 11.8633H3.36328L6.39643 14.896H6.80282L3.76923 11.8633Z" :fill="fill"/>
    <path d="M7.36128 11.8628H7.79398L10.8269 14.8957H11.2329L8.19994 11.8628H8.27025L8.27047 8.27156H11.9079L14.8533 11.2171V10.8105L12.3139 8.27158H13.015L14.8529 10.1094V9.70282L13.4221 8.2716H14.1233L14.8529 9.00128V8.59532L14.5292 8.27162H18.4453V11.8628H18.8225L21.8547 14.8957H22.2613L19.2284 11.8628H19.9302L22.9627 14.8957H23.3693L20.3364 11.8628H21.0373L24.0702 14.8957H24.4766L21.4441 11.8628H21.4771V8.27162H25.6567C26.2393 8.27162 26.7173 7.79513 26.7173 7.21076V6.30001C26.7173 5.71717 26.2393 5.23938 25.6567 5.23938L21.4775 5.2396V1.06069C21.4775 0.477845 21.0001 5.59245e-05 20.4167 5.59245e-05H19.5052C18.9226 5.59245e-05 18.4459 0.477426 18.4459 1.06069V5.23983H14.8215L11.822 2.23985V2.64647L14.4151 5.23958H13.714L11.8219 3.34778V3.75373L13.3076 5.23935H12.6064L11.8222 4.4555V4.8619L12.1996 5.23933L8.27054 5.23977V1.06063C8.27054 0.477789 7.79405 0 7.21036 0H6.29893C5.71654 0 5.23915 0.47737 5.23915 1.06063V5.23977H1.05978C0.476261 5.23977 0 5.71736 0 6.3004V7.21115C0 7.7953 0.476045 8.27201 1.05978 8.27201H5.23938L5.2396 11.8628H5.57855L8.61147 14.8957H9.01787L5.98472 11.8628H6.14856L6.14834 8.27162H7.3618V11.8628L7.36128 11.8628ZM7.36128 5.2396H6.14782V1.06046C6.14782 0.979978 6.21923 0.909003 6.29906 0.909003H7.21048C7.29141 0.909003 7.36172 0.979978 7.36172 1.06046V5.2396H7.36128Z" :fill="fill"/>
    <path d="M9.71847 14.895H10.1251L7.09216 11.8623H6.68555L9.71847 14.895Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "WeavingIcon",
  props: {
    width: {
      type: [Number, String],
      default: 27
    },
    height: {
      type: [Number, String],
      default: 27      
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 27 27'    
    },
  }
}
</script>

