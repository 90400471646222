<template>
  <v-row v-if="password">
    <v-col cols="3" class="password-policy-icon">
      <password-lock />
    </v-col>
    <v-col cols="9">
      <p class="password-policy-title">
        <strong>Your password needs to:</strong>
      </p>
      <div class="password-policy__items">
        <div class="policy-item">
          <span
            :class="{ 'green-tick': havingNumber, 'red-cross': !havingNumber }"
          >
            <template v-if="havingNumber">&#10004;</template>
            <template v-else>&#10008;</template>
          </span>
          <span
            :class="{ 'green-text': havingNumber, 'red-text': !havingNumber }"
          >
            Include at least one number.
          </span>
        </div>
        <div class="policy-item">
          <span
            :class="{
              'green-tick': havingLowerCase,
              'red-cross': !havingLowerCase,
            }"
          >
            <template v-if="havingLowerCase">&#10004;</template>
            <template v-else>&#10008;</template>
          </span>
          <span
            :class="{
              'green-text': havingLowerCase,
              'red-text': !havingLowerCase,
            }"
          >
            Include at least one lowercase character.
          </span>
        </div>
        <div class="policy-item">
          <span
            :class="{
              'green-tick': havingUpperCase,
              'red-cross': !havingUpperCase,
            }"
          >
            <template v-if="havingUpperCase">&#10004;</template>
            <template v-else>&#10008;</template>
          </span>
          <span
            :class="{
              'green-text': havingUpperCase,
              'red-text': !havingUpperCase,
            }"
          >
            Include at least one uppercase character.
          </span>
        </div>
        <div class="policy-item">
          <span
            :class="{ 'green-tick': havingLength, 'red-cross': !havingLength }"
          >
            <template v-if="havingLength">&#10004;</template>
            <template v-else>&#10008;</template>
          </span>
          <span
            :class="{ 'green-text': havingLength, 'red-text': !havingLength }"
          >
            Be at least 6 characters long.
          </span>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PasswordPolicy",
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  computed: {
    havingNumber() {
      return /\d/.test(this.password);
    },
    havingLowerCase() {
      return /[a-z]/.test(this.password);
    },
    havingUpperCase() {
      return /[A-Z]/.test(this.password);
    },
    havingLength() {
      return this.password.length >= 6;
    },
  },
};
</script>

<style lang="scss" scoped>
.password-policy-title {
  text-align: left;
  margin: 15px 0px 10px -15px;
  font-style: normal !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: $tableBorder;
}

.password-policy__items {
  margin-bottom: 15px;
}

.policy-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: $tableBorder;
}

.password-policy-icon {
  justify-content: center;
  display: flex;
  align-items: center;
}

.green-tick {
  color: #00b894;
}

.red-cross {
  color: #d63031;
}

.green-text {
  color: #00b894;
}

.red-text {
  color: #d63031;
}
</style>
