<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4342 0.791992H8.09074C6.67528 0.791992 5.52469 1.97946 5.52469 3.44029V6.08859H2.95863C1.54317 6.08859 0.392578 7.27606 0.392578 8.73689V25.686C0.392578 27.1468 1.54317 28.3343 2.95863 28.3343H16.3021C17.7176 28.3343 18.8682 27.1468 18.8682 25.686V23.0377H21.4342C22.8497 23.0377 24.0003 21.8502 24.0003 20.3894V3.44029C24.0003 1.98067 22.8497 0.791992 21.4342 0.791992ZM16.8153 25.686C16.8153 25.9773 16.5844 26.2157 16.3021 26.2157H2.95863C2.67637 26.2157 2.44542 25.9773 2.44542 25.686V8.73689C2.44542 8.44558 2.67637 8.20723 2.95863 8.20723H5.52469V20.3894C5.52469 21.8502 6.67528 23.0377 8.09074 23.0377H16.8153V25.686ZM21.9474 20.3894C21.9474 20.6807 21.7165 20.9191 21.4342 20.9191H17.8468H17.8418H17.8367H8.09098C7.80872 20.9191 7.57777 20.6807 7.57777 20.3894V3.44029C7.57777 3.14898 7.80872 2.91063 8.09098 2.91063H21.4345C21.7167 2.91063 21.9477 3.14898 21.9477 3.44029L21.9474 20.3894Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "DuplicateIcon",
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 29
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 24 29'    
    },
  }
}
</script>