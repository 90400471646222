import auth from "../auth";

export default {
    getIsSuperAdmin() {
        return auth.user.role['name'] === "super_admin";
    },

    getBrandAccountManager(){
        return auth.user.role['name'] === "brand_account_manager"
    },
}
