<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M132.909 85.8104C132.772 86.1837 132.49 86.4841 132.134 86.6298L126.068 89.1606V109.616C126.068 110.163 125.74 110.682 125.212 110.9L94.5235 123.682C94.3595 123.754 94.1773 123.8 93.9952 123.8C93.813 123.8 93.6308 123.754 93.4668 123.682L62.7788 110.9C62.2504 110.682 61.9316 110.163 61.9316 109.616V89.1606L55.8651 86.6298C55.5007 86.4841 55.2183 86.1837 55.0817 85.8104C54.9451 85.4372 54.9815 85.0275 55.1637 84.6816L62.0865 71.9001L62.1138 71.8819C62.2687 71.6179 62.4873 71.4085 62.7788 71.281L79.521 64.3167C80.0949 64.08 80.7507 64.253 81.1242 64.7082L83.0553 64.6445C82.8185 64.2621 82.7911 63.7705 82.9824 63.3517C83.201 62.851 83.702 62.5324 84.2486 62.5324H88.4296V47.1928C88.4296 46.4281 89.0581 45.7999 89.8233 45.7999H98.1944C98.9595 45.7999 99.5881 46.4281 99.5881 47.1928V62.5324H103.778C104.316 62.5324 104.817 62.851 105.044 63.3517C105.263 63.8524 105.181 64.426 104.817 64.8447L106.757 64.8903C107.112 64.3076 107.841 64.0527 108.488 64.3258L125.23 71.2992C125.521 71.4085 125.74 71.6361 125.895 71.9001C125.913 71.9092 125.922 71.9092 125.922 71.9092L132.845 84.6998L132.827 84.6816C133.009 85.0275 133.054 85.4372 132.909 85.8104Z"
      :fill="background"
    />
    <path
      d="M98.1948 107.377V115.725C98.1948 115.907 98.2222 116.089 98.3041 116.253C98.5227 116.781 99.042 117.1 99.5885 117.1C99.7707 117.1 99.9529 117.054 100.135 116.99L107.112 114.077C107.641 113.859 107.96 113.34 107.96 112.794V104.446C107.96 104.264 107.932 104.081 107.85 103.918C107.559 103.208 106.739 102.871 106.019 103.162L99.042 106.075C98.5136 106.294 98.1948 106.813 98.1948 107.359V107.377ZM105.172 111.892L100.991 113.631V108.296L105.172 106.558V111.892Z"
      :fill="fill"
    />
    <path
      d="M132.845 84.6998L125.923 71.9183C125.923 71.9183 125.904 71.9183 125.895 71.9001C125.74 71.6361 125.522 71.4085 125.221 71.2992L108.479 64.3258C107.768 64.0163 106.949 64.3714 106.648 65.0814C106.357 65.7915 106.694 66.6109 107.404 66.9113L121.049 72.6011L93.9865 83.8623L66.942 72.5828L80.5873 66.8931C81.2978 66.6018 81.6348 65.7733 81.3433 65.0632C81.0518 64.3532 80.2229 64.0163 79.5124 64.3076L62.7793 71.281C62.4878 71.4085 62.26 71.6179 62.1143 71.8819C62.1143 71.8819 62.0961 71.8819 62.087 71.9001L55.1642 84.6816C54.982 85.0275 54.9364 85.4372 55.0822 85.8104C55.2188 86.1837 55.5012 86.4841 55.8656 86.6298L61.9321 89.1515V109.616C61.9321 110.163 62.2509 110.672 62.7793 110.9L93.4673 123.682C93.6313 123.754 93.8135 123.791 93.9957 123.791C94.1778 123.791 94.36 123.745 94.524 123.682L125.212 110.9C125.74 110.682 126.059 110.163 126.059 109.616V89.1515L132.126 86.6298C132.49 86.475 132.763 86.1837 132.909 85.8104C133.046 85.4372 133.009 85.0275 132.827 84.6816L132.845 84.6998ZM63.9543 74.349L92.0554 86.0471L86.4625 96.3616L58.3614 84.6634L63.9361 74.349H63.9543ZM86.5627 99.4113C86.7267 99.4841 86.9271 99.5205 87.0911 99.5205C87.5921 99.5205 88.0657 99.2565 88.3208 98.7922L92.6202 90.863V120.331L64.7286 108.715V90.3258L86.5536 99.4113L86.5719 99.3931L86.5627 99.4113ZM123.317 108.706L95.4166 120.322V90.8539L99.7161 98.7831C99.9711 99.2474 100.445 99.5114 100.946 99.5114C101.128 99.5114 101.31 99.4841 101.474 99.4022L123.299 90.3167V108.706L123.317 108.724V108.706ZM101.574 96.3525L95.9814 86.038L124.083 74.3398L129.657 84.6543L101.565 96.3525H101.574Z"
      :fill="fill"
    />
    <path
      d="M92.9663 75.9967C93.2305 76.3062 93.604 76.4701 94.0139 76.4701C94.4238 76.4701 94.7972 76.3062 95.0614 75.9967L104.826 64.8447C105.191 64.426 105.273 63.8524 105.045 63.3517C104.826 62.851 104.316 62.5324 103.779 62.5324H99.5977V47.1928C99.5886 46.4281 98.96 45.7999 98.1949 45.7999H89.8238C89.0586 45.7999 88.4301 46.4281 88.4301 47.1928V62.5233H84.2491C83.7025 62.5233 83.2015 62.8419 82.9829 63.3426C82.7643 63.8433 82.8463 64.426 83.2107 64.8356L92.9755 75.9876L92.9663 75.9967ZM89.8238 65.3181C90.5889 65.3181 91.2174 64.69 91.2174 63.9253V48.5947H96.7921V63.9253C96.7921 64.69 97.4206 65.3181 98.1858 65.3181H100.7L94.0048 72.9561L87.3097 65.3181H89.8238Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "Carton",
  props: {
    width: {
      type: String,
      default: "188",
    },
    height: {
      type: String,
      default: "168",
    },
    fill: {
      type: String,
      default: "#442D64",
    },
    background: {
      type: String,
      default: "#E9E7ED",
    },
    viewBox: {
      type: String,
      default: "0 0 188 168",
    },
  },
};
</script>
