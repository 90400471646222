<template>
  <svg width="89" height="118" viewBox="0 0 89 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1891_809)">
    <rect x="10" width="69" height="93" rx="5" fill="white"/>
    </g>
    <rect x="24" y="43" width="40" height="4" rx="2" fill="#A5E8E6"/>
    <rect x="17" y="43" width="4" height="4" rx="2" fill="#A5E8E6"/>
    <rect x="17" y="17" width="47" height="4" rx="2" fill="#9D70DE"/>
    <rect x="17" y="30" width="22" height="4" rx="2" fill="#9D70DE"/>
    <rect x="24" y="56" width="31" height="4" rx="2" fill="#E9D7FC"/>
    <rect x="17" y="56" width="4" height="4" rx="2" fill="#E9D7FC"/>
    <rect x="17" y="69" width="4" height="4" rx="2" fill="#A5E8E6"/>
    <path d="M24 71C24 69.8954 24.8954 69 26 69H64V69C64 71.2091 62.2091 73 60 73H26C24.8954 73 24 72.1046 24 71V71Z" fill="#E9D7FC"/>
    <rect x="58" y="66" width="13" height="10" fill="white"/>
    <path d="M78.7132 53.0409C78.3498 52.5081 77.9139 51.9993 77.4295 51.5149C76.9936 51.1032 76.3637 50.6188 75.9278 50.328C75.2011 49.8193 74.208 49.9163 73.6025 50.5218L58.1734 65.9266C58.125 65.9751 58.0766 66.0478 58.0523 66.1204L56.0421 71.8367C55.9452 72.1516 56.0179 72.4907 56.2359 72.7086C56.4055 72.8783 56.5991 72.9509 56.8172 72.9509C56.914 72.9509 57.011 72.9268 57.1078 72.9024L62.7998 70.892C62.8725 70.8679 62.9451 70.8194 62.9936 70.7709L78.4469 55.3177C79.0764 54.7123 79.1734 53.7434 78.7131 53.041L78.7132 53.0409ZM58.997 66.6291C60.3049 67.5253 61.4192 68.6395 62.3153 69.9474L58.997 71.11C58.8759 70.8677 58.7306 70.6256 58.5126 70.4076C58.2947 70.1897 58.0524 70.0444 57.8103 69.9233L58.997 66.6291Z" fill="#9D70DE"/>
    <path d="M52 29L57.2929 34.2929C57.6834 34.6834 58.3166 34.6834 58.7071 34.2929L64 29" stroke="#A5E8E6"/>
    <defs>
    <filter id="filter0_d_1891_809" x="0" y="0" width="89" height="118" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="15"/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.867654 0 0 0 0 0.790052 0 0 0 0 0.9625 0 0 0 1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1891_809"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1891_809" result="shape"/>
    </filter>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "CreateStyleIcon",
  props: {
    boxShadow: {
      type: String,
      default: 'none'
    }
  }
}
</script>
