<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.89867 1.25495C7.89867 1.5177 7.69222 1.72415 7.42947 1.72415H1.724C1.46125 1.72415 1.25481 1.9306 1.25481 2.19335V10.6389C1.25481 10.9017 1.46125 11.1081 1.724 11.1081H8.25526C8.51801 11.1081 8.72446 10.9017 8.72446 10.6389V4.72713C8.72446 4.46438 8.93091 4.25794 9.19366 4.25794C9.45641 4.25794 9.66286 4.46438 9.66286 4.72713V10.6389C9.66286 11.4084 9.02475 12.0465 8.25526 12.0465H1.724C0.954518 12.0465 0.316406 11.4084 0.316406 10.6389V2.19335C0.316406 1.42386 0.954518 0.785751 1.724 0.785751H7.42947C7.69222 0.785751 7.89867 0.992199 7.89867 1.25495ZM2.26828 4.07015H4.68945C4.9522 4.07015 5.15865 3.8637 5.15865 3.60095C5.15865 3.3382 4.9522 3.13175 4.68945 3.13175H2.26828C2.00552 3.13175 1.79908 3.3382 1.79908 3.60095C1.79908 3.8637 2.00552 4.07015 2.26828 4.07015ZM2.26828 6.09709H4.68945C4.9522 6.09709 5.15865 5.89064 5.15865 5.62789C5.15865 5.36514 4.9522 5.15869 4.68945 5.15869H2.26828C2.00552 5.15869 1.79908 5.36514 1.79908 5.62789C1.79908 5.89064 2.00552 6.09709 2.26828 6.09709ZM7.78606 7.05426H2.1932C1.93045 7.05426 1.724 7.2607 1.724 7.52346C1.724 7.78621 1.93045 7.99266 2.1932 7.99266H7.78606C8.04881 7.99266 8.25526 7.78621 8.25526 7.52346C8.25526 7.2607 8.04881 7.05426 7.78606 7.05426ZM7.78606 8.91229H2.1932C1.93045 8.91229 1.724 9.11873 1.724 9.38149C1.724 9.64424 1.93045 9.85069 2.1932 9.85069H7.78606C8.04881 9.85069 8.25526 9.64424 8.25526 9.38149C8.25526 9.11873 8.04881 8.91229 7.78606 8.91229ZM10.4698 0.917127C10.2821 0.729447 9.98181 0.729447 9.8129 0.917127L6.45354 4.25783C6.26586 4.44551 6.26586 4.7458 6.45354 4.91471C6.54738 5.00855 6.65998 5.04608 6.79136 5.04608C6.90397 5.04608 7.03534 5.00855 7.12918 4.91471L10.4699 1.59267C10.6576 1.40499 10.6576 1.1047 10.4699 0.91702L10.4698 0.917127Z" :fill="fill"/>
</svg>
</template>

<script>
export default {
  name: "AlarmIcon",
  props: {
    width: {
      type: [Number, String],
      default: 11
    },
    height: {
      type: [Number, String],
      default: 13      
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 11 13'    
    },
  }
}
</script>