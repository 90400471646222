<template>
  <path 
    d="M12.9512 4.99254L12.4425 4.77325L4.6795 1.38737L3.59181 0.931246L3.50409 0.878615L2.24974 0.325998H2.17956C2.07408 0.270103 1.95683 0.24004 1.83747 0.238281C1.60483 0.238281 1.38172 0.330697 1.21721 0.495198C1.05271 0.6597 0.960297 0.882811 0.960297 1.11545C0.955943 1.15627 0.955943 1.19744 0.960297 1.23826C0.971433 1.31649 0.992052 1.39307 1.0217 1.46632L1.78484 3.95748L1.92518 4.43115L2.29359 5.59779L3.89004 10.8608L4.13565 11.6678C4.18871 11.8575 4.3041 12.0239 4.46322 12.14C4.62235 12.2562 4.81594 12.3154 5.01282 12.3081C5.18072 12.3143 5.34686 12.2721 5.49147 12.1866C5.63609 12.1011 5.7531 11.9758 5.82859 11.8257L7.66188 8.2907C7.7226 8.17954 7.8052 8.08182 7.9047 8.00343C8.00419 7.92504 8.11852 7.8676 8.24081 7.83457L12.8021 6.67671C12.9908 6.64203 13.1629 6.54645 13.2921 6.4046C13.4213 6.26275 13.5004 6.08246 13.5173 5.89135C13.5342 5.70024 13.488 5.50886 13.3858 5.34652C13.2835 5.18418 13.1309 5.05984 12.9512 4.99254ZM7.68819 5.79954C7.27419 5.90497 6.8873 6.09712 6.55312 6.36326C6.21893 6.6294 5.94507 6.96346 5.74964 7.34336L5.34615 8.19421L3.82864 3.33469L8.85483 5.5013L7.68819 5.79954Z" :fill="fill"
  />
</template>

<script>
export default {
  name: "StyleFile",
  props: {
    fill: {
      type: String,
      default: '#70D0CE'
    }
  }
}
</script>