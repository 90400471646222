var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(`
    display: flex;
    width: ${_vm.width};
    height: ${_vm.height};
    border: ${_vm.border} solid ${_vm.borderColor};
    border-radius: 50%;
    background-color: ${_vm.fill};
    align-items: center;
    justify-content: center;
    box-shadow: 0px ${(7.66234 * parseFloat(_vm.width)) / 69}px ${
    (13.7922 * parseFloat(_vm.width)) / 69
  }px ${_vm.boxShadowColor};
  `)},[_vm._t("default",null,{"fill":_vm.iconFill})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }