import { render, staticRenderFns } from "./EllipseIcon.vue?vue&type=template&id=f7915a84&v-slot=%7B%20fill%20%7D"
import script from "./EllipseIcon.vue?vue&type=script&lang=js"
export * from "./EllipseIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports