<template>
  <div :class="`container ${variant}`" @click="click">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ButtonCard",
  props: {
    variant: {
      type: String,
      default: "primary-theme",
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;
  width: 455px;
  height: 107px;
  border-radius: 8px;
  padding: 0px;
  transition-duration: 0.3s;
  cursor: pointer;
}
.primary-theme {
  background: #faf7ff;
  border: 1px solid #473068;
  font-weight: 600;
  font-size: 1.5rem;
  color: #473068;
}
.danger-theme {
  background: #fff2f0;
  border: 1px solid #ea7a66;
  font-weight: 600;
  font-size: 1.5rem;
  color: #473068;
}
.container:not(:active):hover {
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.14),
    0 1px 2px 1px rgba(0, 0, 0, 0.14), 0 1px 2px 1px rgba(0, 0, 0, 0.14),
    0 1px 2px 1px rgba(0, 0, 0, 0.14);
}
.container:after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  width: 455px;
  height: 107px;
  border-radius: 8px;
  padding: 0px;
  opacity: 0;
  transition: all 0.4s;
}
.container:active:after {
  background: rgba(0, 0, 0, 0.14);
  opacity: 0.5;
  transition: 0s;
}
</style>
