<template>
    <svg
      :width="width"
      :height="height"
      :viewBox="viewBox"
      fill="none"
      overflow="visible"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M17.5876 0.566406C8.08194 0.566406 0.40625 8.29302 0.40625 17.7478C0.40625 27.2534 8.13286 34.9291 17.5876 34.9291C27.0933 34.9291 34.7689 27.2025 34.7689 17.7478C34.7689 8.2421 27.0931 0.566406 17.5876 0.566406ZM17.5876 32.4891C9.45423 32.4891 2.8462 25.8808 2.8462 17.7478C2.8462 9.61439 9.45451 3.00636 17.5876 3.00636C25.721 3.00636 32.329 9.61466 32.329 17.7478C32.329 25.8811 25.7211 32.4891 17.5876 32.4891Z" fill="#473068"/>
    <path d="M17.5835 25.5226C17.1768 25.5226 16.8212 25.3701 16.5669 25.1159C16.3638 24.8617 16.2109 24.5567 16.2109 24.15C16.2109 23.794 16.3634 23.4384 16.6176 23.1841C17.1261 22.6756 18.0409 22.6756 18.5494 23.1841C18.8036 23.4383 18.9561 23.794 18.9561 24.15C18.9561 24.5567 18.8036 24.8616 18.5494 25.1159C18.2951 25.4208 17.9395 25.5226 17.5835 25.5226H17.5835Z" fill="#473068"/>
    <path d="M17.5835 19.9356C16.8212 19.9356 16.2109 19.3257 16.2109 18.5631V11.3452C16.2109 10.5829 16.8208 9.97266 17.5835 9.97266C18.3458 9.97266 18.9561 10.5825 18.9561 11.3452V18.5634C18.9561 19.3258 18.3458 19.9356 17.5835 19.9356Z" fill="#473068"/>
    </svg>
  </template>
  
  <script>
  export default {
    name: "Danger",
    props: {
      width: {
        type: [Number, String],
        default: 36
      },
      height: {
        type: [Number, String],
        default: 30      
      },
      fill: {
        type: String,
        default: '#70D0CE'
      },
      viewBox: {
        type: [Number, String],
        default: '3 3 36 30'    
      },
    }
  }
  </script>
  
  