var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',{staticClass:"customizable-table"},[(_vm.title)?_c('tr',[_c('td',{staticClass:"title",attrs:{"colspan":_vm.headers.length}},[_vm._v(_vm._s(_vm.title))]),_c('td',{staticClass:"actions-td"})]):_vm._e(),(_vm.displayHeaders)?_c('tr',[_vm._l((_vm.headers),function(header,index){return _c('td',{key:index,staticClass:"subtitle"},[_vm._v(" "+_vm._s(header.name)+" ")])}),_c('td',{staticClass:"actions-td"})],2):_vm._e(),(_vm.subTitle)?_c('tr',[_c('td',{staticClass:"subtitle",attrs:{"colspan":_vm.headers.length}},[_vm._v(_vm._s(_vm.subTitle))])]):_vm._e(),_vm._l((_vm.data),function(record,index){return _c('tr',{key:index},[_vm._l((record),function(cell,cellNo){return _c('td',{key:cellNo,class:_vm.headers[cellNo].type === 'disabled' ? 'disabled-td' : 'input-td'},[(_vm.headers[cellNo].type === 'suppliers')?_c('div',{staticStyle:{"max-width":"100%","margin-top":"10px"}},[_c('v-autocomplete',{staticClass:"dropdown-list",attrs:{"dense":"","items":JSON.parse(JSON.stringify(_vm.suppliers)),"item-text":"name","item-value":"id","label":"Select Suppliers","multiple":"","return-object":"","menu-props":{
            closeOnContentClick: !_vm.suppliers || !_vm.suppliers.length,
          },"disabled":!_vm.isAccountVerified},on:{"blur":function($event){return _vm.editData(index, cellNo, _vm.selectedSuppliers[index])}},scopedSlots:_vm._u([{key:"selection",fn:function({}){return undefined}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"no-suppliers-text",on:{"click":_vm.changeTab}},[_vm._v(" No suppliers. Click here to add. ")])]},proxy:true}],null,true),model:{value:(_vm.selectedSuppliers[index]),callback:function ($$v) {_vm.$set(_vm.selectedSuppliers, index, $$v)},expression:"selectedSuppliers[index]"}}),(!_vm.isAccountVerified)?_c('div',{staticClass:"not-verified-text"},[_vm._v(" Your company profile needs to be verified to select a supplier or factory. ")]):_vm._e(),_c('div',[(
              _vm.selectedSuppliers[index] &&
              Array.isArray(_vm.selectedSuppliers[index])
            )?_c('table',_vm._l((_vm.selectedSuppliers[index]),function(selectedSupplier){return _c('div',{key:selectedSupplier.id},[_c('tr',[_c('td',[_c('div',{staticClass:"displaySelected"},[_vm._v(" "+_vm._s(_vm.suppliers.find( (supplier) => supplier.id == selectedSupplier.id )?.name)+" ")])])]),_c('tr',[_c('td',[_c('v-autocomplete',{staticClass:"dropdown-list mt-4",attrs:{"dense":"","items":_vm.suppliers.find(
                        (supplier) => supplier.id == selectedSupplier.id
                      )?.factories,"item-text":"name","item-value":"id","no-data-text":"No factories left to add","label":"Select Factories","multiple":"","disabled":!_vm.isAccountVerified},on:{"blur":function($event){return _vm.editData(index, cellNo, _vm.selectedSuppliers[index])}},scopedSlots:_vm._u([{key:"selection",fn:function({}){return undefined}}],null,true),model:{value:(selectedSupplier.selectedFactories),callback:function ($$v) {_vm.$set(selectedSupplier, "selectedFactories", $$v)},expression:"selectedSupplier.selectedFactories"}}),_vm._l((selectedSupplier.selectedFactories),function(selectedFactory){return _c('div',{key:selectedFactory,staticClass:"displaySelected mb-2"},[_vm._v(" "+_vm._s(_vm.suppliers .find( (supplier) => supplier.id == selectedSupplier.id ) ?.factories.find( (factory) => factory.id == selectedFactory ).name)+" ")])})],2)]),_c('br')])}),0):_vm._e()])],1):(_vm.headers[cellNo].type === 'measurement')?_c('div',[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.measurements,"item-text":"name","item-value":"id","label":""},on:{"blur":function($event){return _vm.editData(index, cellNo, _vm.selectedUnit[index])}},model:{value:(_vm.selectedUnit[index]),callback:function ($$v) {_vm.$set(_vm.selectedUnit, index, $$v)},expression:"selectedUnit[index]"}})],1):(_vm.headers[cellNo].type === 'disabled')?_c('div',[_vm._v(" "+_vm._s(cell ? _vm.roundOf(cell) : cell)+" ")]):_c('div',{staticStyle:{"display":"flex"}},[_c('input',{attrs:{"type":_vm.headers[cellNo].type || 'text',"step":_vm.headers[cellNo].step || '.01',"onkeypress":_vm.getOnKeyPressEvent(_vm.headers[cellNo]),"pattern":_vm.getPattern(_vm.headers[cellNo]),"placeholder":_vm.headers[cellNo].type === 'number' && _vm.headers[cellNo].step === '1'
              ? '0'
              : _vm.headers[cellNo].type === 'number' ||
                _vm.headers[cellNo].type === 'disabled'
              ? '0.00'
              : ''},domProps:{"value":cell},on:{"input":function($event){return _vm.onInputHandler(index, cellNo, $event)}}}),(_vm.headers[cellNo].percentage)?_c('span',[_vm._v("%")]):_vm._e()])])}),_c('td',{staticClass:"actions-td"},[_c('div',{staticClass:"tooltip mb-1 mt-1"},[_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.removeRow(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus ")])],1),_c('span',{staticClass:"tooltiptext"},[_vm._v("Remove Record")])],1)])],2)}),_c('div',{staticClass:"tooltip mb-4"},[_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"purple darken-4"},on:{"click":_vm.addRow}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),_c('span',{staticClass:"tooltiptext"},[_vm._v("Add New Record")])],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }