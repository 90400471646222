<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.72135 4.00302L8.25535 4.00002C4.91135 3.99202 2.00835 6.70902 1.98535 10V13.79C1.98535 14.58 1.88535 15.351 1.45435 16.008L1.16735 16.446C0.730354 17.11 1.20035 18 1.98535 18H16.0154C16.8004 18 17.2694 17.11 16.8334 16.446L16.5464 16.008C16.1164 15.351 16.0154 14.579 16.0154 13.789V10.001C15.9754 6.70902 13.0654 4.01102 9.72135 4.00302V4.00302Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 18C12 18.7956 11.6839 19.5587 11.1213 20.1213C10.5587 20.6839 9.79565 21 9 21C8.20435 21 7.44129 20.6839 6.87868 20.1213C6.31607 19.5587 6 18.7956 6 18" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 1C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3V4H7V3C7 2.46957 7.21071 1.96086 7.58579 1.58579C7.96086 1.21071 8.46957 1 9 1Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>  
  </svg>
</template>

<script>
export default {
  name: "NotificationBell",
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 22
    },
    stroke: {
      type: String,
      default: '#442D65'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 18 22'    
    },
  }
}
</script>