/**
 * This module handle the notifications
 */
import Vue from "vue";

export default {
  formatMessages(errors: any): string {
    let message = '<ul>';

    for (const property in errors) {
      const errorList = errors[property];

      errorList.forEach((error) => {
        message += '<li>' + error + '</li>';
      });
    }

    message += '</ul>';

    return message;
  },
  show(type: string, title: string, text: string, duration: number): void {
    Vue.notify({
      group: 'notifications',
      type: type,
      duration: duration,
      title: title,
      text: text
    });
  },
  errors(errors: any) {
    const title = 'Please fix the following errors before continuing';
    const text = this.formatMessages(errors);
    this.show('warn', title, text, 30000);
  },
  success(message: string) {
    this.show('success', message, '', 5000);
  },
  successWithAlert(message: string, text: string) {
    this.show('success', message, text, 5000);
  },
  error(message: string) {
    this.show('error', message, '', 10000);
  }
}