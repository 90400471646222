<template>
  <tbody class="customizable-table">
    <tr v-if="title">
      <td :colspan="headers.length" class="title">{{ title }}</td>
      <td class="actions-td"></td>
    </tr>
    <tr v-if="displayHeaders">
      <td v-for="(header, index) in headers" :key="index" class="subtitle">
        {{ header.name }}
      </td>
      <td class="actions-td"></td>
    </tr>
    <tr v-if="subTitle">
      <td :colspan="headers.length" class="subtitle">{{ subTitle }}</td>
    </tr>
    <tr v-for="(record, index) in data" :key="index">
      <td
        v-for="(cell, cellNo) in record"
        :key="cellNo"
        :class="
          headers[cellNo].type === 'disabled' ? 'disabled-td' : 'input-td'
        "
      >
        <div
          v-if="headers[cellNo].type === 'suppliers'"
          style="max-width: 100%; margin-top: 10px"
        >
          <v-autocomplete
            dense
            v-model="selectedSuppliers[index]"
            :items="JSON.parse(JSON.stringify(suppliers))"
            item-text="name"
            item-value="id"
            label="Select Suppliers"
            class="dropdown-list"
            multiple
            return-object
            :menu-props="{
              closeOnContentClick: !suppliers || !suppliers.length,
            }"
            :disabled="!isAccountVerified"
            @blur="editData(index, cellNo, selectedSuppliers[index])"
          >
            <template v-slot:selection="{}"></template>
            <template v-slot:no-data>
              <div class="no-suppliers-text" @click="changeTab">
                No suppliers. Click here to add.
              </div></template
            >
          </v-autocomplete>
          <div v-if="!isAccountVerified" class="not-verified-text">
            Your company profile needs to be verified to select a supplier or
            factory.
          </div>
          <div>
            <table
              v-if="
                selectedSuppliers[index] &&
                Array.isArray(selectedSuppliers[index])
              "
            >
              <div
                v-for="selectedSupplier in selectedSuppliers[index]"
                :key="selectedSupplier.id"
              >
                <tr>
                  <td>
                    <div class="displaySelected">
                      {{
                        suppliers.find(
                          (supplier) => supplier.id == selectedSupplier.id
                        )?.name
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-autocomplete
                      dense
                      :items="
                        suppliers.find(
                          (supplier) => supplier.id == selectedSupplier.id
                        )?.factories
                      "
                      item-text="name"
                      item-value="id"
                      no-data-text="No factories left to add"
                      label="Select Factories"
                      multiple
                      class="dropdown-list mt-4"
                      v-model="selectedSupplier.selectedFactories"
                      :disabled="!isAccountVerified"
                      @blur="editData(index, cellNo, selectedSuppliers[index])"
                    >
                      <template v-slot:selection="{}"></template>
                    </v-autocomplete>
                    <div
                      class="displaySelected mb-2"
                      v-for="selectedFactory in selectedSupplier.selectedFactories"
                      :key="selectedFactory"
                    >
                      {{
                        suppliers
                          .find(
                            (supplier) => supplier.id == selectedSupplier.id
                          )
                          ?.factories.find(
                            (factory) => factory.id == selectedFactory
                          ).name
                      }}
                    </div>
                  </td>
                </tr>
                <br />
              </div>
            </table>
          </div>
        </div>
        <div v-else-if="headers[cellNo].type === 'measurement'">
          <v-autocomplete
            clearable
            v-model="selectedUnit[index]"
            :items="measurements"
            item-text="name"
            item-value="id"
            label=""
            @blur="editData(index, cellNo, selectedUnit[index])"
          ></v-autocomplete>
        </div>
        <div v-else-if="headers[cellNo].type === 'disabled'">
          {{ cell ? roundOf(cell) : cell }}
        </div>
        <div v-else style="display: flex">
          <input
            :type="headers[cellNo].type || 'text'"
            :step="headers[cellNo].step || '.01'"
            :value="cell"
            :onkeypress="getOnKeyPressEvent(headers[cellNo])"
            :pattern="getPattern(headers[cellNo])"
            @input="onInputHandler(index, cellNo, $event)"
            :placeholder="
              headers[cellNo].type === 'number' && headers[cellNo].step === '1'
                ? '0'
                : headers[cellNo].type === 'number' ||
                  headers[cellNo].type === 'disabled'
                ? '0.00'
                : ''
            "
          />
          <span v-if="headers[cellNo].percentage">%</span>
        </div>
      </td>
      <td class="actions-td">
        <div class="tooltip mb-1 mt-1">
          <v-btn @click="removeRow(index)" fab dark x-small color="error">
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>
          <span class="tooltiptext">Remove Record</span>
        </div>
      </td>
    </tr>
    <div class="tooltip mb-4">
      <v-btn @click="addRow" fab dark x-small color="purple darken-4">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
      <span class="tooltiptext">Add New Record</span>
    </div>
    <slot />
  </tbody>
</template>
<script>
import { round } from "lodash";

export default {
  name: "CustomizableTableBody",
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    displayHeaders: {
      type: Boolean,
      default: true,
    },
    displayRecords: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [[]],
    },
    formula: {
      type: String,
      default: "",
    },
    setTo: {
      type: String,
      default: "",
    },
    suppliers: {
      type: Array,
      default: () => [],
    },
    measurements: {
      type: Array,
      default: () => [],
    },
    isAccountVerified: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selectedSuppliers: [],
    selectedUnit: [],
  }),
  watch: {
    data: {
      handler: function () {
        this.setSuppliersAndUnits();
      },
    },
  },
  methods: {
    changeTab() {
      this.$emit("change-tab", "suppliers");
    },
    roundOf(value) {
      return isNaN(parseFloat(value)) ? value : round(parseFloat(value), 2);
    },
    editData(index, cellNo, value) {
      const newData = this.data;
      newData[index][cellNo] =
        this.headers[cellNo].type === "number" ? this.roundOf(value) : value;
      newData[index][cellNo] =
        this.headers[cellNo].type === "suppliers"
          ? value.map((supplier) => {
              return {
                id: supplier.id,
                selectedFactories: supplier.selectedFactories || [],
              };
            })
          : value;
      if (this.formula && this.setTo) {
        const formula = this.formula.replaceAll("column", `newData[${index}]`);
        const setTo = this.setTo.replace("column", `newData[${index}]`);
        const value = eval(formula);
        if (value && !isNaN(value)) {
          eval(`${setTo} = ${value}`);
        } else {
          eval(`${setTo} = ${null}`);
        }
      }
      this.$emit("input-data", newData);
    },
    getOnKeyPressEvent(header) {
      if (header.int) {
        return "return event.charCode >= 48 && event.charCode <= 57";
      } else if (header.type === "number") {
        return "return (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46 || event.charCode === 45";
      } else {
        return "return true";
      }
    },
    getPattern(header) {
      return header.type === "number" ? "^-?[0-9]*[.]?[0-9]+" : null;
    },
    onInputHandler(index, cellNo, event) {
      if (this.headers[cellNo].type === "number") {
        const value = event.target.value
          .replace(/[^0-9.-]/g, "")
          .replace(/(?!^)-/g, "");
        this.editData(index, cellNo, value);
      } else {
        this.editData(index, cellNo, event.target.value);
      }
    },
    addRow() {
      const emptyRow = [];
      for (let index = 0; index < this.headers.length; index++) {
        emptyRow.push("");
      }
      const newData = this.data;
      newData.push(emptyRow);
      this.$emit("input-data", newData);
    },
    removeRow(index) {
      if (confirm("Are you sure you want to remove this record?")) {
        const newData = this.data;
        newData.splice(index, 1);
        this.$emit("input-data", newData);
      }
    },
    setSuppliersAndUnits() {
      for (let index = 0; index < this.data.length; index++) {
        if (this.headers.find((header) => header.type === "suppliers")) {
          const indexOfSuppliers = this.headers
            .map((header) => header.type)
            .indexOf("suppliers");
          this.selectedSuppliers[index] = this.data[index][indexOfSuppliers];
        }
      }

      for (let index = 0; index < this.data.length; index++) {
        if (this.headers.find((header) => header.type === "measurement")) {
          const indexOfMeasurement = this.headers
            .map((header) => header.type)
            .indexOf("measurement");
          this.selectedUnit[index] = this.data[index][indexOfMeasurement];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center !important;
  background-color: $primaryLight;
  font-weight: bolder;
  font-size: 14pt !important;
  color: $primary;
}

.subtitle {
  background-color: $assignButton;
}

.disabled-td {
  background-color: $light;
}

.actions-td {
  border: none !important;
  width: 40px !important;
}

.suppliersTable {
  // display: flex;
  justify-content: center;
}

.no-suppliers-text {
  font-size: 14px;
  cursor: pointer;
  color: #442d65;
}

.no-suppliers-text:hover {
  text-decoration: underline;
  color: #c6a8ec;
}

.v-btn {
  min-width: 10px;
  min-height: 10px;
  width: 20px;
  height: 20px;
}

input {
  min-width: 0px;
  width: 100%;
  height: 100%;
  border: none;
}

input:focus {
  outline: none;
}

/* Tooltip container */
.tooltip {
  float: left;
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #37474f;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  width: 120px;
  top: 80%;
  left: 50%;
  margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  transition-delay: 1s;
  visibility: visible;
}
.dropdown-list::v-deep {
  .v-label--active {
    transform: unset;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 6px;
    white-space: nowrap;
    pointer-events: none;
  }
}
.dropdown-list.v-input--is-focused::v-deep {
  .v-label--active {
    transform: translateY(-18px) scale(0.75);
    max-width: 100%;
  }
}
.displaySelected {
  overflow-wrap: anywhere;
}
.not-verified-text {
  color: #ff0000;
  text-align: left;
  margin-bottom: 10px;
}
</style>
