<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M378.668 3.86787C371.735 6.00121 353.068 11.0679 337.335 15.3345C321.601 19.6012 298.135 25.8679 285.335 29.3345C272.535 32.8012 243.735 40.6679 221.335 46.6679C155.868 64.2679 149.335 66.2679 145.868 69.4679C142.668 72.4012 142.668 72.5345 142.668 101.868C142.668 134.801 143.335 137.468 152.268 142.135C158.268 145.201 168.668 145.068 173.068 141.735C180.801 136.001 182.001 132.935 182.668 116.801C183.201 105.735 183.868 100.935 185.601 98.4012C188.135 94.4012 188.668 94.2679 228.001 84.1345C242.268 80.5345 262.401 75.3345 272.668 72.6679C282.935 70.0012 312.401 62.4012 338.001 55.7345C375.201 46.1345 386.001 43.8679 391.468 44.2679C397.735 44.8012 400.268 46.2679 423.335 62.8012C437.068 72.6679 449.601 82.4012 451.201 84.6679L454.001 88.6679L454.668 327.735C455.335 561.735 455.335 566.935 457.868 568.801C462.401 572.001 468.401 572.401 513.201 571.735C553.468 571.201 557.201 571.068 561.335 568.668C568.535 564.401 571.335 559.468 571.335 551.335C571.335 544.935 570.801 543.601 566.668 539.335C564.135 536.801 560.001 534.135 557.335 533.468C554.801 532.801 540.935 532.135 526.668 532.135L500.668 532.001L497.735 528.535L494.668 525.201V298.935C494.668 86.1345 494.535 72.4012 492.268 68.0012C490.801 64.9345 485.335 59.8679 476.401 53.3345C434.535 22.4012 409.735 4.53454 405.601 2.40121C399.201 -0.798793 393.735 -0.532126 378.668 3.86787Z"
      :fill="fill"
    />
    <path
      d="M308.002 105.2C304.002 106.533 293.735 110.4 285.335 113.733C262.268 122.933 232.802 134.4 220.002 139.333C208.668 143.6 155.735 164.267 126.668 175.6C102.002 185.2 96.1351 187.6 82.6684 192.667C59.4684 201.6 50.0017 205.467 46.9351 207.467C39.7351 212.133 40.0017 206.267 40.0017 374.4V530.267L27.4684 530.933C13.8684 531.733 8.40172 533.733 3.73505 540.133C-2.93161 548.933 0.535055 563.467 10.5351 569.067L15.7351 572H211.202H406.802L410.002 568.667L413.335 565.467V372.8C413.335 201.333 413.068 179.467 411.202 174.8C409.468 170.4 402.935 164.8 368.402 137.867C332.402 110 321.735 102.8 316.668 102.8C316.002 102.8 312.002 103.867 308.002 105.2ZM271.202 250.933C274.002 254.8 274.135 272 271.468 277.067C268.402 283.067 276.535 280.8 163.335 308C145.068 312.4 126.268 317.067 121.735 318.267C114.002 320.267 113.202 320.267 110.002 318.133C106.802 316 106.668 315.333 106.668 303.467C106.668 292.133 106.935 290.8 109.868 287.6C111.602 285.733 114.802 283.6 116.935 283.067C138.935 277.2 261.735 248.267 264.935 248.133C267.868 248 269.735 248.933 271.202 250.933ZM270.935 320.933C274.402 324.133 274.402 342.933 270.802 348.8C267.868 353.6 265.868 354.4 243.335 359.867C225.202 364.133 183.468 374.267 140.935 384.533C127.202 387.867 114.935 390.667 113.735 390.667C112.535 390.667 110.402 389.733 109.068 388.4C107.068 386.667 106.668 384.267 106.668 375.067C106.668 360.533 108.535 356.933 116.935 354.267C120.535 353.067 143.735 347.333 168.668 341.333C193.602 335.333 224.535 327.733 237.335 324.667C263.602 318.133 267.468 317.733 270.935 320.933ZM270.935 391.6C274.535 394.8 274.402 413.6 270.668 419.6C267.468 424.933 278.935 421.733 158.668 450.8C146.535 453.733 131.468 457.467 125.202 459.067C114.268 461.733 113.468 461.733 110.135 459.6C106.802 457.333 106.668 456.8 106.668 445.2C106.668 432 108.268 428 114.402 425.467C116.402 424.667 126.668 422 137.335 419.467C159.202 414.267 214.268 400.8 241.335 394.133C262.002 388.933 267.468 388.4 270.935 391.6Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  name: "CompanyIcon",
  props: {
    width: {
      type: [Number, String],
      default: 50,
    },
    height: {
      type: [Number, String],
      default: 50,
    },
    fill: {
      type: String,
      default: "#70D0CE",
    },
    viewBox: {
      type: [Number, String],
      default: "0 0 572 572",
    },
  },
};
</script>
