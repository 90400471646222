<template>
  <md-button
    :class="`${className} ${disabled ? 'disable' : ''}`"
    @click="clicked"
    :style="{ ...customStyles }"
    :disabled="disabled || loading"
    ><template v-if="!loading"><slot />{{ title }}</template>
    <v-progress-circular
      v-else
      indeterminate
      :color="$parent.$el.style.color"
      size="20"
      width="1"
    ></v-progress-circular>
  </md-button>
</template>

<script>
export default {
  name: "UiButton",
  props: {
    className: {
      type: String,
      default: "primary",
    },
    title: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customStyles: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    clicked() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.md-button {
  align-items: center;
  text-transform: none !important;
}

.primary {
  background-color: $tableBorder !important;
  color: #ffffff !important;
}

.primary.disable {
  background-color: #6b5a8a !important;
  color: #d9d9d9 !important;
  cursor: not-allowed;
  opacity: 0.4;
}

.primary-outlined {
  border: 1px solid $tableBorder !important;
  background-color: #ffffff !important;
  color: $tableBorder !important;
  font-weight: normal !important;
}

.primary-outlined.disable {
  border: 1px solid #d3c2df !important;
  background-color: #e9e4f0 !important;
  color: #d3c2df !important;
  cursor: not-allowed;
  opacity: 0.9;
}

.danger {
  background-color: $danger !important;
  color: #ffffff !important;
}

.danger.disable {
  background-color: #f2b3a7 !important;
  color: #f5e8e6 !important;
  cursor: not-allowed;
  opacity: 0.7;
}

.success-full {
  background-color: $success !important;
  color: #ffffff !important;
}

.success-full.disable {
  background-color: #92dad9 !important;
  color: #ffffff !important;
  cursor: not-allowed;
  opacity: 0.4;
}

.v-application .lighter {
  background-color: $popupWindow !important;
  border: 1px solid $tableBorder !important;
  color: $tableBorder !important;
}

.v-application .lighter.disable {
  background-color: #e2d8e5 !important;
  border: 1px solid #b5a0ba !important;
  color: #b5a0ba !important;
  cursor: not-allowed;
  opacity: 0.5;
}

.long {
  border-radius: 3px !important;
  min-width: 117px !important;
  height: 32px !important;
}

.short {
  border-radius: 3px !important;
  min-width: 82px !important;
  height: 32px !important;
}

.space {
  word-spacing: 5px;
}
.shadow {
  box-sizing: border-box;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), -3px 3px 3px rgba(0, 0, 0, 0.1) !important;
}
</style>
