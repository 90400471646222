<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6.19922" y="11.4004" width="18.2" height="2.6" :fill="fill"/>
    <rect x="1" y="11.4004" width="2.6" height="2.6" :fill="fill"/>
    <rect x="14" y="1" width="10.4" height="2.6" :fill="fill"/>
    <rect x="11.3984" y="6.19922" width="13" height="2.6" :fill="fill"/>
    <path d="M1.64844 4.90039L4.24844 8.15039L10.7484 1.65039" :stroke="fill" stroke-width="1.95"/>
</svg>
</template>

<script>
export default {
  name: "ActivityLogIcon",
  props: {
    width: {
      type: [Number, String],
      default: 25
    },
    height: {
      type: [Number, String],
      default: 14      
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 25 14'    
    },
  }
}
</script>