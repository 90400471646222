<template>
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.95874 9.6052C0.95874 10.6419 3.07709 11.1842 5.16917 11.1842C7.26125 11.1842 9.37961 10.6419 9.37961 9.6052C9.37961 8.80239 8.1086 8.33419 6.79552 8.13928C7.75869 6.75762 8.85362 4.93939 8.85362 3.81579C8.85362 1.78435 7.20113 0.131348 5.16917 0.131348C3.13773 0.131348 1.48473 1.78384 1.48473 3.81579C1.48473 4.93933 2.57965 6.75812 3.54283 8.13966C2.22975 8.33408 0.95874 8.80234 0.95874 9.6052ZM2.01122 3.81579C2.01122 2.07349 3.42687 0.657841 5.16917 0.657841C6.91148 0.657841 8.32713 2.07349 8.32713 3.81579C8.32713 5.33161 5.89803 8.52367 5.16917 9.44731C4.44038 8.52361 2.01122 5.33161 2.01122 3.81579ZM3.88768 8.62624C4.45611 9.41326 4.91647 9.9738 4.96681 10.037C5.01665 10.0972 5.09017 10.1317 5.16912 10.1317C5.24808 10.1317 5.32159 10.0977 5.37193 10.0345C5.42176 9.9738 5.88263 9.4108 6.45106 8.62377C8.08777 8.81819 8.85363 9.31852 8.85363 9.6052C8.85363 10.034 7.41929 10.6577 5.16919 10.6577C2.91908 10.6577 1.48474 10.034 1.48474 9.6052C1.48474 9.31852 2.25053 8.81818 3.88768 8.62624Z" :fill="fill"/>
  <path d="M7.80051 3.81603C7.80051 2.3659 6.61874 1.18457 5.16906 1.18457C3.71892 1.18457 2.5376 2.36634 2.5376 3.81603C2.5376 5.26616 3.71937 6.44749 5.16906 6.44749C6.61919 6.44749 7.80051 5.26572 7.80051 3.81603ZM3.06359 3.81603C3.06359 2.65548 4.00851 1.71056 5.16906 1.71056C6.3296 1.71056 7.27453 2.65548 7.27453 3.81603C7.27453 4.97658 6.3296 5.9215 5.16906 5.9215C4.00851 5.921 3.06359 4.97658 3.06359 3.81603Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "LocationIcon",
  props: {
    width: {
      type: [Number, String],
      default: 10
    },
    height: {
      type: [Number, String],
      default: 12
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 10 12'
    },
  }
}
</script>
