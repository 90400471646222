<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.1374 15.3008C8.40754 15.3008 6.99219 16.6978 6.99219 18.4052C6.99219 19.1813 7.30671 19.8798 7.77849 20.423V24.614H12.4963V20.423C12.9681 19.8798 13.2826 19.1813 13.2826 18.4052C13.2826 16.6978 11.8673 15.3008 10.1374 15.3008ZM11.2382 19.4917L10.9237 19.7245V23.0618H9.3511V19.7245L9.03658 19.4917C8.72206 19.2589 8.5648 18.8708 8.5648 18.4052C8.5648 17.5515 9.27247 16.853 10.1374 16.853C11.0023 16.853 11.71 17.5515 11.71 18.4052C11.71 18.8708 11.5528 19.2589 11.2382 19.4917Z" :fill="fill"/>
    <path d="M48.2267 13.6371H20.7375C20.4963 12.9553 19.9337 12.5007 19.2907 12.2734V8.33381C19.2907 3.71233 15.3522 0 10.4491 0C5.54607 0 1.60756 3.71233 1.60756 8.33381V12.2734C0.643022 12.5765 0 13.4099 0 14.3948V25.0014C0 26.2894 1.04491 27.2743 2.41133 27.2743H18.4869C19.5318 27.2743 20.416 26.6682 20.7375 25.759H48.2267V13.6371ZM3.21511 8.33381C3.21511 4.54571 6.43022 1.51524 10.4491 1.51524C14.468 1.51524 17.6831 4.54571 17.6831 8.33381V12.1219H16.0756V8.33381C16.0756 5.3791 13.5838 3.03048 10.4491 3.03048C7.31438 3.03048 4.82267 5.3791 4.82267 8.33381V12.1219H3.21511V8.33381ZM14.468 12.1219H6.43022V8.33381C6.43022 6.21248 8.19853 4.54571 10.4491 4.54571C12.6997 4.54571 14.468 6.21248 14.468 8.33381V12.1219ZM19.2907 25.0014C19.2907 25.456 18.9692 25.759 18.4869 25.759H2.41133C1.92907 25.759 1.60756 25.456 1.60756 25.0014V14.3948C1.60756 13.9402 1.92907 13.6371 2.41133 13.6371H18.4869C18.9692 13.6371 19.2907 13.9402 19.2907 14.3948V25.0014ZM46.6191 24.2438H20.8982V15.1524H46.6191V24.2438Z" :fill="fill"/>
    <path d="M22.3633 20.0734L22.977 21.4953L24.7414 20.7469V22.6179H26.2756V20.7469L28.04 21.4953L28.6537 20.0734L27.503 19.6244L28.6537 19.1754L28.04 17.7534L26.2756 18.5018V16.6309H24.7414V18.5018L22.977 17.7534L22.3633 19.1754L23.514 19.6244L22.3633 20.0734Z" :fill="fill"/>
    <path d="M31.3676 21.4953L33.132 20.7469V22.6179H34.6662V20.7469L36.4306 21.4953L37.0443 20.0734L35.8937 19.6244L37.0443 19.1754L36.4306 17.7534L34.6662 18.5018V16.6309H33.132V18.5018L31.3676 17.7534L30.7539 19.1754L31.9046 19.6244L30.7539 20.0734L31.3676 21.4953Z" :fill="fill"/>
    <path d="M39.1233 21.4953L41.0837 20.7469V22.6179H42.7885V20.7469L44.7489 21.4953L45.4308 20.0734L44.1522 19.6244L45.4308 19.1754L44.7489 17.7534L42.7885 18.5018V16.6309H41.0837V18.5018L39.1233 17.7534L38.4414 19.1754L39.72 19.6244L38.4414 20.0734L39.1233 21.4953Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "PasswordLockIcon",
  props: {
    width: {
      type: [Number, String],
      default: 49
    },
    height: {
      type: [Number, String],
      default: 28      
    },
    fill: {
      type: String,
      default: '#473068'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 49 28'    
    },
  }
}
</script>

