<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.443 14.4673C23.443 13.4016 23.2379 12.582 20.574 10.5739L20.533 10.5329L14.1807 6.31148H14.1397C13.6068 5.94266 13.033 5.73763 12.4184 5.61489V4.5492C12.8282 4.09838 13.6478 3.11475 13.6478 2.2131C13.6478 1.14741 12.7872 0 11.3937 0C9.75444 0 9.0576 1.14741 9.0576 1.96733C9.0576 2.33615 9.3854 2.66392 9.75419 2.66392C10.123 2.66392 10.4508 2.33612 10.4508 1.96733C10.4508 1.88532 10.6148 1.39349 11.4344 1.39349C12.0083 1.39349 12.295 1.88531 12.295 2.21315C12.295 2.54095 11.7622 3.27883 11.2704 3.77068C11.1473 3.89371 11.0653 4.09848 11.0653 4.26251V5.61499C10.4505 5.697 9.8769 5.94279 9.34403 6.31157L2.90999 10.533C2.90999 10.533 2.86898 10.533 2.86898 10.574C0.205061 12.5824 0 13.402 0 14.4674C0 15.4511 0.450825 16.9673 3.11475 17.3364C2.99172 20.0003 3.15575 22.9514 3.15575 22.9514H17.7464C17.7464 22.9514 17.7874 19.8776 17.7874 17.4185H18.525C18.484 19.5085 18.525 22.0907 18.525 22.0907L20.4103 22.0497V17.3364C23.0332 16.9674 23.443 15.4511 23.443 14.4674L23.443 14.4673ZM20.3282 15.9428V15.2052C20.3282 15.2052 20.2462 13.8937 19.9184 13.5659C19.1398 12.7463 18.6479 14.9594 18.607 15.3692C18.566 15.5743 18.566 15.779 18.566 16.0251H17.7873C17.9103 13.894 18.6479 13.1151 18.6479 13.1151L5.0002 13.1149C4.26259 13.1149 3.52472 13.8935 3.31992 15.0001C3.27891 15.2869 3.23791 15.615 3.19689 15.9428C1.3936 15.697 1.35257 14.7954 1.35257 14.4673C1.35257 13.9345 1.35257 13.4016 3.64772 11.6804L10.0412 7.41792C10.5331 7.09012 11.1069 6.92609 11.6805 6.92609C12.2544 6.92609 12.8279 7.09012 13.3198 7.41792C13.3198 7.41792 13.3608 7.41792 13.3608 7.45892L19.7543 11.6804C22.0494 13.4017 22.0494 13.8935 22.0494 14.4673C22.0905 14.7951 22.0494 15.6557 20.3281 15.9428L20.3282 15.9428Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "FabricCompositionIcon",
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 30     
    },
    fill: {
      type: String,
      default: '#70D0CE'
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 24 30'    
    },
  }
}
</script>

