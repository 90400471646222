<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M128.365 68.6783H87.1288V58.0651C87.1288 49.8885 80.5154 43.2751 72.3388 43.2751C64.1622 43.2751 57.5488 49.8885 57.5488 58.0651V111.135C57.5488 119.312 64.1622 125.925 72.3388 125.925H128.365C129.497 125.925 130.452 124.967 130.452 123.839V70.7685C130.452 69.6368 129.497 68.6783 128.365 68.6783ZM87.1288 72.855H97.1338L87.1288 82.86V72.855ZM87.1288 88.6881L102.962 72.855H113.056L87.1288 98.6931V88.6881ZM72.3388 121.837C66.4221 121.837 61.637 117.052 61.637 111.135C61.637 105.218 66.422 100.433 72.3388 100.433C78.2555 100.433 83.0407 105.218 83.0407 111.135C83.0373 117.052 78.2523 121.837 72.3388 121.837ZM82.952 100.957C80.2536 98.1735 76.5154 96.4335 72.2501 96.4335C68.0734 96.4335 64.2468 98.1735 61.5482 100.957V58.065C61.5482 52.1483 66.3332 47.3631 72.2501 47.3631C78.167 47.3631 82.952 52.1481 82.952 58.065V100.957ZM126.363 121.837H117.578L126.367 113.048L126.363 121.837ZM126.363 107.22L111.746 121.837H101.656L126.278 97.215V107.22H126.363ZM126.363 91.2981L95.9132 121.748H85.7351L126.279 81.2044V91.2981H126.363ZM126.363 75.3762L86.5163 115.223C86.863 113.918 87.1247 112.525 87.1247 111.135V104.521L118.88 72.7664H126.363L126.363 75.3762Z"
      :fill="fill"
    />
    <path
      d="M72.3389 104.437C68.5972 104.437 65.6406 107.482 65.6406 111.135C65.6406 114.789 68.6856 117.834 72.3389 117.834C76.0806 117.834 79.0372 114.789 79.0372 111.135C79.0372 107.482 76.0772 104.437 72.3389 104.437ZM72.3389 113.745C70.8606 113.745 69.7289 112.613 69.7289 111.135C69.7289 109.657 70.8606 108.525 72.3389 108.525C73.7323 108.525 74.9489 109.657 74.9489 111.135C74.9489 112.613 73.7289 113.745 72.3389 113.745Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "Lining",
  props: {
    width: {
      type: String,
      default: "188",
    },
    height: {
      type: String,
      default: "168",
    },
    fill: {
      type: String,
      default: "#442D64",
    },
    background: {
      type: String,
      default: "#E9E7ED",
    },
    viewBox: {
      type: String,
      default: "0 0 188 168",
    },
  },
};
</script>
